import React from "react";
import { Header } from "antd/es/layout/layout";
import { Dropdown, Button } from "antd";
import { languages } from "../i18n";
import { useLanguage } from "../contexts/LanguageContext";
import { IconAngleDown } from "../utils/Icons";
import { useTranslation } from "react-i18next";

const langIcons = {
  "ru-RU": require("../assets/img/flags/ru.svg").default,
  "en-US": require("../assets/img/flags/en.svg").default,
  "ua-UA": require("../assets/img/flags/ua.svg").default,
};

function NavBar({ className }) {
  // Translation
  const { t } = useTranslation();
  const basePath = "navBar.";

  const { changeLanguage } = useLanguage();
  const activeLang = localStorage.getItem("i18nextLng");

  const languagesArr = languages.map((item, index) => ({
    key: index,
    label: (
      <span className="item-lang">
        <img src={langIcons[item]} alt="" />
        {t(`${basePath}lang.${item}`)}
      </span>
    ),
  }));

  const onLangChange = ({ key }) => {
    const lang = languages[+key];
    localStorage.setItem("i18nextLng", lang);
    changeLanguage(lang);
  };

  return (
    <Header className={className}>
      <div className="container">
        <div className="head">
          <a href="/" className="logo">
            <img src={require("../assets/img/logo.svg").default} alt="" />
          </a>

          <ul>
            <li>
              <a href="/rules">{t(`${basePath}list.item1`)}</a>
            </li>
            <li>
              <a href="/rules">{t(`${basePath}list.item2`)}</a>
            </li>
            <li>
              <a href="/">{t(`${basePath}list.item3`)}</a>
            </li>
          </ul>

          <div className="auth">
            <Dropdown
              className="lang"
              menu={{ items: languagesArr, onClick: onLangChange }}
              trigger={["click"]}
            >
              <button type="button" className={`dropdown-btn`}>
                {t(`${basePath}lang.${activeLang}`)}
                <span className="arrow">
                  <IconAngleDown width="24" height="24" fill="#FFF" />
                </span>
              </button>
            </Dropdown>
            <a href="/">{t(`${basePath}logIn`)}</a>
            <Button className="btn-second">{t(`${basePath}create`)}</Button>
          </div>
        </div>
      </div>
    </Header>
  );
}

export default NavBar;
