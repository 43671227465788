import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// Components List
import Home from "../pages/Home";
import Order from "../pages/Order";
import Rules from "../pages/Rules";

function Routes() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/personal/order/" component={Order} />
          <Route path="/rules/" component={Rules} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default Routes;
