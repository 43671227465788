import React from "react";
import { useTranslation } from "react-i18next";

const Fixing = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "rules.fixing.";

  return (
    <div>
      <h5 className="mb-10">{t(`${basePath}title`)}</h5>
      <ul>
        <li>{t(`${basePath}item1.list.item1`)}</li>
        <li>{t(`${basePath}item1.list.item2`)}</li>
        <li>{t(`${basePath}item1.list.item3`)}</li>
        <li>{t(`${basePath}item1.list.item4`)}</li>
      </ul>
    </div>
  );
};

export default Fixing;
