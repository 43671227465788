import React from "react";
import { useTranslation } from "react-i18next";

const Agreement = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "rules.agreement.";
  return (
    <div className="returns">
      <h5>{t(`${basePath}title`)}</h5>

      <h5>{t(`${basePath}list.item1.title`)}</h5>
      <p>{t(`${basePath}list.item1.item1`)}</p>
      <p>{t(`${basePath}list.item1.item2`)}</p>
      <p>{t(`${basePath}list.item1.item3`)}</p>
      <p>{t(`${basePath}list.item1.item4`)}</p>
      <p>{t(`${basePath}list.item1.item5`)}</p>
      <p>{t(`${basePath}list.item1.item6`)}</p>

      <h5 className="mt-70">{t(`${basePath}list.item2.title`)}</h5>
      <p>{t(`${basePath}list.item2.item1`)}</p>
      <p>{t(`${basePath}list.item2.item2`)}</p>
      <p>{t(`${basePath}list.item2.item3`)}</p>
      <p>{t(`${basePath}list.item2.item4`)}</p>
      <p>{t(`${basePath}list.item2.item5`)}</p>
      <p>{t(`${basePath}list.item2.item6`)}</p>
      <p>{t(`${basePath}list.item2.item7`)}</p>
      <p>{t(`${basePath}list.item2.item8`)}</p>
      <p>{t(`${basePath}list.item2.item9`)}</p>
      <p>{t(`${basePath}list.item2.item10`)}</p>
      <p>{t(`${basePath}list.item2.item11`)}</p>
      <p>{t(`${basePath}list.item2.item12`)}</p>
      <p>{t(`${basePath}list.item2.item13`)}</p>
      <p>{t(`${basePath}list.item2.item14`)}</p>
      <p>{t(`${basePath}list.item2.item15`)}</p>
      <p>{t(`${basePath}list.item2.item16`)}</p>

      <h5 className="mt-70">{t(`${basePath}list.item3.title`)}</h5>
      <p>{t(`${basePath}list.item3.item1`)}</p>
      <p>{t(`${basePath}list.item3.item2`)}</p>
      <p>{t(`${basePath}list.item3.item3`)}</p>
      <p>{t(`${basePath}list.item3.item4`)}</p>
      <p>{t(`${basePath}list.item3.item5`)}</p>
      <p>{t(`${basePath}list.item3.item6`)}</p>
      <p>{t(`${basePath}list.item3.item7`)}</p>
      <p>{t(`${basePath}list.item3.item8`)}</p>
      <p>{t(`${basePath}list.item3.item9`)}</p>
      <p>{t(`${basePath}list.item3.item10`)}</p>

      <h5 className="mt-70">{t(`${basePath}list.item4.title`)}</h5>
      <p>{t(`${basePath}list.item4.item1`)}</p>
      <p>{t(`${basePath}list.item4.item2`)}</p>
      <p>{t(`${basePath}list.item4.item3`)}</p>
      <p>{t(`${basePath}list.item4.item4`)}</p>
      <p>{t(`${basePath}list.item4.item5`)}</p>
      <p>{t(`${basePath}list.item4.item6`)}</p>
      <p>{t(`${basePath}list.item4.item7`)}</p>
      <p>{t(`${basePath}list.item4.item8`)}</p>
      <p>{t(`${basePath}list.item4.item9`)}</p>
      <p>{t(`${basePath}list.item4.item10`)}</p>
      <p>{t(`${basePath}list.item4.item11`)}</p>
      <p>{t(`${basePath}list.item4.item12`)}</p>
      <p>{t(`${basePath}list.item4.item13`)}</p>
      <p>{t(`${basePath}list.item4.item14`)}</p>
      <p>{t(`${basePath}list.item4.item15`)}</p>
      <p>{t(`${basePath}list.item4.item16`)}</p>
      <p>{t(`${basePath}list.item4.item17`)}</p>
      <p>{t(`${basePath}list.item4.item18`)}</p>
      <p>{t(`${basePath}list.item4.item19`)}</p>
      <p>{t(`${basePath}list.item4.item20`)}</p>
      <p>{t(`${basePath}list.item4.item21`)}</p>
      <p>{t(`${basePath}list.item4.item22`)}</p>
      <p>{t(`${basePath}list.item4.item23`)}</p>
      <p>{t(`${basePath}list.item4.item24`)}</p>
      <p>{t(`${basePath}list.item4.item25`)}</p>
      <p>{t(`${basePath}list.item4.item26`)}</p>
      <p>{t(`${basePath}list.item4.item27`)}</p>
      <p>{t(`${basePath}list.item4.item28`)}</p>
      <p>{t(`${basePath}list.item4.item29`)}</p>
      <p>{t(`${basePath}list.item4.item30`)}</p>
      <p>{t(`${basePath}list.item4.item31`)}</p>
      <p>{t(`${basePath}list.item4.item32`)}</p>
      <p>{t(`${basePath}list.item4.item33`)}</p>
      <p>{t(`${basePath}list.item4.item34`)}</p>
      <p>{t(`${basePath}list.item4.item35`)}</p>
      <p>{t(`${basePath}list.item4.item36`)}</p>
      <p>{t(`${basePath}list.item4.item37`)}</p>
      <p>{t(`${basePath}list.item4.item38`)}</p>
      <p>{t(`${basePath}list.item4.item39`)}</p>
      <p>{t(`${basePath}list.item4.item40`)}</p>
      <p>{t(`${basePath}list.item4.item41`)}</p>
      <p>{t(`${basePath}list.item4.item42`)}</p>
      <p>{t(`${basePath}list.item4.item43`)}</p>

      <h5 className="mt-70">{t(`${basePath}list.item5.title`)}</h5>
      <p>{t(`${basePath}list.item5.item1`)}</p>
      <p>{t(`${basePath}list.item5.item2`)}</p>
      <p>{t(`${basePath}list.item5.item3`)}</p>
      <p>{t(`${basePath}list.item5.item4`)}</p>
      <p>{t(`${basePath}list.item5.item5`)}</p>
      <p>{t(`${basePath}list.item5.item6`)}</p>
      <p>{t(`${basePath}list.item5.item7`)}</p>
      <p>{t(`${basePath}list.item5.item8`)}</p>

      <h5 className="mt-70">{t(`${basePath}list.item6.title`)}</h5>
      <p>{t(`${basePath}list.item6.item1`)}</p>
      <p>{t(`${basePath}list.item6.item2`)}</p>
      <p>{t(`${basePath}list.item6.item3`)}</p>
      <p>{t(`${basePath}list.item6.item4`)}</p>
      <p>{t(`${basePath}list.item6.item5`)}</p>
      <p>{t(`${basePath}list.item6.item6`)}</p>
      <p>{t(`${basePath}list.item6.item7`)}</p>

      <h5 className="mt-70">{t(`${basePath}list.item7.title`)}</h5>
      <p>{t(`${basePath}list.item7.item1`)}</p>
      <p>{t(`${basePath}list.item7.item2`)}</p>
      <p>{t(`${basePath}list.item7.item3`)}</p>
      <p>{t(`${basePath}list.item7.item4`)}</p>

      <h5 className="mt-70">{t(`${basePath}list.item8.title`)}</h5>
      <p>{t(`${basePath}list.item8.item1`)}</p>
      <p>{t(`${basePath}list.item8.item2`)}</p>
      <p>{t(`${basePath}list.item8.item3`)}</p>
      <p>{t(`${basePath}list.item8.item4`)}</p>
      <p>{t(`${basePath}list.item8.item5`)}</p>
      <p>{t(`${basePath}list.item8.item6`)}</p>

      <h5 className="mt-70">{t(`${basePath}list.item9.title`)}</h5>
      <p>{t(`${basePath}list.item9.item1`)}</p>
      <p>{t(`${basePath}list.item9.item2`)}</p>
      <p>{t(`${basePath}list.item9.item3`)}</p>
      <p>{t(`${basePath}list.item9.item4`)}</p>
      <p>{t(`${basePath}list.item9.item5`)}</p>
      <p>{t(`${basePath}list.item9.item6`)}</p>
      <p>{t(`${basePath}list.item9.item7`)}</p>
      <p>{t(`${basePath}list.item9.item8`)}</p>

      <h5 className="mt-70">{t(`${basePath}list.item10.title`)}</h5>
      <p>{t(`${basePath}list.item10.item1`)}</p>
      <p>{t(`${basePath}list.item10.item2`)}</p>
      <p>{t(`${basePath}list.item10.item3`)}</p>
      <p>{t(`${basePath}list.item10.item4`)}</p>
    </div>
  );
};

export default Agreement;
