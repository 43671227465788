import React from "react";
import { useTranslation } from "react-i18next";

const AML = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "rules.aml.";

  return (
    <div>
      <h5 className="mb-10">{t(`${basePath}title`)}</h5>
      <p>{t(`${basePath}desc`)}</p>
      <h2>{t(`${basePath}item1.title`)}</h2>
      <p>{t(`${basePath}item1.desc`)}</p>
      <ul>
        <li>{t(`${basePath}item1.list.item1`)}</li>
        <li>{t(`${basePath}item1.list.item2`)}</li>
        <li>{t(`${basePath}item1.list.item3`)}</li>
      </ul>
      <p>{t(`${basePath}item1.desc1`)}</p>
      <p>{t(`${basePath}item1.desc2`)}</p>
      <p>{t(`${basePath}item1.desc3`)}</p>
      <p>{t(`${basePath}item1.desc4`)}</p>

      <h2>{t(`${basePath}item2.title`)}</h2>
      <ul>
        <li>{t(`${basePath}item2.list.item1`)}</li>
        <li>{t(`${basePath}item2.list.item2`)}</li>
        <li>{t(`${basePath}item2.list.item3`)}</li>
        <li>{t(`${basePath}item2.list.item4`)}</li>
        <li>{t(`${basePath}item2.list.item5`)}</li>
        <li>{t(`${basePath}item2.list.item6`)}</li>
      </ul>

      <h2>{t(`${basePath}item3.title`)}</h2>
      <p>{t(`${basePath}item3.desc`)}</p>
      <ul>
        <li>{t(`${basePath}item3.list.item1`)}</li>
        <li>{t(`${basePath}item3.list.item2`)}</li>
        <li>{t(`${basePath}item3.list.item3`)}</li>
        <li>{t(`${basePath}item3.list.item4`)}</li>
      </ul>
    </div>
  );
};

export default AML;
