import React from "react";
import Exchange from "../components/Home/Exchange";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";

function Home() {
  return (
    <main>
      <NavBar />
      <Exchange />
      <Footer />
    </main>
  );
}

export default Home;
