import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import { Tabs } from "antd";
import Regulations from "../components/Rules/Regulations";
import Enrollment from "../components/Rules/Enrollment";
import Fixing from "../components/Rules/Fixing";
import AML from "../components/Rules/AML";
import Returns from "../components/Rules/Returns";
import Agreement from "../components/Rules/Agreement";
import Risk from "../components/Rules/Risk";
import Policy from "../components/Rules/Policy";

const Rules = () => {
  const { t } = useTranslation();
  const basePath = "rules.";

  return (
    <main>
      <NavBar className="bg-white" />
      <div className="container">
        <div className="rules">
          <h1>{t(`${basePath}title`)}</h1>
          <Tabs
            type="card"
            className="rules-card"
            items={[
              {
                key: "1",
                label: t(`${basePath}list.item1`),
                children: <Regulations />,
              },
              {
                key: "2",
                label: t(`${basePath}list.item2`),
                children: <Enrollment />,
              },
              {
                key: "3",
                label: t(`${basePath}list.item3`),
                children: <Fixing />,
              },
              {
                key: "4",
                label: t(`${basePath}list.item4`),
                children: <AML />,
              },
              {
                key: "5",
                label: t(`${basePath}list.item5`),
                children: <Returns />,
              },
              {
                key: "6",
                label: t(`${basePath}list.item6`),
                children: <Agreement />,
              },
              {
                key: "7",
                label: t(`${basePath}list.item7`),
                children: <Risk />,
              },
              {
                key: "8",
                label: t(`${basePath}list.item8`),
                children: <Policy />,
              },
            ]}
          />
        </div>
      </div>
      <Footer className={"bg-white"} />
    </main>
  );
};

export default Rules;
