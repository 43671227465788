import React from "react";
import { useTranslation } from "react-i18next";

const Enrollment = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "rules.enrollment.";

  return (
    <div>
      <h5 className="mb-10">{t(`${basePath}title`)}</h5>
      <ul>
        <li>
          <p>
            <u>{t(`${basePath}item1.title`)}</u>
            <br />
            {t(`${basePath}item1.list.item1`)}
            <br />
            {t(`${basePath}item1.list.item2`)}
            <br />
            {t(`${basePath}item1.list.item3`)}
            <br />
            {t(`${basePath}item1.list.item4`)}
            <br />
            {t(`${basePath}item1.list.item5`)}
            <br />
            {t(`${basePath}item1.list.item6`)}
            <br />
            {t(`${basePath}item1.list.item7`)}
            <br />
            {t(`${basePath}item1.list.item8`)}
            <br />
            {t(`${basePath}item1.list.item9`)}
            <br />
            {t(`${basePath}item1.list.item10`)}
            <br />
            {t(`${basePath}item1.list.item11`)}
            <br />
            {t(`${basePath}item1.list.item12`)}
            <br />
            {t(`${basePath}item1.list.item13`)}
            <br />
            {t(`${basePath}item1.list.item14`)}
            <br />
            {t(`${basePath}item1.list.item15`)}
            <br />
            {t(`${basePath}item1.list.item16`)}
            <br />
            {t(`${basePath}item1.list.item17`)}
            <br />
            {t(`${basePath}item1.list.item18`)}
            <br />
            {t(`${basePath}item1.list.item19`)}
            <br />
            {t(`${basePath}item1.list.item20`)}
            <br />
            {t(`${basePath}item1.list.item21`)}
            <br />
            {t(`${basePath}item1.list.item22`)}
            <br />
            {t(`${basePath}item1.list.item23`)}
          </p>
        </li>
      </ul>
    </div>
  );
};

export default Enrollment;
