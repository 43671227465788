import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Tabs,
} from "antd";
import {
  CRYPTOCURRENCY_FORM,
  CRYPTOCURRENCY_TO,
} from "../../constants/CryptocurrencyArray";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const Exchange = () => {
  const { t } = useTranslation();
  const basePath = "exchange.";

  const getDefaultState = () => ({
    amount: null,
    currencyTo: "USDT",
    currencyFrom: "USDT",
    infoCoinTo: CRYPTOCURRENCY_TO[0],
    infoCoinFrom: CRYPTOCURRENCY_FORM[0],
    email: "",
    wallet: "",
    firstName: "",
    lastName: "",
    exchangeRate: parseFloat(localStorage.getItem("exchangeRate")) || null,
    result: localStorage.getItem("exchangeResult") || "",
    submitTime: null,
  });

  const history = useHistory();
  const [state, setState] = useState(getDefaultState);
  const [isCheckedRules, setIsCheckedRules] = useState(false);
  const [isCheckedAml, setIsCheckedAml] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const isFiatCurrency = (currency) => {
    const fiatCurrencies = [
      "USD",
      "EUR",
      "UAH",
      "PLN",
      "AED",
      "THB",
      "KZT",
      "TRY",
      "AZN",
      "CZK",
    ];
    const currencySymbol = currency.substring(0, 3).toUpperCase();
    return fiatCurrencies.includes(currencySymbol);
  };

  const fetchRate = async (currency) => {
    const url = isFiatCurrency(currency)
      ? `https://api.exchangerate-api.com/v4/latest/${currency}`
      : `https://api.binance.com/api/v3/ticker/price?symbol=${currency}USDT`;

    const response = await fetch(url);
    return response.json();
  };

  const fetchExchangeRate = async () => {
    try {
      const [fromResponse, toResponse] = await Promise.all([
        fetchRate(state.currencyFrom),
        fetchRate(state.currencyTo),
      ]);

      const fromCurrencyToUsdRate = isFiatCurrency(state.currencyFrom)
        ? fromResponse.rates.USD
        : parseFloat(fromResponse.price);

      const toCurrencyToUsdRate = isFiatCurrency(state.currencyTo)
        ? toResponse.rates.USD
        : parseFloat(toResponse.price);

      if (!fromCurrencyToUsdRate || !toCurrencyToUsdRate) {
        throw new Error("Failed to fetch exchange rates");
      }

      const exchangeRate =
        (state.currencyFrom === "TRX") & (state.currencyTo === "USDT")
          ? 0.109
          : fromCurrencyToUsdRate / toCurrencyToUsdRate;

      // const exchangeRate = fromCurrencyToUsdRate / toCurrencyToUsdRate;

      setState((prevState) => ({
        ...prevState,
        exchangeRate,
      }));
      localStorage.setItem("exchangeRate", exchangeRate);
    } catch (error) {
      console.error("Error fetching exchange rate:", error);
      setState((prevState) => ({
        ...prevState,
        exchangeRate: null,
      }));
      localStorage.removeItem("exchangeRate");
    }
  };

  const calculateExchange = () => {
    const { amount, exchangeRate } = state;

    if (isNaN(amount) || amount <= 0 || exchangeRate === null) {
      setState((prevState) => ({
        ...prevState,
        result: null,
      }));
      return;
    }

    const calculatedResult = (amount * exchangeRate).toFixed(8);
    setState((prevState) => ({
      ...prevState,
      result: calculatedResult,
    }));
    localStorage.setItem("exchangeResult", calculatedResult);
  };

  useEffect(() => {
    fetchExchangeRate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currencyFrom, state.currencyTo]);

  useEffect(() => {
    calculateExchange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.amount, state.exchangeRate]);

  useEffect(() => {
    localStorage.setItem("exchangeFormData", JSON.stringify(state));
  }, [state]);

  const onFinish = () => {
    const currentTime = new Date().getTime();
    setState((prevState) => ({
      ...prevState,
      submitTime: currentTime,
    }));

    if (!isCheckedRules || !isCheckedAml) {
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 5000);
      return;
    }

    history.push("/personal/order/");
    window.location.reload();
  };

  return (
    <div className="container exchange">
      <div className="box">
        <p>
          {t(`${basePath}banner.desc1.text1`)}{" "}
          <span>{t(`${basePath}banner.desc1.text2`)}</span>
          {t(`${basePath}banner.desc1.text3`)}{" "}
          <span>{t(`${basePath}banner.desc1.text4`)}</span>
          {t(`${basePath}banner.desc1.text5`)}
          <span>{t(`${basePath}banner.desc1.text6`)}</span>.
          {t(`${basePath}banner.desc1.text7`)}
          <span>{t(`${basePath}banner.desc1.text8`)}</span>
          {t(`${basePath}banner.desc1.text9`)}
          <span>{t(`${basePath}banner.desc1.text10`)}</span>.
        </p>
        <p>
          {t(`${basePath}banner.desc2.text1`)}
          <span>{t(`${basePath}banner.desc2.text2`)}</span>
          {t(`${basePath}banner.desc2.text3`)}
          <span>{t(`${basePath}banner.desc2.text4`)}</span>
          {t(`${basePath}banner.desc2.text5`)}
          <span>{t(`${basePath}banner.desc2.text6`)}</span>
          {t(`${basePath}banner.desc2.text7`)}
        </p>
      </div>
      <Row justify="center">
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form onFinish={onFinish}>
            <div className="col-item">
              <div className="box">
                <div className="title">
                  {t(`${basePath}giving.title`)}
                  <img
                    src={require("../../assets/img/icon/arrow-top.svg").default}
                    alt=""
                  />
                </div>
                <Form.Item className="input-assets">
                  <InputNumber
                    placeholder={"0.00"}
                    value={state.amount}
                    onChange={(value) =>
                      setState((prevState) => ({
                        ...prevState,
                        amount: value,
                      }))
                    }
                    controls={false}
                    addonAfter={
                      state.infoCoinFrom && (
                        <img src={state.infoCoinFrom.icon} alt="" />
                      )
                    }
                  />
                  <span className="currency">{state.infoCoinFrom?.symbol}</span>
                  <div className="top-bottom">
                    <p>
                      {t(`${basePath}giving.min`)}{" "}
                      <span>{state.infoCoinFrom?.min}</span>
                    </p>
                    <p>
                      {t(`${basePath}giving.max`)}{" "}
                      <span>{state.infoCoinFrom?.max}</span>
                    </p>
                  </div>
                </Form.Item>
              </div>
              <div className="box">
                <Tabs
                  defaultActiveKey="1"
                  items={[
                    {
                      key: "1",
                      label: t(`${basePath}giving.tabs.item1`),
                      children: (
                        <Radio.Group
                          defaultValue={"USDT"}
                          onChange={(e) => {
                            const selectedCurrency = CRYPTOCURRENCY_FORM.find(
                              (item) => item.symbol === e.target.value
                            );
                            setState((prevState) => ({
                              ...prevState,
                              currencyFrom: e.target.value,
                              infoCoinFrom: selectedCurrency,
                            }));
                          }}
                        >
                          {CRYPTOCURRENCY_FORM.map((item, index) => (
                            <Radio.Button value={item.symbol} key={index}>
                              <div className="logo">
                                <img src={item.icon} alt="" />
                              </div>
                              <s>{item.name}</s>
                              <p>
                                {item.reserve} {item.symbol}
                              </p>
                            </Radio.Button>
                          ))}
                        </Radio.Group>
                      ),
                    },
                    {
                      key: "2",
                      label: t(`${basePath}giving.tabs.item2`),
                      children: (
                        <Radio.Group
                          onChange={(e) => {
                            const selectedCurrency = CRYPTOCURRENCY_FORM.find(
                              (item) => item.symbol === e.target.value
                            );
                            setState((prevState) => ({
                              ...prevState,
                              currencyFrom: e.target.value,
                              infoCoinFrom: selectedCurrency,
                            }));
                          }}
                        >
                          {CRYPTOCURRENCY_FORM.filter(
                            (i) => i.type === "crypto"
                          ).map((item, index) => (
                            <Radio.Button value={item.symbol} key={index}>
                              <div className="logo">
                                <img src={item.icon} alt="" />
                              </div>
                              <s>{item.name}</s>
                              <p>
                                {item.reserve} {item.symbol}
                              </p>
                            </Radio.Button>
                          ))}
                        </Radio.Group>
                      ),
                    },
                    {
                      key: "3",
                      label: t(`${basePath}giving.tabs.item3`),
                      children: (
                        <Radio.Group
                          onChange={(e) => {
                            const selectedCurrency = CRYPTOCURRENCY_FORM.find(
                              (item) => item.symbol === e.target.value
                            );
                            setState((prevState) => ({
                              ...prevState,
                              currencyFrom: e.target.value,
                              infoCoinFrom: selectedCurrency,
                            }));
                          }}
                        >
                          {CRYPTOCURRENCY_FORM.filter(
                            (i) => i.type === "fiat"
                          ).map((item, index) => (
                            <Radio.Button value={item.symbol} key={index}>
                              <div className="logo">
                                <img src={item.icon} alt="" />
                              </div>
                              <s>{item.name}</s>
                              <p>
                                {item.reserve} {item.symbol}
                              </p>
                            </Radio.Button>
                          ))}
                        </Radio.Group>
                      ),
                    },
                    {
                      key: "4",
                      label: t(`${basePath}giving.tabs.item4`),
                      children: "",
                    },
                  ]}
                />
              </div>
            </div>

            <div className="col-item">
              <div className="box">
                <div className="title">
                  {t(`${basePath}get.title`)}
                  <img
                    src={
                      require("../../assets/img/icon/arrow-down.svg").default
                    }
                    alt=""
                  />
                </div>
                <Form.Item className="input-assets">
                  <InputNumber
                    id="result"
                    value={state.result}
                    controls={false}
                    placeholder={"0"}
                    addonAfter={
                      state.infoCoinTo && (
                        <img src={state.infoCoinTo.icon} alt="" />
                      )
                    }
                  />
                  <span className="currency">
                    {state.infoCoinTo?.type === "fiat"
                      ? state.infoCoinTo?.symbol.slice(0, 3)
                      : state.infoCoinTo?.symbol}
                  </span>
                  <div className="top-bottom">
                    <p>
                      {t(`${basePath}get.reserve`)}{" "}
                      <span>{state.infoCoinTo?.reserve}</span>
                    </p>
                  </div>
                </Form.Item>
              </div>
              <div className="box">
                <Tabs
                  defaultActiveKey="1"
                  items={[
                    {
                      key: "1",
                      label: t(`${basePath}giving.tabs.item1`),
                      children: (
                        <Radio.Group
                          defaultValue={"USDT"}
                          onChange={(e) => {
                            const selectedCurrency = CRYPTOCURRENCY_TO.find(
                              (item) => item.symbol === e.target.value
                            );
                            setState((prevState) => ({
                              ...prevState,
                              currencyTo: e.target.value,
                              infoCoinTo: selectedCurrency,
                            }));
                          }}
                        >
                          {CRYPTOCURRENCY_TO.map((item, index) => (
                            <Radio.Button value={item.symbol} key={index}>
                              <div className="logo">
                                <img src={item.icon} alt="" />
                              </div>
                              <s>{item.name}</s>
                              <p>
                                {item.reserve.toFixed(0)} {item.symbol}
                              </p>
                            </Radio.Button>
                          ))}
                        </Radio.Group>
                      ),
                    },
                    {
                      key: "2",
                      label: t(`${basePath}giving.tabs.item2`),
                      children: (
                        <Radio.Group
                          onChange={(e) => {
                            const selectedCurrency = CRYPTOCURRENCY_TO.find(
                              (item) => item.symbol === e.target.value
                            );
                            setState((prevState) => ({
                              ...prevState,
                              currencyTo: e.target.value,
                              infoCoinTo: selectedCurrency,
                            }));
                          }}
                        >
                          {CRYPTOCURRENCY_TO.filter(
                            (i) => i.type === "crypto"
                          ).map((item, index) => (
                            <Radio.Button value={item.symbol} key={index}>
                              <div className="logo">
                                <img src={item.icon} alt="" />
                              </div>
                              <s>{item.name}</s>
                              <p>
                                {item.reserve.toFixed(0)} {item.symbol}
                              </p>
                            </Radio.Button>
                          ))}
                        </Radio.Group>
                      ),
                    },
                    {
                      key: "3",
                      label: t(`${basePath}giving.tabs.item3`),
                      children: (
                        <Radio.Group
                          onChange={(e) => {
                            const selectedCurrency = CRYPTOCURRENCY_TO.find(
                              (item) => item.symbol === e.target.value
                            );
                            setState((prevState) => ({
                              ...prevState,
                              currencyTo: e.target.value,
                              infoCoinTo: selectedCurrency,
                            }));
                          }}
                        >
                          {CRYPTOCURRENCY_TO.filter(
                            (i) => i.type === "fiat"
                          ).map((item, index) => (
                            <Radio.Button value={item.symbol} key={index}>
                              <div className="logo">
                                <img src={item.icon} alt="" />
                              </div>
                              <s>{item.name}</s>
                              <p>
                                {item.reserve.toFixed(0)} {item.symbol}
                              </p>
                            </Radio.Button>
                          ))}
                        </Radio.Group>
                      ),
                    },
                    {
                      key: "4",
                      label: t(`${basePath}giving.tabs.item4`),
                      children: "",
                    },
                  ]}
                />
              </div>
            </div>

            <div className="col-item">
              <div className="box">
                <div className="title">
                  {t(`${basePath}data.title`)}
                  <img
                    src={
                      require("../../assets/img/icon/arrow-left.svg").default
                    }
                    alt=""
                  />
                </div>
                <div className="data-form">
                  {state.infoCoinTo?.type === "crypto" && (
                    <>
                      <Form.Item name={"wallet"}>
                        <Input
                          placeholder={`${state.infoCoinTo.label} ${t(
                            `${basePath}data.walet`
                          )}`}
                          value={state.wallet}
                          onChange={(e) =>
                            setState((prevState) => ({
                              ...prevState,
                              wallet: e.target.value,
                            }))
                          }
                        />
                        <span className="icon-coin">
                          {state.infoCoinTo && (
                            <img src={state.infoCoinTo.icon} alt="" />
                          )}
                        </span>
                      </Form.Item>
                      <Form.Item name={"email"} type="email">
                        <Input
                          placeholder={"E-mail"}
                          value={state.email}
                          onChange={(e) =>
                            setState((prevState) => ({
                              ...prevState,
                              email: e.target.value,
                            }))
                          }
                        />
                      </Form.Item>
                    </>
                  )}
                  {state.infoCoinTo?.type === "fiat" && (
                    <>
                      <Form.Item name={"wallet"}>
                        <Input
                          placeholder={`Card number`}
                          value={state.wallet}
                          onChange={(e) =>
                            setState((prevState) => ({
                              ...prevState,
                              wallet: e.target.value,
                            }))
                          }
                        />
                        <span className="icon-coin">
                          {state.infoCoinTo && (
                            <img src={state.infoCoinTo.icon} alt="" />
                          )}
                        </span>
                      </Form.Item>
                      <Form.Item name={"email"} type="email">
                        <Input
                          placeholder={"E-mail"}
                          value={state.email}
                          onChange={(e) =>
                            setState((prevState) => ({
                              ...prevState,
                              email: e.target.value,
                            }))
                          }
                        />
                      </Form.Item>
                      <Form.Item name={"firstName"} type="text">
                        <Input
                          placeholder={t(`${basePath}data.firstName`)}
                          value={state.email}
                          onChange={(e) =>
                            setState((prevState) => ({
                              ...prevState,
                              firstName: e.target.value,
                            }))
                          }
                        />
                      </Form.Item>
                      <Form.Item name={"lastName"} type="text">
                        <Input
                          placeholder={t(`${basePath}data.lastName`)}
                          value={state.email}
                          onChange={(e) =>
                            setState((prevState) => ({
                              ...prevState,
                              lastName: e.target.value,
                            }))
                          }
                        />
                      </Form.Item>
                    </>
                  )}
                </div>
                <div className="rules-order">
                  <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    className="m-0"
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(console.log("Error")),
                      },
                    ]}
                  >
                    <Checkbox
                      onChange={(e) => setIsCheckedRules(e.target.checked)}
                    >
                      {t(`${basePath}data.rules.text`)}{" "}
                      <a href="/rules">{t(`${basePath}data.rules.desc1`)}</a> .
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="aml"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(console.log("Error")),
                      },
                    ]}
                  >
                    <Checkbox
                      onChange={(e) => setIsCheckedAml(e.target.checked)}
                    >
                      {t(`${basePath}data.rules.text`)}{" "}
                      <a href="/rules">{t(`${basePath}data.rules.desc2`)}</a> .
                    </Checkbox>
                  </Form.Item>
                </div>
                <div className="d-flex">
                  <Button
                    htmlType="submit"
                    className="btn-primary"
                    type="primary"
                    disabled={!state.amount || !state.email || !state.wallet}
                    onClick={onFinish}
                  >
                    {t(`${basePath}data.btn`)}
                  </Button>
                </div>
              </div>

              <div className="box">
                <div className="item-coin">
                  <div className="icon">
                    <img src={state.infoCoinFrom.icon} alt="" />
                  </div>
                  <div className="title">{state.infoCoinFrom.name}</div>
                  <s>
                    {state.amount || 0} {state.infoCoinFrom?.symbol}
                    <img
                      src={
                        require("../../assets/img/icon/arrow-top.svg").default
                      }
                      alt=""
                    />
                  </s>
                </div>
                <div className="item-coin">
                  <div className="icon">
                    <img src={state.infoCoinTo.icon} alt="" />
                  </div>
                  <div className="title">{state.infoCoinTo.name}</div>
                  <s>
                    {state.result || 0}{" "}
                    {state.infoCoinTo?.type === "fiat"
                      ? state.infoCoinTo?.symbol.slice(0, 3)
                      : state.infoCoinTo?.symbol}
                    <img
                      src={
                        require("../../assets/img/icon/arrow-down-red.svg")
                          .default
                      }
                      alt=""
                    />
                  </s>
                </div>
                <div className="rate">
                  {t(`${basePath}data.corse`)}{" "}
                  <b>1: {state.exchangeRate || 1}</b>
                </div>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
      <div className={`notification ${showNotification ? "active" : ""}`}>
        {t(`${basePath}data.massage`)}
      </div>
    </div>
  );
};

export default Exchange;
