import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import dayjs from "dayjs";
import emailjs from "emailjs-com";
import { Button, Input, QRCode } from "antd";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";

const Order = () => {
  const { t } = useTranslation();
  const basePath = "order.";

  const [userData, setUserData] = useState(null);
  const [isActiveQR, setIsActiveQR] = useState(false);

  const getUserDataFromLocalStorage = () => {
    const storedUserData = localStorage.getItem("exchangeFormData");
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      setUserData(parsedUserData);
    }
  };

  useEffect(() => {
    getUserDataFromLocalStorage();
  }, []);

  const [countdown, setCountdown] = useState({ minutes: 60, seconds: 0 });

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setCountdown((prevCountdown) => {
        const newSeconds = prevCountdown.seconds - 1;
        const newMinutes = prevCountdown.minutes - (newSeconds < 0 ? 1 : 0);
        const seconds = newSeconds < 0 ? 59 : newSeconds;
        const minutes = newSeconds < 0 ? newMinutes : prevCountdown.minutes;

        if (minutes === 0 && seconds === 0) {
          clearInterval(timerInterval);
        }

        return { minutes, seconds };
      });
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  const formattedCountdown = `${countdown.minutes
    .toString()
    .padStart(2, "0")}:${countdown.seconds.toString().padStart(2, "0")}`;

  const sendEmail = async () => {
    if (!userData) return;

    const serviceId = "service_e2kb56b";
    const templateId = "template_d22pazz";
    const userId = "zWUGrm_-1m3kIm23w";

    try {
      const templateParams = {
        amount: `${userData.amount} ${userData.infoCoinFrom.name} - ${userData.result} ${userData.infoCoinTo.name}`,
        submitTime: dayjs(userData.submitTime).format("DD.MM.YYYY / HH:ss"),
        wallet: userData.wallet,
        initials: `${userData.firstName || "No Data"} ${
          userData.infoCoinFrom.lastName || ""
        } `,
        email: userData.email,
      };

      const response = await emailjs.send(
        serviceId,
        templateId,
        templateParams,
        userId
      );
      console.log("Email sent successfully!", response);
    } catch (error) {
      console.error("Error sending email:", error);
      throw error;
    }
  };

  useEffect(() => {
    const emailTimer = setTimeout(() => {
      sendEmail();
    }, 5000);

    return () => clearTimeout(emailTimer);
  }, []);

  return (
    <main>
      <NavBar />
      <div className="order">
        <div className="container">
          <div className="order-page-top">
            <div className="title-h4">{t(`${basePath}title`)} № 5273</div>
            <div className="payment-data_date">
              {t(`${basePath}pending`)}:<span>{formattedCountdown}</span>
            </div>
          </div>
          <div className="box">
            <div className="side">
              <div className="table">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <span>{t(`${basePath}table.item1`)}</span>
                      </td>
                      <td>
                        <div className="-row">
                          <img src={userData?.infoCoinFrom.icon} alt="" />
                          <p>
                            {userData?.amount} {userData?.infoCoinFrom.symbol}
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>{t(`${basePath}table.item2`)}</span>
                      </td>
                      <td>
                        <div className="-row">
                          <img src={userData?.infoCoinTo.icon} alt="" />
                          <p>
                            {userData?.result}{" "}
                            {userData?.infoCoinTo?.type === "fiat"
                              ? userData?.infoCoinTo?.symbol.slice(0, 3)
                              : userData?.infoCoinTo?.symbol}
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>
                          {userData?.infoCoinTo?.type === "crypto"
                            ? userData?.infoCoinTo.label
                            : "Card number"}
                        </span>
                      </td>
                      <td>
                        <div className="-row">
                          <p>{userData?.wallet}</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span>E-mail</span>
                      </td>
                      <td>
                        <div className="-row">
                          <p>{userData?.email}</p>
                        </div>
                      </td>
                    </tr>
                    {userData?.infoCoinTo.type === "fiat" && (
                      <>
                        <tr>
                          <td>
                            <span>{t(`${basePath}table.item3`)}</span>
                          </td>
                          <td>
                            <div className="-row">
                              <p>{userData?.firstName}</p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span>{t(`${basePath}table.item4`)}</span>
                          </td>
                          <td>
                            <div className="-row">
                              <p>{userData?.lastName}</p>
                            </div>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="main">
              <div className="title">
                <h1>{t(`${basePath}side.title`)}</h1>
              </div>

              <div className="qr-block">
                <Button
                  type="primary"
                  className="qr"
                  onClick={() => setIsActiveQR((prev) => !prev)}
                >
                  <img
                    src={require("../assets/img/icon/qr-icon.svg").default}
                    alt=""
                  />
                </Button>
                <div
                  className={`merchant-qrcode ${isActiveQR ? "active" : ""}`}
                >
                  <QRCode value={userData?.infoCoinFrom.wallet || "-"} />
                </div>
              </div>

              <div className="order-card">
                <div className="input-group">
                  <label htmlFor="">{t(`${basePath}side.get`)}</label>
                  <Input
                    type="text"
                    value={`${userData?.amount} ${userData?.infoCoinFrom.name} ${userData?.infoCoinFrom.symbol}`}
                    readOnly
                  />
                </div>

                <div className="input-group">
                  <label htmlFor="">{t(`${basePath}side.address`)}</label>
                  <Input
                    className="w-100"
                    type="text"
                    value={userData?.infoCoinFrom.wallet || "-"}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default Order;
