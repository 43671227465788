export const TRANSLATIONS_UA = {
  navBar: {
    list: {
      item1: "Правила",
      item2: "KYC/AML",
      item3: "Контакти",
    },
    lang: {
      "ru-RU": "Русский",
      "en-US": "English",
      "ua-UA": "Українська",
    },
    logIn: "Вхід",
    create: "Реєстрація",
  },

  footer: {
    copy: "© Copyright 2023, All Rights Reserved",
    text: "Support 09:00 - 23:00 UTC +2",
  },

  exchange: {
    banner: {
      desc1: {
        text1: "Ціна формується від",
        text2: "WhiteBit.com",
        text3: ". Курс ",
        text4: "фіксується",
        text5: " до змін не більше ",
        text6: "0.025%",
        text7: " Кількість ",
        text8: "підтверджень",
        text9: " для зарахування ",
        text10: "20шт",
      },
      desc2: {
        text1: "Інформація про ",
        text2: "AML",
        text3: " Ми не приймаємо валюти понад",
        text4: " 70%",
        text5: " ризику. Повернення таких валют може зайняти час і",
        text6: " верифікацію",
        text7: " особистості.",
      },
    },

    giving: {
      title: "1. Віддаєте",
      min: "Мін:",
      max: "Макс:",
      tabs: {
        item1: "Усі",
        item2: "Crypto",
        item3: "Fiat",
        item4: "Cash",
      },
    },

    get: {
      title: "2. Отримаєте",
      reserve: "Резерв:",
    },

    data: {
      title: "3. Введення даних",
      walet: "адреса",
      firstName: "Surname",
      lastName: "Name",
      rules: {
        text: "Я згоден з",
        desc1: "правилами сервісу",
        desc2: "політикою KYC/AML",
      },
      btn: "Обміняти зараз",
      corse: "Курс:",
      massage: "Вам необхідно ознайомитись та погодитися з правилами сервісу.",
    },
  },

  order: {
    title: "Транзакція",
    pending: "Очікує",
    table: {
      item1: "Відправляєте",
      item2: "Отримуєте",
      item3: "Surname",
      item4: "Name",
    },
    side: {
      title: "Для завершення обміну вам потрібно переказати кошти:",
      get: "Сплатити:",
      address: "На адресу:",
    },
  },
};
