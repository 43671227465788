import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const basePath = "footer.";

  return (
    <div className={`footer`}>
      <div className="container">
        <div className="wrapper">
          <p>{t(`${basePath}copy`)}</p>
          <div className="info">
            <a href="/">
              <img src={require("../assets/img/icon/best.png")} alt="" />
            </a>
            <a href="/" className="icon">
              <img
                src={require("../assets/img/icon/mail.svg").default}
                alt=""
              />
            </a>
            <a href="/" className="icon">
              <img
                src={require("../assets/img/icon/telegram.svg").default}
                alt=""
              />
            </a>
          </div>
          <p>{t(`${basePath}text`)}</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
