export const TRANSLATIONS_RU = {
  navBar: {
    list: {
      item1: "Правила",
      item2: "KYC/AML",
      item3: "Контакты",
    },
    lang: {
      "ru-RU": "Русский",
      "en-US": "English",
      "ua-UA": "Українська",
    },
    logIn: "Войти",
    create: "Создать аккаунт",
  },

  footer: {
    copy: "© Copyright 2023, All Rights Reserved",
    text: "Support 09:00 - 23:00 UTC +2",
  },

  exchange: {
    banner: {
      desc1: {
        text1: "Цена формируется от",
        text2: "WhiteBit.com",
        text3: ". Курс ",
        text4: "фиксируется",
        text5: " до изменений не более ",
        text6: "0.025%",
        text7: " Кол-во ",
        text8: "подтверждений",
        text9: " для зачисления ",
        text10: "20шт",
      },
      desc2: {
        text1: "Информация об ",
        text2: "AML",
        text3: " Мы не принимаем валюты свыше",
        text4: " 70%",
        text5: " риска. Возврат таких валют может занять время и",
        text6: " верификацию",
        text7: " личности.",
      },
    },

    giving: {
      title: "1. Отдаёте",
      min: "Мин:",
      max: "Макс:",
      tabs: {
        item1: "Все",
        item2: "Crypto",
        item3: "Fiat",
        item4: "Cash",
      },
    },

    get: {
      title: "2. Получите",
      reserve: "Резерв:",
    },

    data: {
      title: "3. Ввод данных",
      walet: "адрес",
      firstName: "Фамилия",
      lastName: "Имя",
      rules: {
        text: "Я согласен с",
        desc1: "правилами сервиса",
        desc2: "политикой KYC/AML",
      },
      btn: "Обменять сейчас",
      corse: "Курс:",
      massage: "Вам необходимо ознакомиться и согласиться с правилами сервиса.",
    },
  },

  order: {
    title: "Транзакция",
    pending: "Ожидание",
    table: {
      item1: "Отправляете",
      item2: "Получаете",
      item3: "Фамилия",
      item4: "Имя",
    },
    side: {
      title: "Для завершения обмена вам нужно перевести средства:",
      get: "Перевести:",
      address: "На адрес:",
    },
  },

  rules: {
    title: "Правила",
    list: {
      item1: "Регламент работы",
      item2: "Зачисление",
      item3: "Фиксирование",
      item4: "AML/KYC Политика",
      item5: "Политика возвратов",
      item6: "Пользовательское соглашение",
      item7: "Предупреждение о рисках",
      item8: "Политика конфиденциальности",
    },
    regulations: {
      title: "Регламент работы",
      item1: {
        title: "Регламент проведения обменных операций",
        list: {
          item1:
            "Заявки, при которых наличные средства не участвуют в обмене — обрабатываются в автоматическом режиме — круглосуточно, и занимают от 2х до 10 минут, в некоторых случаях возможны задержки до 60 мин.",
          item2:
            "При обменах, в которых участвуют наличные средства — заявки обрабатываются в ручном режиме и согласовываются с нашей Службой поддержки, в ее рабочее время, смотреть график работы службы поддержки.",
          item3:
            "Служба поддержки доступна в будние дни с 8:00 до 23:00. Выходные дни — дежурный режим.",
          item4:
            "Курсы обмена обновляются каждую минуту и доступны на главной странице сервиса paystore.pro",
        },
      },
      item2: {
        title:
          "(Безналичные) Продажа Криптовалюты. Сроки зачисления на реквизиты после обмена.",
        list: {
          item1:
            "В случае отправки клиентом Криптовалюты — курс операции и сумма к выплате расчитываются после подтверждения транзакции в сети и после инициируется системой автоматически по указанным клиентом платёжным реквизитам. Выплаты производятся на карты Visa/MasterCard в валютах Гривна / Евро / Доллар / Тенге. Есть исключение по странам (санкции)",
          item2:
            "Срок зачисления зависит от банка-эмитента карты получателя (от нескольких секунд до 5 банковских дней). Если сумма к выплате превышает установленный лимит для одной операции — 14,000.00 грн, система отправит несколько платежей, кратных установленному лимиту.",
        },
      },
      item3: {
        title:
          "(Безналичные) Покупка Криптовалюты. Сроки отправки и обработки платежей.",
        list: {
          item1:
            "Платежи с карт Visa/MasterCard обрабатываются на стороне платёжной системы в режиме реального времени. После получения уведомления об успешности платежа, система моментально инициирует отправку Криптовалюты на указанный клиентом адрес в автоматическом режиме, с приоритетной комиссией.",
          item2:
            "Сроки зачисления Криптовалюты на адрес клиента зависит от нагрузки в сети Blockchain / USDT. Как правило, подтверждение после оплаты система получает моментально, однако в некоторых случаях, могут быть задержки на стороне платёжной системы или банка. Так же учтите, что сумма к выплате будет пересмотрена относительно актуального на момент оплаты курса — который фиксируется после поступления средств, через форму оплаты, на стороне платежной системы.",
        },
      },
      item4: {
        title:
          "(Наличные) Продажа Криптовалюты. Сроки получения наличных средств.",
        list: {
          item1:
            "В случае отправки клиентом Криптовалюты — курс операции и сумма к выплате рассчитываются после подтверждения транзакции в сети. Обмен происходит следующим образом и не может быть нарушен.",
          item2:
            "Уточняете наличие интересующей суммы в городе через поддержку > Создаете заявку > Оплачиваете > Присылаете хеш транзакции > Поддержка выдает «код индентификации» + адрес кассы > Идете на кассу > Называете «код индентификации» > Забираете наличные.",
          item3:
            "В некоторых странах возможно заполнение акнеты в связи с политикой данной страны, уточняйте у технической поддержки.",
          item4:
            "Оплата на месте невозможна, это не офис, это касса обменного пункта, на кассе сидит кассир, выдает / принимает деньги согласно ранее полученным кодам.",
        },
      },
      item5: {
        title:
          "(Наличные) Покупка Криптовалюты. Сроки отправки и безналичных средств.",
        list: {
          item1:
            "В случае получение клиентом Криптовалюты — курс операции и сумма к выплате рассчитываются после подтверждения кассы о поступление средств. Обмен происходит следующим образом и не может быть нарушен.",
          item2:
            "Создаете заявку > Поддержка выдает «код индентификации» + адрес кассы > Идете на кассу > Называете «код индентификации» > Вносите наличные > В течении 5 — 30 минут производится выплата на указанный Вами кошелек в заявке > В некоторых случаях (редко) возможны задержки по выплате до 60 минут (ожидать поступление средств можно около кассы)",
          item3:
            "В некоторых странах возможно заполнение акнеты в связи с политикой данной страны, уточняйте у технической поддержки.",
          item4:
            "Прийти на кассу без «код индентификации» невозможно, это не офис, это касса обменного пункта, на кассе сидит кассир, выдает / принимает деньги согласно ранее полученным кодам.",
        },
      },
      item6: {
        title: "Ошибка отправки платежа.",
        list: {
          item1:
            "При отправке платежа — система может получить отказ, о чем клиент будет уведомлен на странице статуса его обмена. Возможные причины: сбой в работе платёжной системы — одна из причин, возможные временные технические работы; клиент указал неверные реквизиты — несуществующий/некорректный/заблокированный номер счета; отказ в зачислении от банка эмитента — указана валютная карта или превышен лимит. Заявки с таким статусом обрабатываются оператором в течении рабочего времени. Все вопросы по сроку перевода можно задать, связавшись с нашей Службой поддержки. При невозможности отправить средства клиенту или передать их другим возможным способом, не по вине сервиса, сумма активов будет возвращена клиенту за вычетом комиссии на отправку.",
        },
      },
      item7: {
        title: "Краткая политика возвратов платежей.",
        list: {
          item1:
            "Возврат Криптовалюты инициируется оператором в течении 24 часов, после обращения клиента. Возврат Фиатных средств инициируется после подачи запроса в Платёжную систему или Банк — эквайер и может занимать до 2-ух банковских рабочих недель. Активы будут отправлены на те же реквизиты, с которых клиент выполнил перевод средств в пользу сервиса. В случае замены реквизитов для возврата средств, со стороны клиента, сервис вправе запросить любую информацию, для идентификации клиента как истинного держателя активов.",
        },
      },
      item8: {
        title: "Покупка Криптовалюты. Оплата с ворованных карт.",
        list: {
          item1:
            "В случае подозрения в использовании ворованных карточных данных, сервис оставляет за собой право приостановить выплату средств — до момента прояснения ситуации.",
        },
      },
      item9: {
        title: "Проверка личности пользователя.",
        list: {
          item1:
            "В некоторых случаях, при оформлении возвратов или выводов на другие реквизиты, сервис может запросить от пользователя документы — подтверждающие личность и провести Skype — Call, для полной идентификации пользователя и его реквизитов.",
        },
      },
      item10: {
        title: "Регламент решения проблемных ситуаций.",
        list: {
          item1:
            "Решение всех проблемных ситуаций происходит через нашу Службу поддержки. Для решения Вашей проблемы, обязательно укажите номер заявки, детально опишите проблему, при необходимости укажите сразу новые реквизиты. Сроки рассмотрения обращения — в Рабочее время службы поддержки. В нерабочее время, консультации будут предоставлены по возможности максимально оперативно.",
        },
      },
      item11: {
        title: "Форс — мажорные обстоятельства.",
        list: {
          item1:
            "Возможно отклонение от регламента выполнения операций в случае ситуаций, возникшие не по вине Сервиса. В большинстве операций участвует третья сторона (банки, платёжные системы) и в случае неполадок, на стороне посредника (неработоспособность сайта, API, технические работы, потеря перевода внутри платёжной системы и т.д.), к срокам по регламенту прибавляется время на устранение неполадок на стороне сервиса — посредника",
        },
      },
    },
    enrollment: {
      title: "Зачисление",
      item1: {
        title:
          "Кол-во подтверждений необходимое для фиксирования и зачисления:",
        list: {
          item1: "1. USDT TRC — 20 — подтверждений транзакции в сети.",
          item2: "2. USDT ERC — 32 —  подтверждения транзакции в сети.",
          item3: "3. BTC — 2 —  подтверждения транзакции в сети.",
          item4: "4. ETH — 32 —  подтверждения транзакции в сети.",
          item5: "5. XRP - 1 - подтверждение транзакции в сети.",
          item6: "6. DOGE - 15 - подтверждений транзакции в сети.",
          item7: "7. SOL - 500 - подтверждений транзакции в сети.",
          item8: "8. ADA - 40 - подтверждений транзакции в сети.",
          item9: "9. TRX - 20 - подтверждений транзакции в сети.",
          item10: "10. DOT - 50 - подтверждений транзакции в сети.",
          item11: "11. LTC - 10 - подтверждений транзакции в сети.",
          item12: "12. BCH - 10 подтверждений транзакции в сети.",
          item13: "13. LINK - 32 - подтверждения транзакции в сети.",
          item14: "14. XLM - 1 -подтверждение транзакции в сети.",
          item15: "15. XMR - 10 - подтверждений транзакции в сети.",
          item16: "16. ATOM - 70 - подтверждений транзакции в сети.",
          item17: "17. SHIB - 32 - подтверждения транзакции в сети.",
          item18: "18. ZEC - 100 - подтверждений транзакции в сети.",
          item19: "19. EOS - 1 - подтверждение транзакции в сети. ",
          item20: "20. NEAR - 3- подтверждения транзакции в сети.",
          item21: "21. ETC - 300 - подтверждений транзакции в сети.",
          item22: "22. DASH - 10 - подтверждений транзакции в сети.",
          item23: "23. AVAX - 1 - подтверждение транзакции в сети. ",
        },
      },
    },
    fixing: {
      title: "Фиксирование",
      item1: {
        list: {
          item1: "Курс в автоматических направлениях фиксируется.",
          item2: "Но при изменениях более чем на 0.025 % будет пересчитан.",
          item3:
            "Зачисление и пересчет происходит по стакану биржи WhiteBit.com",
          item4:
            "Количество подтверждений в сети необходимых для зачисления смотреть.",
        },
      },
    },
    aml: {
      title: "AML/KYC Политика",
      desc: "Настоящая Политика противодействия легализации доходов, полученных преступным путем, и финансированию терроризма (далее - «Политика AML/CFT») PayStore предназначена для предотвращения и минимизации возможных рисков вовлечения PayStore в любую незаконную деятельность.",
      item1: {
        title: "Определение легализации доходов, полученных преступным путем",
        desc: "Легализация доходов, полученных преступным путем, означает",
        list: {
          item1:
            "Конвертацию или перевод имущества, полученного в результате преступной деятельности или имущества, полученного вместо такого имущества, сознавая, что такое имущество получено в результате преступной деятельности или от участия в такой деятельности, для целей сокрытия или маскировки незаконного происхождения имущества или оказания помощи любому лицу, участвующему в совершении такой деятельности, уклониться от правовых последствий своих действий;",
          item2:
            "Приобретение, хранение или использование имущества, полученного в результате преступной деятельности или имущества, полученного вместо такого имущества, зная, на момент получения, что такое имущество было получено в результате преступной деятельности или от участия в ней;",
          item3:
            "Сокрытие или маскировку истинной природы, источника, местонахождения, перемещения, прав в отношении, или владения имуществом, полученным в результате преступной деятельности или имуществом, полученным вместо такого имущества, зная, что такое имущество получено в результате преступной деятельности или от участия в такой деятельности.",
        },
        desc1:
          "Легализация доходов также означает участие в, объединение с целью совершения, попытки совершения и пособничество, подстрекательство, содействие и консультирование по совершению любого из вышеперечисленных действий.",
        desc2: "Определение финансирования терроризма",
        desc3:
          "Финансирование терроризма определяется как финансирование и поддержка акта терроризма и его совершения, а также финансирование и поддержку поездки в целях терроризма.",
        desc4:
          "Международные и местные законы и нормативные акты обязывают PayStore внедрять эффективные внутренние процедуры и механизмы для предотвращения отмывания денег, финансирования терроризма, наркотрафика и торговли людьми, распространения оружия массового уничтожения, коррупции и взяточничества и принимать меры в случае любого вида подозрительной деятельности его Пользователей.",
      },
      item2: {
        title: "Политика AML/CFT охватывает следующие вопросы",
        list: {
          item1: "внутренние контрольные механизмы",
          item2: "должностное лицо по соблюдению требований AML/CFT",
          item3: "обучение персонала",
          item4: "процедуры проверки",
          item5: "мониторинг, оценка рисков и риск-ориентированный подход",
          item6: "аудит программы AML/CFT",
        },
      },
      item3: {
        title: "Внутренние контрольные механизмы",
        desc: "Мы разработали структурированную систему внутренних контрольных механизмов для соблюдения применимых законов и нормативных актов о борьбе с отмыванием денег и финансированием терроризма (далее - «AML/CFT»), включая, но не ограничиваясь:",
        list: {
          item1:
            "установление личности клиента и проверку предоставленной информации;",
          item2:
            "установление особого режима для работы с клиентами, являющимися политически значимыми лицами (ПЭП);",
          item3:
            "выявление необычной активности и содействие в сообщении о подозрительной деятельности (СРА);",
          item4: "хранение документации клиентов и истории транзакций.",
        },
      },
    },
    returns: {
      title: "Политика возвратов",
      list: {
        item1: {
          title: "ОБЩИЕ ПОЛОЖЕНИЯ И ОПРЕДЕЛЕНИЯ",
          item1:
            "1. Администратором персональных данных, собранных через сайт https://www.paystore.pro/ , является PAYSTORE Sp. z o.o. находится в Варшаве (00-867), Aleja Jana Pawla II 27, зарегистрированный под номером: 0001060590, NIP: 5273076950, акционерный капитал 5 000,00 злотых по смыслу ст. 8 Закона от 18 июля 2002 г. о предоставлении электронных услуг.",
          item2:
            "2. Связаться с Администратором можно по электронной почте: PayStore.Pro@proton.me",
          item3: "3. Определения:",
          item4:
            "• Файлы cookie – означают ИТ-данные, в частности небольшие текстовые файлы, сохраняемые и хранятся на устройствах, через которые Пользователь использует Сайт;",
          item5:
            "• Персональные данные – информация об идентифицированном или идентифицируемом физическом лице. Идентифицируемое физическое лицо — это лицо, которое может быть идентифицировано прямо или косвенно, в частности, посредством ссылки на идентификатор, такой как имя, идентификационный номер, данные о местоположении, онлайн-идентификатор, или на один или несколько факторов, специфичных для физического, физиологического, генетическая, психическая, экономическая, культурная или социальная идентичность личности;",
          item6:
            "• Профилирование - означает любую форму автоматизированной обработки персональных данных, которая заключается в использовании персональных данных для оценки определенных личных факторов физического лица, в частности, для анализа или прогнозирования аспектов, касающихся последствий работы этого физического лица, его или ее экономической деятельности. ситуация, здоровье, личные предпочтения, интересы, авторитет, поведение, местоположение или передвижение;",
          item7:
            "• Обработка – означает операцию или набор операций, выполняемых с персональными данными или наборами персональных данных, независимо от того, с помощью автоматизированных средств или нет, например, сбор, запись, организация, структурирование, хранение, адаптация или изменение, загрузка, просмотр, использование, раскрытие путем отправки, распространения или иного предоставления доступа, выравнивания или объединения, ограничения, удаления или уничтожения;",
          item8: "• Веб-сайт – https://www.paystore.pro/",
          item9:
            "• Устройство – электронное устройство, позволяющее обрабатывать, получать и отправлять данные, например ноутбук, компьютер, смартфон, планшет, мобильный телефон;",
          item10:
            "• Пользователь – означает лицо, которому услуги могут быть предоставлены в электронном виде в соответствии с положениями законодательства или с которым может быть заключен Договор на оказание электронных услуг;",
          item11:
            "• Регламент – означает документ, определяющий правила и условия использования Сайта;",
          item12:
            "• Платежный оператор – организация, которая обрабатывает платежи Пользователей Поставщику услуг с целью их обмена на виртуальные валюты;",
          item13:
            "• Поставщики – внешние организации, предоставляющие контент, доступный в",
          item14: "Веб-сайт, в частности предложения, опросы и реклама.",
        },
        item2: {
          title: "ПРАВОВАЯ ОСНОВА И ЦЕЛИ ОБРАБОТКИ ДАННЫХ ПОЛЬЗОВАТЕЛЯ",
          item1:
            "1. Персональные данные, собранные Администратором, обрабатываются в соответствии с положениями Регламента (ЕС) 2016/679 Европейского Парламента и Совета от 27 апреля 2016 года о защите физических лиц в отношении обработки персональных данных и о свободном перемещении таких данных, а также об отмене директивы 95/46/EC (далее «GDPR»), Закона от 10 мая 2018 г. о защите персональных данных (Законодательный вестник 2019 г., поз. 1781) и Закон от 18 июля 2002 г. об оказании электронных услуг (Законодательный вестник 2020 г., ст. 344).",
          item2:
            "2. Администратор обрабатывает персональные данные, предоставленные или ставшие доступными Пользователю в связи с использованием Сайта, в целях:",
          item3:
            "• заключение и исполнение договора на оказание электронных услуг и обеспечение функциональности Сайта (объем данных: IP-адреса, адрес кошелька, данные платежной карты, адрес электронной почты, номер телефона и другие необходимые данные об Устройстве, используемом Пользователь - в соответствии со статьей 6(1)(b) GDPR, т. е. в связи с тем, что обработка необходима для исполнения договора, стороной которого является субъект данных; • информирование Пользователей о вопросах, связанных с функционирование Веб-сайта (объем данных: адрес электронной почты), электронная почта, данные Устройства, используемого Пользователем) - в соответствии со статьей 6(1)(b) и (f) GDPR, т.е. в связи с тем, что что обработка необходима для исполнения договора, стороной которого является субъект данных, а также для реализации целей, вытекающих из юридически обоснованных интересов, преследуемых Администратором или третьим лицом;",
          item4:
            "• предъявление претензий и защита прав (объем данных: все данные, полученные от Пользователя, необходимые для доказательства наличия претензии или защиты прав) – согласно ст. 6 раздел 1 буква f GDPR, т.е. в связи с тем, что обработка необходима для целей законных интересов, преследуемых Администратором или третьей стороной;",
          item5:
            "• выполнение юридических обязательств, возложенных на Администратора в связи с ведением бизнеса, в частности, как обязанное учреждение по смыслу Закона о противодействии отмыванию денег и финансированию терроризма (объем данных: все данные, полученные от Пользователя) - в соответствии с Искусство. 6 раздел 1 буква c GDPR, т.е. в связи с тем, что обработка необходима для выполнения юридических обязательств Администратора;",
          item6:
            "• проведение собственной маркетинговой и рекламной деятельности (объем данных: все данные, полученные от Пользователя) – согласно ст. 6 раздел 1 буква е GDPR;",
          item7:
            "• проведение маркетинговой и рекламной деятельности на основании отдельного согласия (статья 6(1)(a) GDPR);",
          item8:
            "• направление коммерческой информации в электронном виде в соответствии со ст. 10 раздел 2 Закон об оказании услуг электронными средствами от 18 июля 2002 г. (Законодательный вестник 2017 г., ст. 1219, с изменениями), в том числе направление уведомлений (объем данных: все данные, полученные от Пользователя) - на основании отдельного предоставленное согласие (Статья 6(1)(a) GDPR);",
        },
        item3: {
          title: "ДАННЫЕ, СОБИРАЕМЫЕ АДМИНИСТРАТОРОМ САЙТА",
          item1:
            "1. Администратор Сайта собирает или может собирать следующие персональные данные через Сайт или при прямом контакте с Пользователем:",
          item2:
            "• идентификационные и контактные данные (адрес электронной почты, номер телефона);",
          item3: "• адрес кошелька виртуальной валюты;",
          item4:
            "• данные об Устройстве, используемом Пользователем (включая IP-адрес, тип устройства, марку устройства, модель устройства, имя устройства, язык устройства);",
          item5:
            "• данные об использовании Пользователем программного обеспечения VPN или Устройства с модифицированной операционной системой;",
          item6:
            "• иные данные, добровольно предоставленные Пользователем при общении с Администратором, включая данные о его Устройстве, данные переписки и иные данные, не перечисленные выше.",
          item7:
            "2. Просмотр содержимого Сайта не требует предоставления персональных данных, кроме автоматически полученной информации о параметрах подключения (IP-адресе).",
          item8:
            "3. В рамках услуг, предоставляемых на Сайте, Администратор собирает следующие персональные данные:",
          item9: "• электронный адрес;",
          item10: "• номер мобильного телефона;",
          item11: "• адрес кошелька виртуальной валюты;",
          item12: "• данные платежной карты;",
          item13: "• Айпи адрес;",
          item14:
            "• иные необходимые данные об Устройстве, используемом Пользователем.",
          item15:
            "4. Администратор оставляет за собой право в рамках использования сервисов Сайта, в том числе платежной системы, также может обрабатывать персональные данные Пользователя на основании собственной политики конфиденциальности.",
        },
        item4: {
          title: "COOKIE",
          item1:
            "1. Администратор собирает файлы cookie, связанные с активностью Пользователя на Сайте.",
          item2:
            "2. Администратор может использовать профилирование обрабатываемых данных на основании ст. 6 раздел 1 буква c GDPR в связи с с обязательствами, вытекающими из Закона от 1 марта 2018 года о противодействии отмыванию денег и финансированию терроризма.",
          item3:
            "3. При использовании Сайта на устройстве Пользователя сохраняются небольшие файлы, в частности текстовые файлы, которые используются для запоминания решений Пользователя, поддержания сеанса Пользователя, запоминания введенных данных, сбора информации об устройстве Пользователя и его посещении для обеспечения безопасности, а также анализ посещений и корректировка контента.",
          item4:
            "4. Файлы cookie не содержат данных, идентифицирующих Пользователя, а значит, на их основе невозможно определить его личность. Файлы, используемые Сайтом, никоим образом не наносят вреда Пользователю или устройству и не влияют на его программное обеспечение или настройки.",
          item5:
            "5. Файл cookie закрепляется за Пользователем после входа на главную страницу Сайта или любую из его подстраниц.",
          item6:
            "6. Система cookie не мешает работе компьютера Пользователя и может быть отключена.",
          item7:
            "7. Пользователь может настроить браузер на блокировку определенных типов файлов cookie и других технологий, указав допустимый объем сбора информации.",
          item8:
            "8. Использование сайта без изменения настроек браузера, т.е. принятие файлов cookie и аналогичных технологий по умолчанию, означает согласие на их использование для целей, указанных в настоящей Политике конфиденциальности.",
          item9:
            "9. Администратор сообщает, что если файлы необходимы для работы Сайта, ограничение их использования может помешать использованию сервисов Сайта.",
          item10:
            "10. Настройки браузера устройства Пользователя должны разрешать сохранение файлов cookie и разрешать согласие путем нажатия кнопки «ОК» в окне, которое появляется после входа на Веб-сайт с информацией: «Этот веб-сайт использует файлы cookie для обеспечения правильного предоставления услуги. Продолжая использовать сайт, вы соглашаетесь с их использованием – эти файлы будут сохранены на вашем устройстве.",
          item11:
            "11. Поставщики, включая операторов платежей, могут собирать файлы cookie на основании отдельно предоставленного согласия Пользователя.",
        },
        item5: {
          title: "ВРЕМЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ",
          item1:
            "1. Персональные данные будут обрабатываться в течение периода:",
          item2:
            "• необходимо для реализации договора на оказание электронных услуг, указанного в Регламенте, заключенного посредством Сайта, в том числе после его исполнения в связи с возможностью реализации сторонами своих прав по договору, а также в связи с возможным взыскание дебиторской задолженности – до истечения срока исковой давности по требованиям;",
          item3:
            "• до момента отзыва согласия или подачи возражения против обработки данных – в случаях обработки персональных данных Пользователя на основании отдельного согласия;",
          item4:
            "2. Администратор хранит персональные данные Пользователей также тогда, когда это необходимо для выполнения своих юридических обязательств, разрешения споров, обеспечения исполнения обязательств Пользователя, обеспечения безопасности и предотвращения мошенничества и злоупотреблений.",
        },
        item6: {
          title: "РАЗРЕШЕНИЯ ПОЛЬЗОВАТЕЛЯ",
          item1:
            "1. Администратор обеспечивает реализацию Пользователями прав, указанных в разделе 2 ниже. Чтобы воспользоваться правами, отправьте соответствующий запрос (запрос) по электронной почте на адрес: PayStore.Pro@proton.me или традиционной почтой на адрес местонахождения Администратора.",
          item2: "2. Пользователь имеет право:",
          item3:
            "• доступ к содержанию данных – в соответствии со ст. 15 GDPR;",
          item4:
            "• исправление/обновление данных – в соответствии со ст. 16 GDPR;",
          item5: "",
          item6: "• удаление данных – в соответствии со ст. 17 GDPR;",
          item7:
            "• ограничения на обработку данных – в соответствии со ст. 18 GDPR;",
          item8: "• передача данных – в соответствии со ст. 20 GDPR;",
          item9:
            "• возражать против обработки данных – в соответствии со ст. 21 GDPR;",
          item10:
            "• отзыв согласия в любое время, однако отзыв согласия не влияет на законность обработки, осуществляемой на основании согласия до его отзыва – в соответствии со ст. 7 раздел 3",
          item11: "GDPR;",
          item12:
            "• подать жалобу в надзорный орган, т.е. председателю Управления по защите персональных данных – в соответствии со ст. 77 GDPR;",
          item13:
            "3. Администратор рассмотрит поданные запросы без неоправданной задержки, но не позднее, чем в течение одного месяца со дня их поступления. Однако, если - из-за сложного характера запроса или количества запросов - Администратор не сможет рассмотреть запрос Пользователя в указанный срок, он сообщит Пользователю о предполагаемом продлении срока и укажет крайний срок. за рассмотрение заявления, но не более 2 месяцев.",
          item14:
            "4. Администратор информирует каждого получателя, которому были раскрыты персональные данные, об исправлении или удалении персональных данных или ограничении обработки, произведенных им в соответствии с запросом Пользователя, если это не окажется невозможным или не потребует несоразмерных усилий.",
        },
        item7: {
          title: "НЕОБХОДИМОСТЬ ПРЕДОСТАВЛЕНИЯ ДАННЫХ",
          item1:
            "1. Для выполнения договора Администратор может сделать данные, полученные от Пользователей, доступными для юридических лиц, в том числе: сотрудников, сотрудников, организаций, предоставляющих юридические и ИТ-услуги Администратору и поставщикам, включая поставщиков платежей. Кроме того, Администратор предоставляет собранные персональные данные лицу, с которым заключил договор о поручении обработки персональных данных.",
          item2:
            "2. В таких случаях объем передаваемых данных ограничивается необходимым минимумом. Кроме того, информация, предоставленная Пользователями, может быть доступна компетентным государственным органам, ограничиваясь ситуациями, когда этого требует действующее законодательство.",
          item3:
            "3. Получателям, не указанным выше, обработанные персональные данные не предоставляются извне в форме, позволяющей идентифицировать Пользователей, за исключением случаев, когда Пользователь дал согласие на конкретное раскрытие данных.",
        },
        item8: {
          title: "ТЕХНИЧЕСКИЕ МЕРЫ",
          item1:
            "1. Администратор прилагает все усилия для обеспечения безопасности данных Пользователей и защиты их от действий третьих лиц, а также контролирует безопасность данных на протяжении всего периода их владения таким образом, чтобы обеспечить защиту от несанкционированного доступа, повреждения, искажения, уничтожения. или потеря данных.",
          item2:
            "2. Администратор применяет необходимые меры безопасности к серверам, соединениям и Сайту. Однако действия Администратора могут оказаться недостаточными в случае несоблюдения Пользователями правил безопасности.",
        },
        item9: {
          title:
            "ПЕРЕДАЧА ПЕРСОНАЛЬНЫХ ДАННЫХ ЗА ПРЕДЕЛЫ ЕВРОПЕЙСКОЙ ЭКОНОМИЧЕСКОЙ ЗОНЫ",
          item1:
            "1. Персональные данные пользователей не передаются в страны за пределами ЕЭЗ. Администратор использует серверы для хранения данных, расположенные в странах ЕЭЗ.",
          item2:
            "2. Администратор оставляет за собой право поставщиков, включая операторов платежей, обрабатывать и передавать персональные данные за пределы Европейской экономической зоны.",
        },
        item10: {
          title: "СУБЪЕКТЫ, ОБРАБАТЫВАЮЩИЕ ДАННЫЕ ОТ ИМЕНИ АДМИНИСТРАТОРА",
          item1:
            "1. Персональные данные Пользователей могут быть переданы на обработку от имени Администратора порталам, поддерживающим маркетинговую кампанию Администратора. Каждый обрабатывающий субъект обязан обеспечивать безопасность обработки и соблюдать принципы обработки ваших персональных данных в той же степени, что и Администратор.",
        },
        item11: {
          title: "ИЗМЕНЕНИЕ В ПОЛИТИКЕ КОНФИДЕНЦИАЛЬНОСТИ И COOKIES",
          item1:
            "1. Администратор имеет право изменить настоящий документ, о чем Пользователь будет уведомлен способом, позволяющим Пользователю ознакомиться с изменениями до их вступления в силу, например, путем размещения соответствующей информации на Сайте.",
          item2:
            "2. Если у Пользователя есть возражения против внесенных изменений, он может потребовать удаления персональных данных с Сайта. Продолжение использования Сайта после публикации или направления уведомления об изменениях настоящего документа считается согласием на сбор, использование и раскрытие персональных данных Пользователя в соответствии с обновленным содержанием документа.",
          item3:
            "3. Настоящий документ не ограничивает какие-либо права Пользователя в соответствии с общеприменимым законодательством",
        },
      },
    },
    agreement: {
      title: "Пользовательское соглашение",
      list: {
        item1: {
          title: "ОСНОВНЫЕ ПОЛОЖЕНИЯ",
          item1:
            "1. Настоящие Правила определяют правила использования онлайн-пункта обмена валюты, доступного по адресу: https://www.paystore.pro/, а также предоставления услуг с помощью электронных средств Поставщиком услуг: PAYSTORE Sp. z o.o. находится в Варшаве (00-867), Aleja Jana Pawla II 27, зарегистрированный под номером: 0001060590, NIP: 5273076950, акционерный капитал 5 000,00 злотых по смыслу ст. 8 Закона от 18 июля 2002 г. о предоставлении электронных услуг.",
          item2:
            "2. Деятельность Поставщика услуг подлежит обязательной регистрации в реестре деятельности в сфере виртуальных валют, указанном в ст. 129М Закона от 1 марта 2018 года о противодействии отмыванию денег и финансированию терроризма. У Поставщика услуг имеется действующая запись в реестре под номером RDWW-966 от 5 октября 2023 года. Реестр ведет директор Палаты налогового администрирования в Катовице, текущий статус которого доступен на официальном сайте.",
          item3:
            "3. Поставщик услуг является обязанным учреждением по смыслу ст. 2 раздел 1 пункта 12 Закона от 1 марта 2018 года о противодействии отмыванию денег и финансированию терроризма, поэтому применяются внутренние процедуры, направленные на противодействие отмыванию денег и финансированию терроризма.",
          item4:
            "4. Каждый Пользователь обязан с момента совершения действий по использованию Сайта https://www.paystore.pro/ соблюдать положения настоящего Регламента. Содержание Правил доступно Пользователям на Сайте и становится доступным при процедуре регистрации.",
          item5:
            "5. Пользователь обязан ознакомиться с содержанием настоящих Правил. Принятие Правил является добровольным, но является условием использования Сайта https://www.paystore.pro/",
          item6:
            "6. Правила доступны в электронной форме на веб-сайте Поставщика услуг таким образом, что Пользователи могут хранить и извлекать их в ходе обычной деятельности.",
        },
        item2: {
          title: "ОПРЕДЕЛЕНИЯ",
          item1:
            "1. Поставщиком услуг: PAYSTORE Sp. z o.o. находится в Варшаве (01-001), Aleja Jana Pawla II 27, зарегистрированный под номером: 0001060590, NIP: 5273076950, акционерный капитал 5 000,00 злотых по смыслу ст. 8 Закона от 18 июля 2002 г. о предоставлении электронных услуг.",
          item2:
            "2. Регламент – настоящий документ, определяющий правила и условия использования Сайта.",
          item3:
            "3. Веб-сайт – онлайн-обменник виртуальных валют, доступный по адресу: https://www.paystore.pro/",
          item4:
            "4. Услуги – услуги, предоставляемые Поставщиком услуг в электронной форме через Сайт, описанные в настоящих Правилах.",
          item5:
            "5. Устройство – электронное устройство, позволяющее обрабатывать, получать и отправлять данные, например ноутбук, компьютер, смартфон, планшет, мобильный телефон.",
          item6:
            "6. Пользователь - означает лицо, которому услуги могут быть оказаны в электронной форме в соответствии с положениями законодательства или с которым может быть заключен договор на оказание услуг в электронной форме.",
          item7:
            "7. KYC — действия по аутентификации пользователя, предпринимаемые для использования функций Веб-сайта, в частности, предоставление имени, фамилии, гражданства, даты рождения и отправка соответствующих фотографий удостоверения личности вместе с изображением Пользователя.",
          item8:
            "8. Авторизация – действия по аутентификации Пользователя, предпринимаемые во время каждой транзакции, заключающиеся в проверке Пользователя путем отправки SMS и сообщений на указанный адрес электронной почты.",
          item9:
            "9. Учетная запись Пользователя – совокупность ресурсов и авторизаций, закрепленных за Пользователем в рамках Сайта, содержащая информацию, необходимую для его авторизации и позволяющую использовать сервис.",
          item10:
            "10. Средство платежа – бумажные или цифровые деньги, выпущенные Национальным банком Польши, иностранными центральными банками или другими органами государственного управления, которые являются законным платежным средством.",
          item11:
            "11. Виртуальные валюты – валюты по смыслу ст. 2 раздел 2 пункта 26 Закона от 1 марта 2018 года о противодействии отмыванию денег и финансированию терроризма.",
          item12:
            "12. Транзакция – обмен валюты по заказу Пользователя, заключающийся в покупке, продаже или обмене виртуальных валют или платежных средств.",
          item13:
            "13. Адрес кошелька – строка символов электронного набора идентификационных данных, обеспечивающая возможность хранения и управления виртуальными валютами.",
          item14:
            "14. Услуги – электронные (цифровые) услуги по смыслу Закона от 18 июля 2002 года о предоставлении электронных услуг, предоставляемые через Сайт, в частности заключающиеся в оказании услуг по обмену валюты.",
          item15:
            "15. Платежный оператор – организация, которая обрабатывает платежи Пользователей Поставщику услуг с целью их обмена на виртуальные валюты.",
          item16:
            "16. Поставщики – внешние субъекты, которые предоставляют контент, доступный на Сайте, в частности предложения, опросы и рекламу.",
        },
        item3: {
          title: "ДОСТУП И ИСПОЛЬЗОВАНИЕ ВЕБ-САЙТА",
          item1:
            "1. Основной Услугой, предоставляемой посредством Сайта, является возможность обмена между виртуальными валютами и платежными средствами на условиях, указанных в §4 настоящих Правил. Поставщик услуг оставляет за собой право также предоставлять на Сайте другие Услуги.",
          item2:
            "2. Поставщик услуг предоставляет Услуги на условиях, установленных настоящими Правилами.",
          item3:
            "3. Веб-сайт доступен всем Пользователям Устройств, подключенным к Интернету через веб-сайт https://www.paystore.pro/. Для использования Сайта Пользователю необходимо правильно ввести адрес сайта и запустить его на своем Устройстве.",
          item4:
            "4. Услуги с использованием Сайта предоставляются 24 часа в сутки, 7 дней в неделю, по индивидуальному запросу Пользователя, заключающемуся в запуске Сайта и использовании Сервисов.",
          item5:
            "5. Использование Сервисов может быть территориально ограничено. Поставщик услуг предлагает доступ к Услугам в соответствии с местным законодательством. Поставщик услуг не разрешает Пользователям из юрисдикции: Кубы, Ирана, Израиля, Ирака, Южной Кореи, Судана, Южного Судана, Сирии, Пакистана, Нигерии, Индии, Индонезии, Бенина использовать Услуги.",
          item6:
            "6. Поставщик услуг оставляет за собой право в любое время выбирать, ограничивать или отказывать в предоставлении услуг в определенной юрисдикции.",
          item7:
            "7. Веб-сайт и все содержащиеся на нем материалы, информация и решения, а также выбор и расположение контента, логотипов, графических элементов и товарных знаков, представленных на веб-сайте, являются объектом исключительных прав Поставщика услуг или его деловых партнеров. .",
          item8:
            "8. Для запуска и корректной работы Сайта необходимо подключить устройство к Интернету, активировав мобильные сети или Wi-Fi.",
          item9:
            "9. При акцепте Регламента между Поставщиком услуг и Пользователем заключается договор на оказание электронных услуг. Контракт заключается на неопределенный срок.",
          item10:
            "10. Принятие Регламента Пользователем необходимо перед использованием Сервисов и заказом Сделки. Пользователь образования",
        },
        item4: {
          title: "УСЛОВИЯ ЭКСПЛУАТАЦИИ",
          item1:
            "1. Функция Веб-сайта по умолчанию заключается в предоставлении услуг обмена виртуальных валют и платежных средств.",
          item2:
            "2. В рамках услуги обмена валюты пользователь может выбрать транзакцию, связанную с покупкой или продажей виртуальных валют, предполагающую обмен между виртуальными валютами или обмен между платежными средствами и виртуальными валютами.",
          item3:
            "3. Чтобы использовать определенный тип транзакции, Пользователь обязан выбрать конкретный тип транзакции и способ пополнения и вывода средств.",
          item4:
            "4. Диапазон доступных способов пополнения и вывода средств может меняться в зависимости от текущей доступности. На веб-сайте обычно предусмотрены различные формы пополнения и снятия средств, в том числе:",
          item5: "• наличные деньги;",
          item6: "• перевод виртуальной валюты;",
          item7: "• оплата картой Visa или MasterCard.",
          item8: "5. Для совершения транзакции Пользователь обязан:",
          item9: "• утверждение формы и размера оплаты;",
          item10: "• утверждение формы оплаты;",
          item11:
            "• указание адреса кошелька виртуальной валюты, на который будут переведены средства;",
          item12:
            "• заполнение персональных данных, необходимых для авторизации;",
          item13:
            "• выполнение проверки капчи или другой подобной меры безопасности;",
          item14:
            "• подтверждение предоставленных данных с помощью кнопки Обменять;",
          item15:
            "6. Если вы решите приобрести виртуальную валюту с помощью карты Visa или MasterCard, Пользователь дополнительно обязан предоставить данные карты, необходимые для обработки транзакции, включая номер карты, срок действия и код CVV или CVC.",
          item16:
            "7. После корректного заполнения данных Пользователь обязан ввести код авторизации, отправленный на указанные данные, в соответствии с выбранным способом авторизации.",
          item17:
            "8. В целях поддержания обменного курса Пользователь обязан завершить транзакцию в течение:",
          item18:
            "• 5 минут на пополнение и снятие наличных, отсчитываемые с момента подтверждения данных;",
          item19:
            "• 10 минут на депозиты и снятие средств с помощью Visa или MasterCard.",
          item20:
            "9. Превышение времени означает истечение срока действия транзакции и изменение обменного курса, за что Поставщик услуг не несет ответственности.",
          item21:
            "10. Поставщик услуг оставляет за собой право изменять формы пополнения и вывода средств. Некоторые формы, включая снятие наличных, могут зависеть от соблюдения Пользователем дополнительных требований, указанных в разделе 18 и далее.",
          item22:
            "11. Использование услуг, доступных на Сайте, может взиматься плата. Поставщик услуг взимает комиссию за предоставляемые услуги по обмену валюты в виде:",
          item23:
            "• Комиссия 1-10%, которая включена в отображаемый на данный момент курс обмена виртуальной валюты;",
          item24:
            "• транзакционные издержки, включенные в отображаемую сумму виртуальных валют, которые Пользователь покупает или продает.",
          item25:
            "12. Поставщик услуг оставляет за собой право на то, что транзакционные издержки по покупке и продаже виртуальных валют не являются фиксированными. Их сумма зависит от текущего состояния сети, отвечающей за работу данной виртуальной валюты.",
          item26:
            "13. В рамках каждой транзакции Пользователь информируется об общей сумме транзакции, т.е. включая комиссии, указанные в разделе 11.",
          item27:
            "14. Пользователь имеет право зарегистрировать учетную запись на Сайте. Для этого необходимо выполнить действия по аутентификации, состоящие из установки логина, пароля и указания адреса электронной почты.",
          item28:
            "15. Пользователь, имеющий зарегистрированную учетную запись на Сайте, имеет право на получение партнерской ссылки и дополнительных преимуществ, в том числе уменьшения размера комиссий, указанных в разделе 11 и получение дополнительной комиссии от транзакций, совершенных Пользователями по партнерской ссылке.",
          item29:
            "16. Каждая транзакция на Сайте требует авторизации, которая заключается в подтверждении транзакции путем ввода кода авторизации или перехода по соответствующей ссылке, полученной по SMS или на указанный адрес электронной почты.",
          item30:
            '17. Поставщик услуг определяет текущие курсы виртуальных валют, используемых для проведения транзакций на постоянной основе, на основе текущих курсов платформы Coingecko, предоставленных XE.COM INC. ("XE"), WhiteBIT Tech Sp. Z.o.o, Binance Poland Spółka z Ograniczoną Odpowiedzialnością',
          item31:
            "18. Поставщик услуг в соответствии с внутренними процедурами противодействия отмыванию денег и финансированию терроризма может запросить у Пользователя дополнительную информацию. Процедура KYC выполняется каждый раз для транзакций, превышающих 1000 евро. В рамках KYC Пользователь обязан предоставить данные, в том числе:",
          item32:
            "• скан или фотография действующего документа, удостоверяющего личность Пользователя (например, паспорта, удостоверения личности);",
          item33: "• фотография, закрывающая лицо Пользователя в формате;",
          item34:
            "• фотографию лица Пользователя вместе с документом, указанным в пункте 1;",
          item35: "• имя и фамилия Пользователя",
          item36: "• Дата рождения.",
          item37:
            "19. Оплата наличными осуществляется в офисе Поставщика услуг согласно текущему графику работы.",
          item38:
            "20. Перед использованием Сервисов Сайта Пользователь обязан ознакомиться с настоящими Правилами и принять их каждый раз при размещении заказа на сделку.",
          item39:
            "21. Пользователь несет ответственность за предоставление неверных данных во время транзакции, авторизации или KYC. Предоставление неверных данных может привести, в частности, к невозможности завершения транзакции.",
          item40:
            "22. Вывод средств при продаже или покупке виртуальной валюты осуществляется Поставщиком услуг.",
          item41: "23. Платежи осуществляются внешними платежными операторами.",
          item42:
            "24. Поставщик услуг не несет ответственности за риск, связанный с колебаниями курса виртуальных валют, который определяет рыночную стоимость проданной или купленной виртуальной валюты.",
          item43:
            "25. Запрещается использование Устройства с модифицированной операционной системой, в частности вредоносным программным обеспечением, приводящим к некорректной работе Сайта. Если Пользователь их использует, Поставщик услуг имеет право применить меры, указанные в разделе III. 15 Правил до момента прекращения использования Пользователем такого Устройства.",
        },
        item5: {
          title: "ЖАЛОБЫ И ОТКАЗ ОТ ДОГОВОРА",
          item1:
            "1. Все жалобы, связанные с работой Сайта и предоставлением через него Услуг, а также вопросы, касающиеся использования Сайта, следует направлять Поставщику услуг по адресу электронной почты: PayStore.Pro@proton.me.",
          item2:
            "2. Жалоба должна включать: данные пользователя, включая контактные данные, причину сообщения, идентификацию транзакции, на которую подана жалоба, подробное описание и причину сообщения.",
          item3:
            "3. В течение 30 дней с момента ее получения Поставщик услуг рассматривает жалобу и информирует Пользователя о результате ее рассмотрения. Этот срок может быть продлен, если рассмотрение жалобы требует получения специальной информации или у Поставщика услуг возникают другие трудности, находящиеся вне его контроля, или если необходимо получить дополнительную информацию от Пользователя. Каждый раз предоставление Пользователем дополнительной информации продлевает срок рассмотрения жалобы.",
          item4:
            "4. Ответ на жалобу будет отправлен Поставщиком услуг на адрес электронной почты, с которого она была получена.",
          item5:
            "5. Направление жалобы Пользователем в электронной форме равносильно согласию на получение ответа от Поставщика услуг также в электронной форме.",
          item6:
            "6. Использование Пользователем внесудебных способов рассмотрения жалоб и предъявления претензий является добровольным.",
          item7:
            "7. Поставщик услуг оставляет за собой право не реагировать на заведомо необоснованную жалобу, в частности в той мере, в которой жалоба уже рассматривалась в отношении данного Пользователя.",
          item8:
            "8. Право отказа от договора исключено в соответствии со ст. 38 п. 13 Закона от 30 мая 2014 г. о правах потребителей, в связи с предоставлением Поставщиком услуг цифровых услуг, не записанных на материальном носителе, и их выполнение началось с явного согласия Пользователя до истечения срока отказа от договора и после уведомления его об утрате права на отказ от договора.",
        },
        item6: {
          title: "ОТВЕТСТВЕННОСТЬ ПОСТАВЩИКА УСЛУГ",
          item1:
            "1. Поставщик услуг осуществляет постоянный надзор за техническим функционированием Сайта, обеспечивая его правильную работу. Однако Поставщик услуг не гарантирует постоянную доступность всех функций Сайта или их безошибочную работу.",
          item2:
            "2. Пользователь использует Сайт добровольно и на свой страх и риск. Ответственность Поставщика услуг за любой ущерб, возникший в связи с использованием Веб-сайта, в частности его неработоспособностью или некорректным функционированием, исключается в максимальной степени, разрешенной законом.",
          item3:
            "3. Поставщик услуг не несет ответственности за ограничения или технические проблемы в ИТ-системах, используемых Устройствами Пользователей, которые препятствуют или ограничивают Пользователей в использовании Веб-сайта и Услуг, предлагаемых через него. Поставщик услуг не несет ответственности за качество, эффективность или точность Сайта, которые неудовлетворительны Пользователя.",
          item4:
            "4. Возможны перебои в работе Сайта по техническим причинам. В этом случае Пользователь не имеет претензий в связи с прерыванием или прекращением предоставления Услуг Поставщиком услуг.",
          item5:
            "5. Оказание Услуг может быть прервано в случае ненадлежащего качества связи, повреждения или дефектов телекоммуникационных устройств, систем электропитания, компьютерного оборудования, выхода из строя телекоммуникационной сети, отключения электроэнергии или любых действий третьих лиц.",
          item6:
            "6. Поставщик услуг имеет право заблокировать доступ к Сайту или отдельным функциям в случае каких-либо нарушений в использовании Сайта, в частности возникновения обстоятельств, которые могут нанести вред Пользователю или Поставщику услуг. В этом случае Поставщик услуг не несет ответственности за временное приостановление доступа к Веб-сайту на период, необходимый для устранения любых угроз или нарушений.",
          item7:
            "7. Расходы на возможную передачу данных, необходимых для запуска и использования Сайта, несет Пользователь самостоятельно, на основании договоров, заключенных с операторами связи или другими интернет-провайдерами. Поставщик услуг не несет ответственности за размер платы, взимаемой за использование передачи данных, необходимой для использования Сайта.",
        },
        item7: {
          title: "ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ",
          item1:
            "1. Администратором персональных данных является поставщик услуг: PAYSTORE Sp. z o.o. находится в Варшаве (01-001), Aleja Jana Pawla II 27, зарегистрированный под номером: 0001060590, NIP: 5273076950, акционерный капитал 5 000,00 злотых по смыслу ст. 8 Закона от 18 июля 2002 г. о предоставлении электронных услуг.",
          item2:
            "2. Персональные данные обрабатываются в соответствии с Регламентом (ЕС) 2016/679 Европейского парламента и Совета от 27 апреля 2016 г. о защите физических лиц при обработке персональных данных и о свободном перемещении таких данных. данные, а также отмену Директивы 95/46/EC (Общий регламент по защите данных – именуемый в дальнейшем «GDPR»), Закона от 10 мая 2018 г. о защите персональных данных и Закона от 18 июля 2002 г. о предоставлении электронные услуги.",
          item3:
            "3. Администратор обеспечивает применение соответствующих технических и организационных мер для обеспечения безопасности обрабатываемых персональных данных, в том числе предотвращения доступа к ним неуполномоченных третьих лиц, а также их обработки с нарушением положений общеприменимого законодательства, предотвращения потеря, повреждение или уничтожение персональных данных.",
          item4:
            "4. Подробные правила обработки персональных данных регламентированы Политикой конфиденциальности, которая является неотъемлемой частью настоящего Положения.",
        },
        item8: {
          title: "АВТОРСКОЕ ПРАВО И ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ",
          item1:
            "1. Все права на Сайт, включая авторские права на Сайт, а также на его отдельные части, в частности на текстовые, графические, мультимедийные элементы и элементы программирования, создающие и эксплуатирующие Сайт, включая права промышленной собственности и все другие производные права, за исключением контента, предоставленного Поставщиками или платежными операторами, имеют право Поставщик услуг.",
          item2:
            "2. При использовании Сайта и принятии Правил Пользователем Поставщик услуг предоставляет Пользователю неисключительную лицензию на использование Сайта в объеме, в котором Пользователь использует его Сервисы. Лицензия не подлежит передаче и предоставляется на срок использования Пользователем Сайта в соответствии с его целевым назначением и в соответствии с настоящими Правилами.",
          item3:
            "3. Неисключительная лицензия, предоставленная Пользователю, не дает Пользователю права предоставлять дополнительные лицензии. При этом Пользователь не имеет права действовать за пределами Лицензии.",
          item4:
            "4. Передача содержания, содержащегося на Сайте, третьим лицам разрешается только с использованием инструментов, содержащихся на Сайте и предназначенных для этой цели.",
          item5:
            "5. Пользователь не имеет права воспроизводить, продавать или иным образом продавать или распространять исходный код Сайта полностью или частично, в частности отправлять или делать его доступным в компьютерных системах и сетях, системах распространения мобильных приложений или любых других ИТ-системы.",
          item6:
            "6. В случае нарушения Пользователем правил использования Сайта или условий предоставленной Лицензии Поставщик услуг имеет право заблокировать доступ Пользователя к Сайту и отозвать предоставленную Лицензию на использование Сайта. Вышеизложенное не наносит ущерба праву Поставщика услуг предпринять другие соответствующие действия, предусмотренные законом, в связи с нарушением.",
        },
        item9: {
          title: "ПРОЦЕДУРЫ AML",
          item1:
            "1. Поставщик услуг оставляет за собой право, как обязанное учреждение по смыслу Закона от 1 марта 2018 года о противодействии отмыванию денег и финансированию терроризма или реализации внутренних процедур AML, может запросить дополнительную проверку у Пользователей Сайта.",
          item2:
            "2. Процедура проверки может, в частности, предусматривать запрос на предоставление дополнительных данных, в частности:",
          item3:
            "• персональные данные, подтвержденные документом, удостоверяющим личность;",
          item4: "• документирование источника средств;",
          item5: "• другие данные, необходимые Поставщику услуг.",
          item6:
            "3. Непредоставление запрошенных данных может повлечь за собой приостановление операции и, как следствие, отказ в ее завершении.",
          item7:
            "4. Сделки проверяются в соответствии с действующими требованиями Закона от 1 марта 2018 года о противодействии отмыванию денег и финансированию терроризма, а также внутренней процедуре AML Поставщика услуг. В частности, речь идет о сделках, превышающих 1000 евро.",
          item8:
            "5. Также могут быть проверены некоторые способы вывода платежей, в частности наличные.",
        },
        item10: {
          title: "ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ",
          item1:
            "1. Поставщик услуг имеет право изменять настоящие Правила без объяснения причин. Поставщик услуг проинформирует об изменениях на видном месте на Веб-сайте. Если Пользователь не согласен с изменением Правил, он имеет право расторгнуть договор оказания электронных услуг.",
          item2:
            "2. Поставщик услуг предоставляет доступ к Правилам через Сайт таким образом, чтобы их можно было скачать, сохранить и распечатать.",
          item3:
            "3. Положения настоящих Правил и любые споры между Поставщиком услуг и Пользователем регулируются положениями польского законодательства.",
          item4:
            "4. Отсутствие правовой основы или неполнота любого из пунктов, содержащихся в Положении, не означает, что все Положение утрачивает свою юридическую силу. Эти положения изменены на те, которые лучше всего отражают смысл и цель существующих положений",
        },
      },
    },
    risk: {
      title: "Предупреждение о рисках",
      list: {
        item1: {
          title: "ОБЩИЕ ПОЛОЖЕНИЯ И ОПРЕДЕЛЕНИЯ",
          item1:
            "1. Администратором персональных данных, собранных через сайт https://www.paystore.pro/, является PAYSTORE Sp. z o.o. находится в Варшаве (00-867), Aleja Jana Pawla II 27, зарегистрированный под номером: 0001060590, NIP: 5273076950, акционерный капитал 5 000,00 злотых по смыслу ст. 8 Закона от 18 июля 2002 г. о предоставлении электронных услуг.",
          item2:
            "2. Связаться с Администратором можно по электронной почте: PayStore.Pro@proton.me",
          item3: "3. Определения:",
          item4:
            "• Файлы cookie – означают ИТ-данные, в частности небольшие текстовые файлы, сохраняемые и хранятся на устройствах, через которые Пользователь использует Сайт;",
          item5:
            "• Персональные данные – информация об идентифицированном или идентифицируемом физическом лице. Идентифицируемое физическое лицо — это лицо, которое может быть идентифицировано прямо или косвенно, в частности, посредством ссылки на идентификатор, такой как имя, идентификационный номер, данные о местоположении, онлайн-идентификатор, или на один или несколько факторов, специфичных для физического, физиологического, генетическая, психическая, экономическая, культурная или социальная идентичность личности;",
          item6:
            "• Профилирование - означает любую форму автоматизированной обработки персональных данных, которая заключается в использовании персональных данных для оценки определенных личных факторов физического лица, в частности, для анализа или прогнозирования аспектов, касающихся последствий работы этого физического лица, его или ее экономической деятельности. ситуация, здоровье, личные предпочтения, интересы, авторитет, поведение, местоположение или передвижение;",
          item7:
            "• Обработка – означает операцию или набор операций, выполняемых с персональными данными или наборами персональных данных, независимо от того, с помощью автоматизированных средств или нет, например, сбор, запись, организация, структурирование, хранение, адаптация или изменение, загрузка, просмотр, использование, раскрытие путем отправки, распространения или иного предоставления доступа, выравнивания или объединения, ограничения, удаления или уничтожения;",
          item8: "• Веб-сайт – https://www.paystore.pro/;",
          item9:
            "• Устройство – электронное устройство, позволяющее обрабатывать, получать и отправлять данные, например ноутбук, компьютер, смартфон, планшет, мобильный телефон;",
          item10:
            "• Пользователь – означает лицо, которому услуги могут быть предоставлены в электронном виде в соответствии с положениями законодательства или с которым может быть заключен Договор на оказание электронных услуг;",
          item11:
            "• Регламент – означает документ, определяющий правила и условия использования Сайта;",
          item12:
            "• Платежный оператор – организация, которая обрабатывает платежи Пользователей Поставщику услуг с целью их обмена на виртуальные валюты;",
          item13:
            "• Поставщики – внешние организации, предоставляющие контент, доступный в",
          item14: "Веб-сайт, в частности предложения, опросы и реклама.",
        },
        item2: {
          title: "ПРАВОВАЯ ОСНОВА И ЦЕЛИ ОБРАБОТКИ ДАННЫХ ПОЛЬЗОВАТЕЛЯ",
          item1:
            "1. Персональные данные, собранные Администратором, обрабатываются в соответствии с положениями Регламента (ЕС) 2016/679 Европейского Парламента и Совета от 27 апреля 2016 года о защите физических лиц в отношении обработки персональных данных и о свободном перемещении таких данных, а также об отмене директивы 95/46/EC (далее «GDPR»), Закона от 10 мая 2018 г. о защите персональных данных (Законодательный вестник 2019 г., поз. 1781) и Закон от 18 июля 2002 г. об оказании электронных услуг (Законодательный вестник 2020 г., ст. 344).",
          item2:
            "2. Администратор обрабатывает персональные данные, предоставленные или ставшие доступными Пользователю в связи с использованием Сайта, в целях:",
          item3:
            "• заключение и исполнение договора на оказание электронных услуг и обеспечение функциональности Сайта (объем данных: IP-адреса, адрес кошелька, данные платежной карты, адрес электронной почты, номер телефона и другие необходимые данные об Устройстве, используемом Пользователь - в соответствии со статьей 6(1)(b) GDPR, т. е. в связи с тем, что обработка необходима для исполнения договора, стороной которого является субъект данных; • информирование Пользователей о вопросах, связанных с функционирование Веб-сайта (объем данных: адрес электронной почты), электронная почта, данные Устройства, используемого Пользователем) - в соответствии со статьей 6(1)(b) и (f) GDPR, т.е. в связи с тем, что что обработка необходима для исполнения договора, стороной которого является субъект данных, а также для реализации целей, вытекающих из юридически обоснованных интересов, преследуемых Администратором или третьим лицом;",
          item4:
            "• предъявление претензий и защита прав (объем данных: все данные, полученные от Пользователя, необходимые для доказательства наличия претензии или защиты прав) – согласно ст. 6 раздел 1 буква f GDPR, т.е. в связи с тем, что обработка необходима для целей законных интересов, преследуемых Администратором или третьей стороной;",
          item5:
            "• выполнение юридических обязательств, возложенных на Администратора в связи с ведением бизнеса, в частности, как обязанное учреждение по смыслу Закона о противодействии отмыванию денег и финансированию терроризма (объем данных: все данные, полученные от Пользователя) - в соответствии с Искусство. 6 раздел 1 буква c GDPR, т.е. в связи с тем, что обработка необходима для выполнения юридических обязательств Администратора;",
          item6:
            "• проведение собственной маркетинговой и рекламной деятельности (объем данных: все данные, полученные от Пользователя) – согласно ст. 6 раздел 1 буква е GDPR;",
          item7:
            "• проведение маркетинговой и рекламной деятельности на основании отдельного согласия (статья 6(1)(a) GDPR);",
          item8:
            "• направление коммерческой информации в электронном виде в соответствии со ст. 10 раздел 2 Закон об оказании услуг электронными средствами от 18 июля 2002 г. (Законодательный вестник 2017 г., ст. 1219, с изменениями), в том числе направление уведомлений (объем данных: все данные, полученные от Пользователя) - на основании отдельного предоставленное согласие (Статья 6(1)(a) GDPR);",
        },
        item3: {
          title: "ДАННЫЕ, СОБИРАЕМЫЕ АДМИНИСТРАТОРОМ САЙТА",
          item1:
            "1. Администратор Сайта собирает или может собирать следующие персональные данные через Сайт или при прямом контакте с Пользователем:",
          item2:
            "• идентификационные и контактные данные (адрес электронной почты, номер телефона);",
          item3: "• адрес кошелька виртуальной валюты;",
          item4:
            "• данные об Устройстве, используемом Пользователем (включая IP-адрес, тип устройства, марку устройства, модель устройства, имя устройства, язык устройства);",
          item5:
            "• данные об использовании Пользователем программного обеспечения VPN или Устройства с модифицированной операционной системой;",
          item6:
            "• иные данные, добровольно предоставленные Пользователем при общении с Администратором, включая данные о его Устройстве, данные переписки и иные данные, не перечисленные выше.",
          item7:
            "2. Просмотр содержимого Сайта не требует предоставления персональных данных, кроме автоматически полученной информации о параметрах подключения (IP-адресе).",
          item8:
            "3. В рамках услуг, предоставляемых на Сайте, Администратор собирает следующие персональные данные:",
          item9: "• электронный адрес;",
          item10: "• номер мобильного телефона;",
          item11: "• адрес кошелька виртуальной валюты;",
          item12: "• данные платежной карты;",
          item13: "• Айпи адрес;",
          item14:
            "• иные необходимые данные об Устройстве, используемом Пользователем.",
          item15:
            "4. Администратор оставляет за собой право в рамках использования сервисов Сайта, в том числе платежной системы, также может обрабатывать персональные данные Пользователя на основании собственной политики конфиденциальности.",
        },
        item4: {
          title: "COOKIE",
          item1:
            "1. Администратор собирает файлы cookie, связанные с активностью Пользователя на Сайте.",
          item2:
            "2. Администратор может использовать профилирование обрабатываемых данных на основании ст. 6 раздел 1 буква c GDPR в связи с с обязательствами, вытекающими из Закона от 1 марта 2018 года о противодействии отмыванию денег и финансированию терроризма.",
          item3:
            "3. При использовании Сайта на устройстве Пользователя сохраняются небольшие файлы, в частности текстовые файлы, которые используются для запоминания решений Пользователя, поддержания сеанса Пользователя, запоминания введенных данных, сбора информации об устройстве Пользователя и его посещении для обеспечения безопасности, а также анализ посещений и корректировка контента.",
          item4:
            "4. Файлы cookie не содержат данных, идентифицирующих Пользователя, а значит, на их основе невозможно определить его личность. Файлы, используемые Сайтом, никоим образом не наносят вреда Пользователю или устройству и не влияют на его программное обеспечение или настройки.",
          item5:
            "5. Файл cookie закрепляется за Пользователем после входа на главную страницу Сайта или любую из его подстраниц.",
          item6:
            "6. Система cookie не мешает работе компьютера Пользователя и может быть отключена.",
          item7:
            "7. Пользователь может настроить браузер на блокировку определенных типов файлов cookie и других технологий, указав допустимый объем сбора информации.",
          item8:
            "8. Использование сайта без изменения настроек браузера, т.е. принятие файлов cookie и аналогичных технологий по умолчанию, означает согласие на их использование для целей, указанных в настоящей Политике конфиденциальности.",
          item9:
            "9. Администратор сообщает, что если файлы необходимы для работы Сайта, ограничение их использования может помешать использованию сервисов Сайта.",
          item10:
            "10. Настройки браузера устройства Пользователя должны разрешать сохранение файлов cookie и разрешать согласие путем нажатия кнопки «ОК» в окне, которое появляется после входа на Веб-сайт с информацией: «Этот веб-сайт использует файлы cookie для обеспечения правильного предоставления услуги. Продолжая использовать сайт, вы соглашаетесь с их использованием – эти файлы будут сохранены на вашем устройстве.",
          item11:
            "11. Поставщики, включая операторов платежей, могут собирать файлы cookie на основании отдельно предоставленного согласия Пользователя.",
        },
        item5: {
          title: "ВРЕМЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ",
          item1:
            "1. Персональные данные будут обрабатываться в течение периода:",
          item2:
            "• необходимо для реализации договора на оказание электронных услуг, указанного в Регламенте, заключенного посредством Сайта, в том числе после его исполнения в связи с возможностью реализации сторонами своих прав по договору, а также в связи с возможным взыскание дебиторской задолженности – до истечения срока исковой давности по требованиям;",
          item3:
            "• до момента отзыва согласия или подачи возражения против обработки данных – в случаях обработки персональных данных Пользователя на основании отдельного согласия;",
          item4:
            "2. Администратор хранит персональные данные Пользователей также тогда, когда это необходимо для выполнения своих юридических обязательств, разрешения споров, обеспечения исполнения обязательств Пользователя, обеспечения безопасности и предотвращения мошенничества и злоупотреблений.",
        },
        item6: {
          title: "РАЗРЕШЕНИЯ ПОЛЬЗОВАТЕЛЯ",
          item1:
            "1. Администратор обеспечивает реализацию Пользователями прав, указанных в разделе 2 ниже. Чтобы воспользоваться правами, отправьте соответствующий запрос (запрос) по электронной почте на адрес: PayStore.Pro@proton.me или традиционной почтой на адрес местонахождения Администратора.",
          item2: "2. Пользователь имеет право:",
          item3:
            "• доступ к содержанию данных – в соответствии со ст. 15 GDPR;",
          item4:
            "• исправление/обновление данных – в соответствии со ст. 16 GDPR;",
          item5: "• удаление данных – в соответствии со ст. 17 GDPR;",
          item6:
            "• ограничения на обработку данных – в соответствии со ст. 18 GDPR;",
          item7: "• передача данных – в соответствии со ст. 20 GDPR;",
          item8:
            "• возражать против обработки данных – в соответствии со ст. 21 GDPR;",
          item9:
            "• отзыв согласия в любое время, однако отзыв согласия не влияет на законность обработки, осуществляемой на основании согласия до его отзыва – в соответствии со ст. 7 раздел 3",
          item10: "GDPR;",
          item11:
            "• подать жалобу в надзорный орган, т.е. председателю Управления по защите персональных данных – в соответствии со ст. 77 GDPR;",
          item12:
            "3. Администратор рассмотрит поданные запросы без неоправданной задержки, но не позднее, чем в течение одного месяца со дня их поступления. Однако, если - из-за сложного характера запроса или количества запросов - Администратор не сможет рассмотреть запрос Пользователя в указанный срок, он сообщит Пользователю о предполагаемом продлении срока и укажет крайний срок. за рассмотрение заявления, но не более 2 месяцев.",
          item13:
            "4. Администратор информирует каждого получателя, которому были раскрыты персональные данные, об исправлении или удалении персональных данных или ограничении обработки, произведенных им в соответствии с запросом Пользователя, если это не окажется невозможным или не потребует несоразмерных усилий.",
        },
        item7: {
          title: "НЕОБХОДИМОСТЬ ПРЕДОСТАВЛЕНИЯ ДАННЫХ",
          item1:
            "1. Для выполнения договора Администратор может сделать данные, полученные от Пользователей, доступными для юридических лиц, в том числе: сотрудников, сотрудников, организаций, предоставляющих юридические и ИТ-услуги Администратору и поставщикам, включая поставщиков платежей. Кроме того, Администратор предоставляет собранные персональные данные лицу, с которым заключил договор о поручении обработки персональных данных.",
          item2:
            "2. В таких случаях объем передаваемых данных ограничивается необходимым минимумом. Кроме того, информация, предоставленная Пользователями, может быть доступна компетентным государственным органам, ограничиваясь ситуациями, когда этого требует действующее законодательство.",
          item3:
            "3. Получателям, не указанным выше, обработанные персональные данные не предоставляются извне в форме, позволяющей идентифицировать Пользователей, за исключением случаев, когда Пользователь дал согласие на конкретное раскрытие данных.",
        },
        item8: {
          title: "ТЕХНИЧЕСКИЕ МЕРЫ",
          item1:
            "1. Администратор прилагает все усилия для обеспечения безопасности данных Пользователей и защиты их от действий третьих лиц, а также контролирует безопасность данных на протяжении всего периода их владения таким образом, чтобы обеспечить защиту от несанкционированного доступа, повреждения, искажения, уничтожения. или потеря данных.",
          item2:
            "2. Администратор применяет необходимые меры безопасности к серверам, соединениям и Сайту. Однако действия Администратора могут оказаться недостаточными в случае несоблюдения Пользователями правил безопасности.",
        },
        item9: {
          title:
            "ПЕРЕДАЧА ПЕРСОНАЛЬНЫХ ДАННЫХ ЗА ПРЕДЕЛЫ ЕВРОПЕЙСКОЙ ЭКОНОМИЧЕСКОЙ ЗОНЫ",
          item1:
            "1. Персональные данные пользователей не передаются в страны за пределами ЕЭЗ. Администратор использует серверы для хранения данных, расположенные в странах ЕЭЗ.",
          item2:
            "2. Администратор оставляет за собой право поставщиков, включая операторов платежей, обрабатывать и передавать персональные данные за пределы Европейской экономической зоны.",
        },
        item10: {
          title: "СУБЪЕКТЫ, ОБРАБАТЫВАЮЩИЕ ДАННЫЕ ОТ ИМЕНИ АДМИНИСТРАТОРА",
          item1:
            "1. Персональные данные Пользователей могут быть переданы на обработку от имени Администратора порталам, поддерживающим маркетинговую кампанию Администратора. Каждый обрабатывающий субъект обязан обеспечивать безопасность обработки и соблюдать принципы обработки ваших персональных данных в той же степени, что и Администратор.",
        },
        item11: {
          title: "ИЗМЕНЕНИЕ В ПОЛИТИКЕ КОНФИДЕНЦИАЛЬНОСТИ И COOKIES",
          item1:
            "1. Администратор имеет право изменить настоящий документ, о чем Пользователь будет уведомлен способом, позволяющим Пользователю ознакомиться с изменениями до их вступления в силу, например, путем размещения соответствующей информации на Сайте.",
          item2:
            "2. Если у Пользователя есть возражения против внесенных изменений, он может потребовать удаления персональных данных с Сайта. Продолжение использования Сайта после публикации или направления уведомления об изменениях настоящего документа считается согласием на сбор, использование и раскрытие персональных данных Пользователя в соответствии с обновленным содержанием документа.",
          item3:
            "3. Настоящий документ не ограничивает какие-либо права Пользователя в соответствии с общеприменимым законодательством",
        },
      },
    },
    policy: {
      title: "Политика конфиденциальности",
      list: {
        item1: {
          title: "ОБЩИЕ ПОЛОЖЕНИЯ И ОПРЕДЕЛЕНИЯ",
          item1:
            "1. Администратором персональных данных, собранных через сайт https://paystore.pro является PAYSTORE Sp. z o.o. находится в Варшаве (00-867), Aleja Jana Pawla II 27, зарегистрированный под номером: 0001060590, NIP: 5273076950, акционерный капитал 5 000,00 злотых по смыслу ст. 8 Закона от 18 июля 2002 г. о предоставлении электронных услуг.",
          item2:
            "2. Связаться с Администратором можно по электронной почте: PayStore.Pro@proton.me",
          item3: "3. Определения:",
          item4:
            "• Файлы cookie – означают ИТ-данные, в частности небольшие текстовые файлы, сохраняемые и хранятся на устройствах, через которые Пользователь использует Сайт;",
          item5:
            "• Персональные данные – информация об идентифицированном или идентифицируемом физическом лице. Идентифицируемое физическое лицо — это лицо, которое может быть идентифицировано прямо или косвенно, в частности, посредством ссылки на идентификатор, такой как имя, идентификационный номер, данные о местоположении, онлайн-идентификатор, или на один или несколько факторов, специфичных для физического, физиологического, генетическая, психическая, экономическая, культурная или социальная идентичность личности;",
          item6:
            "• Профилирование - означает любую форму автоматизированной обработки персональных данных, которая заключается в использовании персональных данных для оценки определенных личных факторов физического лица, в частности, для анализа или прогнозирования аспектов, касающихся последствий работы этого физического лица, его или ее экономической деятельности. ситуация, здоровье, личные предпочтения, интересы, авторитет, поведение, местоположение или передвижение;",
          item7:
            "• Обработка – означает операцию или набор операций, выполняемых с персональными данными или наборами персональных данных, независимо от того, с помощью автоматизированных средств или нет, например, сбор, запись, организация, структурирование, хранение, адаптация или изменение, загрузка, просмотр, использование, раскрытие путем отправки, распространения или иного предоставления доступа, выравнивания или объединения, ограничения, удаления или уничтожения;",
          item8: "• Веб-сайт – https://www.paystore.pro/;",
          item9:
            "• Устройство – электронное устройство, позволяющее обрабатывать, получать и отправлять данные, например ноутбук, компьютер, смартфон, планшет, мобильный телефон;",
          item10:
            "• Пользователь – означает лицо, которому услуги могут быть предоставлены в электронном виде в соответствии с положениями законодательства или с которым может быть заключен Договор на оказание электронных услуг;",
          item11:
            "• Регламент – означает документ, определяющий правила и условия использования Сайта;",
          item12:
            "• Платежный оператор – организация, которая обрабатывает платежи Пользователей Поставщику услуг с целью их обмена на виртуальные валюты;",
          item13:
            "• Поставщики – внешние организации, предоставляющие контент, доступный в",
          item14: "Веб-сайт, в частности предложения, опросы и реклама.",
        },
        item2: {
          title: "ПРАВОВАЯ ОСНОВА И ЦЕЛИ ОБРАБОТКИ ДАННЫХ ПОЛЬЗОВАТЕЛЯ",
          item1:
            "1. Персональные данные, собранные Администратором, обрабатываются в соответствии с положениями Регламента (ЕС) 2016/679 Европейского Парламента и Совета от 27 апреля 2016 года о защите физических лиц в отношении обработки персональных данных и о свободном перемещении таких данных, а также об отмене директивы 95/46/EC (далее «GDPR»), Закона от 10 мая 2018 г. о защите персональных данных (Законодательный вестник 2019 г., поз. 1781) и Закон от 18 июля 2002 г. об оказании электронных услуг (Законодательный вестник 2020 г., ст. 344).",
          item2:
            "2. Администратор обрабатывает персональные данные, предоставленные или ставшие доступными Пользователю в связи с использованием Сайта, в целях:",
          item3:
            "• заключение и исполнение договора на оказание электронных услуг и обеспечение функциональности Сайта (объем данных: IP-адреса, адрес кошелька, данные платежной карты, адрес электронной почты, номер телефона и другие необходимые данные об Устройстве, используемом Пользователь - в соответствии со статьей 6(1)(b) GDPR, т. е. в связи с тем, что обработка необходима для исполнения договора, стороной которого является субъект данных; • информирование Пользователей о вопросах, связанных с функционирование Веб-сайта (объем данных: адрес электронной почты), электронная почта, данные Устройства, используемого Пользователем) - в соответствии со статьей 6(1)(b) и (f) GDPR, т.е. в связи с тем, что что обработка необходима для исполнения договора, стороной которого является субъект данных, а также для реализации целей, вытекающих из юридически обоснованных интересов, преследуемых Администратором или третьим лицом;",
          item4:
            "• предъявление претензий и защита прав (объем данных: все данные, полученные от Пользователя, необходимые для доказательства наличия претензии или защиты прав) – согласно ст. 6 раздел 1 буква f GDPR, т.е. в связи с тем, что обработка необходима для целей законных интересов, преследуемых Администратором или третьей стороной;",
          item5:
            "• выполнение юридических обязательств, возложенных на Администратора в связи с ведением бизнеса, в частности, как обязанное учреждение по смыслу Закона о противодействии отмыванию денег и финансированию терроризма (объем данных: все данные, полученные от Пользователя) - в соответствии с Искусство. 6 раздел 1 буква c GDPR, т.е. в связи с тем, что обработка необходима для выполнения юридических обязательств Администратора;",
          item6:
            "• проведение собственной маркетинговой и рекламной деятельности (объем данных: все данные, полученные от Пользователя) – согласно ст. 6 раздел 1 буква е GDPR;",
          item7:
            "• проведение маркетинговой и рекламной деятельности на основании отдельного согласия (статья 6(1)(a) GDPR);",
          item8:
            "• направление коммерческой информации в электронном виде в соответствии со ст. 10 раздел 2 Закон об оказании услуг электронными средствами от 18 июля 2002 г. (Законодательный вестник 2017 г., ст. 1219, с изменениями), в том числе направление уведомлений (объем данных: все данные, полученные от Пользователя) - на основании отдельного предоставленное согласие (Статья 6(1)(a) GDPR);",
        },
        item3: {
          title: "ДАННЫЕ, СОБИРАЕМЫЕ АДМИНИСТРАТОРОМ САЙТА",
          item1:
            "1. Администратор Сайта собирает или может собирать следующие персональные данные через Сайт или при прямом контакте с Пользователем:",
          item2:
            "• идентификационные и контактные данные (адрес электронной почты, номер телефона);",
          item3: "• адрес кошелька виртуальной валюты;",
          item4:
            "• данные об Устройстве, используемом Пользователем (включая IP-адрес, тип устройства, марку устройства, модель устройства, имя устройства, язык устройства);",
          item5:
            "• данные об использовании Пользователем программного обеспечения VPN или Устройства с модифицированной операционной системой;",
          item6:
            "• иные данные, добровольно предоставленные Пользователем при общении с Администратором, включая данные о его Устройстве, данные переписки и иные данные, не перечисленные выше.",
          item7:
            "2. Просмотр содержимого Сайта не требует предоставления персональных данных, кроме автоматически полученной информации о параметрах подключения (IP-адресе).",
          item8:
            "3. В рамках услуг, предоставляемых на Сайте, Администратор собирает следующие персональные данные:",
          item9: "• электронный адрес;",
          item10: "• номер мобильного телефона;",
          item11: "• адрес кошелька виртуальной валюты;",
          item12: "• данные платежной карты;",
          item13: "• Айпи адрес;",
          item14:
            "• иные необходимые данные об Устройстве, используемом Пользователем.",
          item15:
            "4. Администратор оставляет за собой право в рамках использования сервисов Сайта, в том числе платежной системы, также может обрабатывать персональные данные Пользователя на основании собственной политики конфиденциальности.",
        },
        item4: {
          title: "COOKIE",
          item1:
            "1. Администратор собирает файлы cookie, связанные с активностью Пользователя на Сайте.",
          item2:
            "2. Администратор может использовать профилирование обрабатываемых данных на основании ст. 6 раздел 1 буква c GDPR в связи с с обязательствами, вытекающими из Закона от 1 марта 2018 года о противодействии отмыванию денег и финансированию терроризма.",
          item3:
            "3. При использовании Сайта на устройстве Пользователя сохраняются небольшие файлы, в частности текстовые файлы, которые используются для запоминания решений Пользователя, поддержания сеанса Пользователя, запоминания введенных данных, сбора информации об устройстве Пользователя и его посещении для обеспечения безопасности, а также анализ посещений и корректировка контента.",
          item4:
            "4. Файлы cookie не содержат данных, идентифицирующих Пользователя, а значит, на их основе невозможно определить его личность. Файлы, используемые Сайтом, никоим образом не наносят вреда Пользователю или устройству и не влияют на его программное обеспечение или настройки.",
          item5:
            "5. Файл cookie закрепляется за Пользователем после входа на главную страницу Сайта или любую из его подстраниц.",
          item6:
            "6. Система cookie не мешает работе компьютера Пользователя и может быть отключена.",
          item7:
            "7. Пользователь может настроить браузер на блокировку определенных типов файлов cookie и других технологий, указав допустимый объем сбора информации.",
          item8:
            "8. Использование сайта без изменения настроек браузера, т.е. принятие файлов cookie и аналогичных технологий по умолчанию, означает согласие на их использование для целей, указанных в настоящей Политике конфиденциальности.",
          item9:
            "9. Администратор сообщает, что если файлы необходимы для работы Сайта, ограничение их использования может помешать использованию сервисов Сайта.",
          item10:
            "10. Настройки браузера устройства Пользователя должны разрешать сохранение файлов cookie и разрешать согласие путем нажатия кнопки «ОК» в окне, которое появляется после входа на Веб-сайт с информацией: «Этот веб-сайт использует файлы cookie для обеспечения правильного предоставления услуги. Продолжая использовать сайт, вы соглашаетесь с их использованием – эти файлы будут сохранены на вашем устройстве.",
          item11:
            "11. Поставщики, включая операторов платежей, могут собирать файлы cookie на основании отдельно предоставленного согласия Пользователя.",
        },
        item5: {
          title: "ВРЕМЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ",
          item1:
            "1. Персональные данные будут обрабатываться в течение периода:",
          item2:
            "• необходимо для реализации договора на оказание электронных услуг, указанного в Регламенте, заключенного посредством Сайта, в том числе после его исполнения в связи с возможностью реализации сторонами своих прав по договору, а также в связи с возможным взыскание дебиторской задолженности – до истечения срока исковой давности по требованиям;",
          item3:
            "• до момента отзыва согласия или подачи возражения против обработки данных – в случаях обработки персональных данных Пользователя на основании отдельного согласия;",
          item4:
            "2. Администратор хранит персональные данные Пользователей также тогда, когда это необходимо для выполнения своих юридических обязательств, разрешения споров, обеспечения исполнения обязательств Пользователя, обеспечения безопасности и предотвращения мошенничества и злоупотреблений.",
        },
        item6: {
          title: "РАЗРЕШЕНИЯ ПОЛЬЗОВАТЕЛЯ",
          item1:
            "1. Администратор обеспечивает реализацию Пользователями прав, указанных в разделе 2 ниже. Чтобы воспользоваться правами, отправьте соответствующий запрос (запрос) по электронной почте на адрес: PayStore.Pro@proton.me или традиционной почтой на адрес местонахождения Администратора.",
          item2: "2. Пользователь имеет право:",
          item3:
            "• доступ к содержанию данных – в соответствии со ст. 15 GDPR;",
          item4:
            "• исправление/обновление данных – в соответствии со ст. 16 GDPR;",
          item5: "• удаление данных – в соответствии со ст. 17 GDPR;",
          item6:
            "• ограничения на обработку данных – в соответствии со ст. 18 GDPR;",
          item7: "• передача данных – в соответствии со ст. 20 GDPR;",
          item8:
            "• возражать против обработки данных – в соответствии со ст. 21 GDPR;",
          item9:
            "• отзыв согласия в любое время, однако отзыв согласия не влияет на законность обработки, осуществляемой на основании согласия до его отзыва – в соответствии со ст. 7 раздел 3",
          item10: "GDPR;",
          item11:
            "• подать жалобу в надзорный орган, т.е. председателю Управления по защите персональных данных – в соответствии со ст. 77 GDPR;",
          item12:
            "3. Администратор рассмотрит поданные запросы без неоправданной задержки, но не позднее, чем в течение одного месяца со дня их поступления. Однако, если - из-за сложного характера запроса или количества запросов - Администратор не сможет рассмотреть запрос Пользователя в указанный срок, он сообщит Пользователю о предполагаемом продлении срока и укажет крайний срок. за рассмотрение заявления, но не более 2 месяцев.",
          item13:
            "4. Администратор информирует каждого получателя, которому были раскрыты персональные данные, об исправлении или удалении персональных данных или ограничении обработки, произведенных им в соответствии с запросом Пользователя, если это не окажется невозможным или не потребует несоразмерных усилий.",
        },
        item7: {
          title: "НЕОБХОДИМОСТЬ ПРЕДОСТАВЛЕНИЯ ДАННЫХ",
          item1:
            "1. Для выполнения договора Администратор может сделать данные, полученные от Пользователей, доступными для юридических лиц, в том числе: сотрудников, сотрудников, организаций, предоставляющих юридические и ИТ-услуги Администратору и поставщикам, включая поставщиков платежей. Кроме того, Администратор предоставляет собранные персональные данные лицу, с которым заключил договор о поручении обработки персональных данных.",
          item2:
            "2. В таких случаях объем передаваемых данных ограничивается необходимым минимумом. Кроме того, информация, предоставленная Пользователями, может быть доступна компетентным государственным органам, ограничиваясь ситуациями, когда этого требует действующее законодательство.",
          item3:
            "3. Получателям, не указанным выше, обработанные персональные данные не предоставляются извне в форме, позволяющей идентифицировать Пользователей, за исключением случаев, когда Пользователь дал согласие на конкретное раскрытие данных.",
        },
        item8: {
          title: "ТЕХНИЧЕСКИЕ МЕРЫ",
          item1:
            "1. Администратор прилагает все усилия для обеспечения безопасности данных Пользователей и защиты их от действий третьих лиц, а также контролирует безопасность данных на протяжении всего периода их владения таким образом, чтобы обеспечить защиту от несанкционированного доступа, повреждения, искажения, уничтожения. или потеря данных.",
          item2:
            "2. Администратор применяет необходимые меры безопасности к серверам, соединениям и Сайту. Однако действия Администратора могут оказаться недостаточными в случае несоблюдения Пользователями правил безопасности.",
        },
        item9: {
          title:
            "ПЕРЕДАЧА ПЕРСОНАЛЬНЫХ ДАННЫХ ЗА ПРЕДЕЛЫ ЕВРОПЕЙСКОЙ ЭКОНОМИЧЕСКОЙ ЗОНЫ",
          item1:
            "1. Персональные данные пользователей не передаются в страны за пределами ЕЭЗ. Администратор использует серверы для хранения данных, расположенные в странах ЕЭЗ.",
          item2:
            "2. Администратор оставляет за собой право поставщиков, включая операторов платежей, обрабатывать и передавать персональные данные за пределы Европейской экономической зоны.",
        },
        item10: {
          title: "СУБЪЕКТЫ, ОБРАБАТЫВАЮЩИЕ ДАННЫЕ ОТ ИМЕНИ АДМИНИСТРАТОРА",
          item1:
            "1. Персональные данные Пользователей могут быть переданы на обработку от имени Администратора порталам, поддерживающим маркетинговую кампанию Администратора. Каждый обрабатывающий субъект обязан обеспечивать безопасность обработки и соблюдать принципы обработки ваших персональных данных в той же степени, что и Администратор.",
        },
        item11: {
          title: "ИЗМЕНЕНИЕ В ПОЛИТИКЕ КОНФИДЕНЦИАЛЬНОСТИ И COOKIES",
          item1:
            "1. Администратор имеет право изменить настоящий документ, о чем Пользователь будет уведомлен способом, позволяющим Пользователю ознакомиться с изменениями до их вступления в силу, например, путем размещения соответствующей информации на Сайте.",
          item2:
            "2. Если у Пользователя есть возражения против внесенных изменений, он может потребовать удаления персональных данных с Сайта. Продолжение использования Сайта после публикации или направления уведомления об изменениях настоящего документа считается согласием на сбор, использование и раскрытие персональных данных Пользователя в соответствии с обновленным содержанием документа.",
          item3:
            "3. Настоящий документ не ограничивает какие-либо права Пользователя в соответствии с общеприменимым законодательством",
        },
      },
    },
  },
};
