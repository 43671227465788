export const TRANSLATIONS_EN = {
  navBar: {
    list: {
      item1: "Rules",
      item2: "KYC/AML",
      item3: "Contacts",
    },
    lang: {
      "ru-RU": "Русский",
      "en-US": "English",
      "ua-UA": "Українська",
    },
    logIn: "Login",
    create: "Create an account",
  },

  footer: {
    copy: "© Copyright 2023, All Rights Reserved",
    text: "Support 09:00 - 23:00 UTC +2",
  },

  exchange: {
    banner: {
      desc1: {
        text1: "Price from",
        text2: "WhiteBit.com",
        text3: ". The rate is ",
        text4: "fixed",
        text5: " until the change does not exceed ",
        text6: "0.025%",
        text7: " Number of ",
        text8: "confirmations",
        text9: " for enrollment ",
        text10: "1-20pcs",
      },
      desc2: {
        text1: "Information about ",
        text2: "AML",
        text3: " We do not accept currencies with a",
        text4: " risk 70%",
        text5: ". Refunding crypto may take time and involve identity",
        text6: " verification.",
        text7: "",
      },
    },
    giving: {
      title: "1. Give",
      min: "Min:",
      max: "Max:",
      tabs: {
        item1: "All",
        item2: "Crypto",
        item3: "Fiat",
        item4: "Cash",
      },
    },

    get: {
      title: "2. Take",
      reserve: "Reserve:",
    },

    data: {
      title: "3. Exchange details",
      walet: "address",
      firstName: "Surname",
      lastName: "Name",
      rules: {
        text: "I agree with the",
        desc1: "rules",
        desc2: "KYC/AML policy",
      },
      btn: "Start exchange",
      corse: "Rate:",
      massage: "You must be agree with rules.",
    },
  },

  order: {
    title: "Application",
    pending: "Waiting",
    table: {
      item1: "Send",
      item2: "Get",
      item3: "Surname",
      item4: "Name",
    },
    side: {
      title: "To complete the order, you need to transfer funds:",
      get: "Transfer:",
      address: "To address:",
    },
  },

  rules: {
    title: "Rules",
    list: {
      item1: "Warranty period.",
      item2: "Enrollment.",
      item3: "Fixation.",
      item4: "AML/KYC POLICY",
      item5: "Refund Policy",
      item6: "Agreement",
      item7: "Risk Policy",
      item8: "Privacy policy",
    },
    regulations: {
      title: "Warranty period.",
      item1: {
        title: "Regulations of exchange operations",
        list: {
          item1:
            "Requests that do not involve cash in the exchange – are processed automatically – around the clock, and takes from 2 to 10 minutes, in some cases, delays up to 60 minutes are possible.",
          item2:
            "For exchanges that involve cash – applications are processed manually and coordinated with our support service, during its working hours, see the schedule of support service.",
          item3:
            "Support service is available on weekdays from 9:00 to 23:00. Weekends are on duty.",
          item4:
            "Exchange rates are updated every minute and are available on the main page of the service paystore.pro",
        },
      },
      item2: {
        title:
          "(Cashless) Selling Cryptocurrency. Terms of crediting to the requisites after the exchange.",
        list: {
          item1:
            " In case client sends Cryptocurrency – transaction rate and amount to be paid out are calculated after confirmation of the transaction in the network and then initiated by the systеm automatically to the payment details specified by the client. Payments are made to Visa/MasterCard in currencies UAH / EUR / USD / Tenge. There is an exception on countries (sanctions)",
          item2:
            "The period of crediting depends on the issuing bank of the recipient’s card (from a few seconds up to 5 banking days). If the amount to be paid exceeds the limit of one transaction – 100,000.00 UAH, the systеm will send multiple payments, multiple of the established limit.",
        },
      },
      item3: {
        title:
          "(Cashless) Purchase of Cryptocurrency. The terms of sending and processing payments.",
        list: {
          item1:
            "Payments from Visa/MasterCard are processed on the side of payment systеm in real time. After receiving notification about successful payment, the systеm instantly initiates sending Cryptocurrency to the address specified by the client in automatic mode, with priority commission.",
          item2:
            "The timing of cryptocurrency crediting to the client’s address depends on the load in the Blockchain / USDT network. As a rule, the systеm receives confirmation after payment instantly, but in some cases, there can be delays on the side of payment systеm or bank. Also note that the amount to be paid will be revised in relation to the actual rate at the time of payment, which is fixed after the receipt of funds through the payment form on the side of the payment systеm.",
        },
      },
      item4: {
        title: "(Cash) Selling Cryptocurrency. Timeframe for receiving cash.",
        list: {
          item1:
            " In case the client sends the Cryptocurrency – the rate of the transaction and the amount to be paid are calculated after the confirmation of the transaction in the network. The exchange occurs in the following way and can’t be disturbed.",
          item2:
            "Check the availability of the amount of interest in the city through support > Create a request > Pay > Send the hash of the transaction > Support gives the “indentification code” + address of the cash register > Go to the cash register > Call the “indentification code” > Pick up the cash.",
          item3:
            "In some countries it is possible to fill in the ticket in connection with the policy of this country, check with technical support.",
          item4:
            "Payment on the spot is not possible, it is not an office, it is a cashier of the exchange office, at the cashier sits, gives / takes money according to the previously received codes.",
        },
      },
      item5: {
        title:
          "(Cash) Buying Cryptocurrency. Timeframe for sending and cashless funds.",
        list: {
          item1:
            " In case of receipt by the client of Cryptocurrency – the rate of operation and the amount payable are calculated after confirmation of receipt by the cashier. The exchange is carried out as follows and cannot be broken.",
          item2:
            "Create an application > Support gives you an “identification code” + address of the cashier > Go to the cashier > Call “identification code” > Bring cash > Within 5 – 30 minutes, the payment is made to a purse specified by you in the application > In some cases (rarely), delays in payment of up to 60 minutes (you can wait for the receipt of funds near the cashier)",
          item3:
            "In some countries it is possible to fill in the account in connection with the policy of this country, check with technical support.",
          item4:
            "It is impossible to come to the cash desk without “indentification code”, this is not an office, this is the cashier of the exchange office, at the cashier sits, gives out / accepts money according to the previously received codes.",
        },
      },
      item6: {
        title: "Payment sending error.",
        list: {
          item1:
            "When sending a payment – the systеm can receive a refusal, about which the client will be notified on the page of the exchange status. Possible reasons: malfunction in the payment systеm – one of the reasons, possible temporary technical works; the client specified wrong details – nonexistent/correct/blocked account number; refusal of crediting from the issuing bank – indicated currency card or exceeded limit. Requests with this status are processed by an operator within the working hours. All questions about the transfer time can be asked by contacting our support service. If it is impossible to send funds to the client or transfer them in another possible way through no fault of the service, the amount of assets will be returned to the client minus the commission for sending.",
        },
      },
      item7: {
        title: "Brief refund policy.",
        list: {
          item1:
            "Cryptocurrency refund is initiated by the operator within 24 hours after the client’s request. Fiat refund is initiated after request to the Payment systеm or Bank-Acquirer and can take up to 2 bank working weeks. Assets will be sent to the same details from which the client has performed the transfer of funds in favor of the service. In case the customer replaces the return details, the service is entitled to request any information to identify the customer as the true owner of the assets.",
        },
      },
      item8: {
        title: "Buying Cryptocurrency. Payment from stolen cards.",
        list: {
          item1:
            "In case of suspected use of stolen card data, the service reserves the right to suspend the payment of funds – until the situation is clarified.",
        },
      },
      item9: {
        title: "User’s identity verification.",
        list: {
          item1:
            "In some cases, when processing chargebacks or withdrawals to other details, the service can request documents from the user – to confirm the identity and conduct a Skype – Call, to fully identify the user and his credentials.",
        },
      },
      item10: {
        title: "Regulations for solving problematic situations.",
        list: {
          item1:
            "The solution of all problem situations occurs through our support service. To solve your problem, be sure to specify the application number, describe the problem in detail, if necessary, specify the new details. Time frames for treatment of the request – during the working hours of the support service. Outside working hours, consultations will be provided as quickly as possible.",
        },
      },
      item11: {
        title: "Force-majeure circumstances.",
        list: {
          item1:
            "There is a possibility of deviations from the rules of carrying out operations in cases that are not the fault of the Service. In the majority of operations the third party is involved (banks, payment systems) and in case of problems on the intermediary’s side (website, API non-functionality, technical works, loss of transfer inside the payment systеm and etc), the deadlines according to regulations are added by time to fix problems on the intermediary’s service side.",
        },
      },
    },
    enrollment: {
      title: "Enrollment",
      item1: {
        title: "Number of confirmations required for fixing and enrollment:",
        list: {
          item1: "1. USDT TRC — 20 — transaction confirmations in the network.",
          item2: "2. USDT ERC — 32 —  transaction confirmations in the network",
          item3: "3. BTC — 2 —  transaction confirmations in the network",
          item4: "4. ETH — 32 —  transaction confirmations in the network",
          item5: "5. XRP - 1 - transaction confirmations in the network",
          item6: "6. DOGE - 15 - transaction confirmations in the network",
          item7: "7. SOL - 500 - transaction confirmations in the network",
          item8: "8. ADA - 40 - transaction confirmations in the network",
          item9: "9. TRX - 20 - transaction confirmations in the network.",
          item10: "10. DOT - 50 - transaction confirmations in the network",
          item11: "11. LTC - 10 - transaction confirmations in the network",
          item12: "12. BCH - 10 transaction confirmations in the network",
          item13: "13. LINK - 32 - transaction confirmations in the network",
          item14: "14. XLM - 1 - transaction confirmations in the network",
          item15: "15. XMR - 10 - transaction confirmations in the network",
          item16: "16. ATOM - 70 - transaction confirmations in the network",
          item17: "17. SHIB - 32 - transaction confirmations in the network",
          item18: "18. ZEC - 100 - transaction confirmations in the network",
          item19: "19. EOS - 1 - transaction confirmations in the network",
          item20: "20. NEAR - 3- transaction confirmations in the network",
          item21: "21. ETC - 300 - transaction confirmations in the network",
          item22: "22. DASH - 10 - transaction confirmations in the network",
          item23: "23. AVAX - 1 - transaction confirmations in the network",
        },
      },
    },
    fixing: {
      title: "Fixation.",
      item1: {
        list: {
          item1: "The course in automatic directions is fixed.",
          item2: "But for changes greater than 0.025% will be recalculated.",
          item3:
            "Crediting and recalculation takes place according to the WhiteBit.com exchange glass",
          item4:
            "The number of online confirmations required for enrollment watch.",
        },
      },
    },
    aml: {
      title: "AML/KYC POLICY",
      desc: 'The Anti-Money Laundering, Countering Financing of Terrorism and Know Your Customer Policy (hereinafter - the "AML/CFT Policy") of ] PayStore is designated to prevent and mitigate possible risks of PayStore being involved in any kind of illegal activity.',
      item1: {
        title: "Money laundering is defined as",
        desc: "Legalization of proceeds of crime means",
        list: {
          item1:
            "the conversion or transfer of property derived from criminal activity or property obtained instead of such property, knowing that such property is derived from criminal activity or from an act of participation in such activity, for the purpose of concealing or disguising the illicit origin of the property or of assisting any person who is involved in the commission of such an activity to evade the legal consequences of that person’s actions;",
          item2:
            "the acquisition, possession or use of property derived from criminal activity or property obtained instead of such property, knowing, at the time of receipt, that such property was derived from criminal activity or from an act of participation therein;",
          item3:
            "the concealment or disguise of the true nature, source, location, disposition, movement, rights with respect to, or ownership of, property derived from criminal activity or property obtained instead of such property, knowing that such property is derived from criminal activity or from an act of participation in such an activity.",
        },
        desc1:
          "Money laundering also means participation in, association to commit, attempts to commit and aiding, abetting, facilitating and counselling the commission of any of the activities referred to above.",
        desc2:
          "Terrorist financing is defined as the financing and supporting of an act of terrorism and commissioning thereof as well as the financing and supporting of travel for the purpose of terrorism",
        desc3:
          "Both international and local laws and regulations require PayStore to implement effective internal procedures and mechanisms to prevent money laundering, terrorist financing, drug and human trafficking, proliferation of weapons of mass destruction, corruption and bribery and to take action in case of any form of suspicious activity from its Users.",
        desc4: "",
      },
      item2: {
        title: "AML/CFT Policy covers the following matters",
        list: {
          item1: "internal controls",
          item2: "compliance officer;",
          item3: "training of personal;",
          item4: "verification procedures;",
          item5: "monitoring, risk assessment and risk-based approach;",
          item6: "AML/CFT program audit.",
        },
      },
      item3: {
        title: "Internal Controls",
        desc: 'We have designed a structured system of internal controls in order to comply with applicable Anti-Money Laundering, Countering Financing of Terrorism (hereinafter - the "AML/CFT") laws and regulations, including, but not limited to:',
        list: {
          item1:
            "establishing customer's identity and verifying the information provided;",
          item2:
            "establishing special regime for dealing with customers which are politically exposed persons (PEP);",
          item3:
            "the identification of unusual activity and facilitating the reporting of suspicious activity (SAR);",
          item4:
            "record keeping of customer documentation and transactional history.",
        },
      },
    },
    returns: {
      title: "Refund Policy",
      list: {
        item1: {
          title: "GENERAL PROVISIONS AND DEFINITIONS",
          item1:
            "1. the Administrator of the personal data collected through the website https://www.paystore.pro/ , is PAYSTORE Sp. z o.o. located in Warsaw (00-867), Aleja Jana Pawla II 27, registered under the number: 0001060590, NIP: 5273076950, share capital PLN 5,000.00 within the meaning of Article 8 of the Act of July 18, 2002 on the provision of electronic services.",
          item2:
            "2. Contact the Administrator by e-mail: PayStore.Pro@proton.m",
          item3: "3. Definitions:",
          item4:
            "• Cookies - means IT data, in particular small text files, saved and stored on the devices through which the User uses the Site;",
          item5:
            "• Personal Data means information about an identified or identifiable natural person. An identifiable natural person is a person who can be identified directly or indirectly, in particular by reference to an identifier such as a name, identification number, location data, online identifier, or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of the individual;",
          item6:
            "• Profiling - means any form of automated processing of personal data that consists in using personal data to assess certain personal factors of a natural person, in particular to analyze or predict aspects relating to the consequences of that natural person's work, his or her economic activity. situation, health, personal preferences, interests, authority, behavior, location or movement;",
          item7:
            "• Processing - means an operation or set of operations performed on personal data or sets of personal data, whether or not by automated means, such as collecting, recording, organizing, structuring, storing, adapting or modifying, downloading, viewing, using, disclosing by sending, disseminating or otherwise making available, aligning or combining, restricting, deleting or destroying;",
          item8: "• The Web site is https://www.paystore.pro/",
          item9:
            "• Device - an electronic device that allows data to be processed, received and sent, e.g. laptop, computer, smartphone, tablet, cell phone;",
          item10:
            "• User - means a person to whom services may be provided electronically in accordance with the provisions of the law or with whom an Electronic Services Agreement may be concluded;",
          item11:
            "• Regulations means the document that defines the rules and terms of use of the Site;",
          item12:
            "• Payment Operator - an organization that processes Users' payments to the Service Provider for the purpose of their exchange into virtual currencies;",
          item13:
            "• Suppliers are external organizations that provide content available in the",
          item14:
            "Website, particularly suggestions, surveys and advertisements.",
        },
        item2: {
          title: "LEGAL BASIS AND PURPOSES OF PROCESSING USER DATA",
          item1:
            '1. The personal data collected by the Administrator is processed in accordance with the provisions of Regulation (EU) 2016/679 of the European Parliament and of the Council of April 27, 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data and on the repeal of Directive 95/46/EC (hereinafter "GDPR"), the Act of May 10, 2018 on the protection of personal data (Journal of Laws 2019, item. 1781) and the Act of July 18, 2002 on the provision of electronic services (Journal of Laws 2020, item. 344).',
          item2:
            "2. the Administrator processes personal data provided or made available to the User in connection with the use of the Site for the purposes of:",
          item3:
            "• conclusion and execution of a contract for the provision of electronic services and the functionality of the Website (scope of data: IP addresses, wallet address, payment card details, e-mail address, telephone number and other necessary data about the Device used by the User - in accordance with Article 6(1)(b) GDPR, i.e. because the processing is necessary for the performance of a contract to which the data subject is a party; - informing Users about issues related to the functionality of the Website (scope of data: e-mail address, e-mail address, payment card details, telephone number and other necessary data about the Device used by the User - in accordance with Article 6(1)(b) GDPR, i.e. because the processing is necessary for the performance of a contract to which the data subject is a party; - informing Users about issues related to the functionality of the Website (scope of data: e-mail address, electronic",
          item4:
            "• assertion of claims and defense of rights (scope of data: all data obtained from the User necessary to prove the existence of a claim or defense of rights) - pursuant to Art. 6 Section 1 letter f GDPR, i.e. because the processing is necessary for the purposes of legitimate interests pursued by the Administrator or a third party;",
          item5:
            "• fulfillment of the legal obligations imposed on the Administrator in connection with the conduct of business, in particular as an obliged institution within the meaning of the Anti-Money Laundering and Terrorist Financing Act (scope of data: all data collected from the User) - in accordance with Art. 6 section 1 letter c GDPR, i.e. because the processing is necessary to fulfill the legal obligations of the Administrator;",
          item6:
            "• conducting its own marketing and advertising activities (scope of data: all data received from the User) - in accordance with Article 6 Section 1 Letter e of the GDPR;",
          item7:
            "• conducting marketing and promotional activities on the basis of a separate consent (Article 6(1)(a) GDPR);",
          item8:
            "• sending commercial information electronically in accordance with Article 10 Section 2 of the Act on the provision of services by electronic means of July 18, 2002 (Journal of Laws 2017, Art. 1219, as amended), including the sending of notifications (scope of data: all data received from the User) - based on the separate consent granted (Article 6(1)(a) GDPR);",
        },
        item3: {
          title: "DATA COLLECTED BY THE SITE ADMINISTRATOR",
          item1:
            "1. the Site Administrator collects or may collect the following personal data through the Site or by direct contact with the User:",
          item2:
            "• Identification and contact details (e-mail address, telephone number);",
          item3: "• virtual currency wallet address;",
          item4:
            "• data about the Device used by the User (including IP address, device type, device brand, device model, device name, device language);",
          item5:
            "• data on the User's use of the VPN software or the Device with a modified operating system;",
          item6:
            "• other data voluntarily provided by the User when communicating with the Administrator, including data about their Device, correspondence data and other data not listed above.",
          item7:
            "2. Viewing the content of the Site does not require the provision of personal data, except for automatically obtained information about the connection parameters (IP address).",
          item8:
            "3. As part of the services provided on the Website, the Administrator collects the following personal data:",
          item9: "• e-mail address;",
          item10: "• cell phone number;",
          item11: "• virtual currency wallet address;",
          item12: "• payment card details;",
          item13: "• IP address;",
          item14: "• other necessary data about the Device used by the User.",
          item15:
            "4. The Administrator reserves the right within the framework of using the services of the Site, including the payment system, may also process personal data of the User on the basis of its own privacy policy.",
        },
        item4: {
          title: "COOKIE",
          item1:
            "1. the Administrator collects cookies related to the User's activity on the Site.",
          item2:
            "2. the Administrator may use profiling of the processed data on the basis of Article 6 section 1 letter c GDPR in connection with the obligations arising from the Act of March 1, 2018 on combating money laundering and terrorist financing.",
          item3:
            "3. When using the Site, small files, in particular text files, are stored on the User's device, which are used to remember the User's decisions, maintain the User's session, remember the entered data, collect information about the User's device and its visit to ensure security, as well as analyze visits and adjust content.",
          item4:
            "4. Cookies do not contain data identifying the User, which means that it is impossible to determine the User's identity based on them. The files used by the Site do not in any way harm the User or the device and do not affect its software or settings.",
          item5:
            "5. A cookie is assigned to the User after entering the main page of the Site or any of its subpages.",
          item6:
            "6. The cookie system does not interfere with the User's computer and can be disabled.",
          item7:
            "7. The user can set the browser to block certain types of cookies and other technologies by specifying the amount of information collection allowed.",
          item8:
            "8. Using the site without changing your browser settings, i.e. accepting cookies and similar technologies by default, constitutes consent to their use for the purposes set out in this Privacy Policy.",
          item9:
            "9. The Administrator informs that if the files are necessary for the Site operation, restriction of their use may prevent the use of the Site services.",
          item10:
            '10. The browser settings of the User\'s device must allow the storage of cookies and authorize consent by clicking "OK" in the window that appears after entering the Website with the information: This website uses cookies to ensure the correct provision of the service. By continuing to use the Website, you consent to their use - these files will be stored on your device.',
          item11:
            "11. Providers, including payment processors, may collect cookies based on the User's separately provided consent.",
        },
        item5: {
          title: "TIME OF PERSONAL DATA PROCESSING",
          item1: "1. Personal data will be processed during the period:",
          item2:
            "• is necessary for implementation of the contract for provision of electronic services specified in the Regulations, concluded through the Website, including after its execution due to the possibility of the parties to exercise their rights under the contract, as well as due to the possible collection of receivables - before the expiration of the limitation period for claims;",
          item3:
            "• until the moment of withdrawal of consent or filing an objection to data processing - in cases of processing the User's personal data on the basis of a separate consent;",
          item4:
            "2 The Administrator stores personal data of Users also when it is necessary to fulfill its legal obligations, resolve disputes, enforce User's obligations, ensure security and prevent fraud and abuse.",
        },
        item6: {
          title: "USER AUTHORIZATIONS",
          item1:
            "1. the Administrator ensures that Users exercise the rights specified in section 2 below. To exercise the rights, send the corresponding request (inquiry) by e-mail to the address: PayStore.Pro@proton.me or by traditional mail to the address of the Administrator's location.",
          item2: "2. The user has the right to:",
          item3:
            "• access to data content - in accordance with Article 15 GDPR;",
          item4:
            "• data correction/updating - in accordance with Article 16 GDPR;",
          item5: "",
          item6: "• data deletion - in accordance with Article 17 GDPR;",
          item7:
            "• restrictions on data processing - in accordance with Article 18 GDPR;",
          item8: "• data transfer - in accordance with Article 20 GDPR;",
          item9:
            "• object to data processing - in accordance with Article 21 GDPR;",
          item10:
            "• withdrawal of consent at any time, but withdrawal of consent does not affect the lawfulness of processing carried out on the basis of consent prior to its withdrawal - in accordance with Art. 7 Section 3",
          item11: "GDPR;",
          item12:
            "• lodge a complaint with the supervisory authority, i.e. the Chairman of the Personal Data Protection Authority - in accordance with Article 77 GDPR;",
          item13:
            "3. The Administrator will consider submitted requests without undue delay, but no later than within one month from the date of receipt. However, if - due to the complex nature of the request or the number of requests - the Administrator will not be able to consider the User's request within the specified period of time, he will inform the User of the expected extension of the deadline and specify the deadline. for consideration of the application, but not more than 2 months.",
          item14:
            "4. The Administrator shall inform each recipient to whom personal data have been disclosed of the rectification or deletion of personal data or restriction of processing carried out by it in accordance with the User's request, unless this proves impossible or requires disproportionate effort.",
        },
        item7: {
          title: "NEED FOR DATA PROVISION",
          item1:
            "1. To fulfill the contract, the Administrator may make the data collected from Users available to legal entities, including: employees, collaborators, organizations providing legal and IT services to the Administrator and suppliers, including payment providers. In addition, the Administrator makes the collected personal data available to the person with whom it has concluded a contract on entrusting the processing of personal data.",
          item2:
            "2. In such cases, the amount of data transmitted is limited to the necessary minimum. In addition, the information provided by Users may be made available to competent public authorities, limited to situations where this is required by applicable law.",
          item3:
            "3. To recipients other than those specified above, processed personal data are not provided externally in a form that allows identification of Users, unless the User has consented to the specific disclosure of the data.",
        },
        item8: {
          title: "TECHNICAL MEASURES",
          item1:
            "1. the Administrator shall make every effort to ensure the security of Users' data and protect them from the actions of third parties, and shall control the security of data throughout their possession in such a way as to ensure protection against unauthorized access, damage, distortion, destruction. or loss of data.",
          item2:
            "2. the Administrator applies the necessary security measures to servers, connections and the Site. However, the Administrator's actions may be insufficient if Users fail to comply with the security rules.",
        },
        item9: {
          title: "TRANSFER OF PERSONAL DATA OUTSIDE THE EUROPEAN ECONOMIC AREA",
          item1:
            "1. Personal data of users are not transferred to countries outside the EEA. The Administrator uses servers for data storage located in EEA countries.",
          item2:
            "2 The Administrator reserves the right of suppliers, including payment operators, to process and transfer personal data outside the European Economic Area.",
        },
        item10: {
          title: "SUBJECTS PROCESSING DATA ON BEHALF OF THE ADMINISTRATOR",
          item1:
            "1. Users' personal data may be transferred for processing on behalf of the Administrator to portals supporting the Administrator's marketing campaign. Each processing entity is obliged to ensure the security of processing and comply with the principles of processing your personal data to the same extent as the Administrator.",
        },
        item11: {
          title: "CHANGE IN PRIVACY POLICY AND COOKIES",
          item1:
            "1. the Administrator has the right to change this document, about which the User will be notified in a way that allows the User to familiarize with the changes before they take effect, for example, by posting the relevant information on the Site.",
          item2:
            "2. If the User has objections to the changes made, he/she may request the deletion of personal data from the Site. Continued use of the Site after publication or sending of the notice of changes hereof shall be deemed consent to collection, use and disclosure of the User's personal data in accordance with the updated content of the document.",
          item3:
            "3 This document does not limit any of the User's rights under generally applicable law",
        },
      },
    },
    agreement: {
      title: "Agreement",
      list: {
        item1: {
          title: "GENERAL PROVISIONS",
          item1:
            "1. These Regulations define the rules for the use of the online currency exchange office available at: https://paystore.pro as well as for the provision of services via electronic means by the Service Provider: PAYSTORE Sp. z o.o. based in Warsaw (00-867), Aleja Jana Pawla II 27, registered under the number: 0001060590, NIP: 5273076950, share capital 5,000.00 PLN, within the meaning of Art. 8 of the Act of 18 July 2002 on the provision of electronic services.",
          item2:
            "2. The activity of the Service Provider is subject to mandatory entry in the register of activities in the field of virtual currencies, referred to in Art. 129m of the Act of March 1, 2018 on counteracting money laundering and terrorism financing. The Service Provider has a current entry in the register under the number RDWW-966 of October 5, 2023. The register is kept by the Director of the Tax Administration Chamber in Katowice, the current status of which is available on the official website.",
          item3:
            "3. The service provider is an obligated institution within the meaning of Art. 2 section 1 point 12 of the Act of March 1, 2018 on counteracting money laundering and terrorist financing, therefore it applies internal procedures aimed at counteracting money laundering and terrorist financing.",
          item4:
            "4. Each User is obliged, from the moment of taking steps to use the paystore.pro Website, to comply with the provisions of these Regulations. The content of the Regulations is available to Users on the Website and is made available during the registration procedure.",
          item5:
            "5. The User is obliged to read the content of these Regulations. Acceptance of the Regulations is voluntary, but it is a condition for using the paystore.pro Website.",
          item6:
            "6. The Regulations are available in electronic form on the Service Provider's website in such a way that Users can store and retrieve them in the course of ordinary activities.",
        },
        item2: {
          title: "DEFINITIONS",
          item1:
            "1. These Regulations define the rules for the use of the online currency exchange office available at: paystore.pro, as well as for the provision of services via electronic means by the Service Provider: PAYSTORE Sp. z o.o. based in Warsaw (01-001), Aleja Jana Pawla II 27, registered under the number: 0001060590, NIP: 5273076950, share capital 5,000.00 PLN, within the meaning of Art. 8 of the Act of 18 July 2002 on the provision of electronic services.",
          item2:
            "2. Regulations - this document specifying the rules and conditions of using the Website.",
          item3:
            "3. Website - online virtual currency exchange office available at  paystore.pro",
          item4:
            "4. Services - services provided electronically by the Service Provider via the Website, described in these Regulations.",
          item5:
            "5. Device - an electronic device that allows processing, receiving and sending data, such as a laptop, computer, smartphone, tablet, mobile phone.",
          item6:
            "6. User - means an entity to which services may be provided electronically in accordance with the provisions of law or with which an agreement for the provision of services electronically may be concluded.",
          item7:
            "7. KYC - User authentication activities undertaken in order to use the Website's functions, in particular providing the name, surname, citizenship, date of birth and sending appropriate photos of the identity card along with the User's image.",
          item8:
            "8. Authorization - User's authentication activities undertaken during each transaction, consisting in verifying the User by sending an SMS and messages to the indicated e-mail address.",
          item9:
            "9. User Account - a set of resources and authorizations assigned to the User within the Website, containing information necessary for its authorization and enabling the use of the service.",
          item10:
            "10. Means of payment - fiat or digital money issued by the National Bank of Poland, foreign central banks or other public administration bodies that are legal tender.",
          item11:
            "11. Virtual currencies - currencies within the meaning of Art. 2 section 2 point 26 of the Act of March 1, 2018 on counteracting money laundering and terrorism financing.",
          item12:
            "12. Transaction - currency exchange ordered by the User, consisting in the purchase, sale or exchange of virtual currencies or means of payment.",
          item13:
            "13. Wallet address - a string of characters of an electronically maintained set of identification data ensuring the possibility of storing and managing virtual currencies.",
          item14:
            "14. Services - electronic (digital) services within the meaning of the Act of July 18, 2002 on the provision of electronic services, provided via the Website, in particular consisting in the provision of currency exchange services.",
          item15:
            "15. Payment operator - an entity that processes Users' payments to the Service Provider in order to exchange them for virtual currencies.",
          item16:
            "16. Suppliers - external entities that provide content available on the Website, in particular offers, surveys and advertisements.",
        },
        item3: {
          title: "ACCESS AND USE OF THE WEBSITE",
          item1:
            "1. The basic Service provided via the Website is the possibility of exchanging between virtual currencies and means of payment under the conditions specified in §4 of these Regulations. The Service Provider reserves the right to also introduce other Services on the Website.",
          item2:
            "2. The Service Provider provides Services under the terms set out in these Regulations.",
          item3:
            "3. The website is available to all Device Users connected to the Internet via the website  paystore.pro. To use the Website, the User must correctly enter the website address and run it on his Device.",
          item4:
            "4. Services using the Website are provided 24 hours a day, 7 days a week, at the User's individual request, consisting in launching the Website and using the Services.",
          item5:
            "5. The use of the Services may be territorially limited. The Service Provider offers access to the Services in accordance with local law. The Service Provider does not allow Users from the jurisdiction of: Cuba, Iran, Israel, Iraq, South Korea, Sudan, South Sudan, Syria, Pakistan, Nigeria, India, Indonesia, Benin to use the Services.",
          item6:
            "6. The Service Provider reserves the right to select, limit or refuse to provide services in a specific jurisdiction at any time.",
          item7:
            "7. The website and all materials, information and solutions contained therein, as well as the selection and arrangement of the content, logos, graphic elements and trademarks presented on the website are the subject of the exclusive rights of the Service Provider or its business partners.",
          item8:
            "8. To launch and properly operate the Website, it is necessary to connect the device to the Internet by activating mobile networks or Wi-Fi.",
          item9:
            "9. Upon acceptance of the Regulations, an agreement for the provision of electronic services is concluded between the Service Provider and the User. The contract is concluded for an indefinite period.",
          item10:
            "10. Acceptance of the Regulations by the User is required before using the Services and ordering a Transaction. The user declares that he accepts the regulations by checking the appropriate box. occurs during The moment of acceptance of the Regulations by the User is considered to be the moment of unchecking the User's declaration before using the Services.",
        },
        item4: {
          title: "TERMS OF USE",
          item1:
            "1. The default function of the Website is to provide exchange services between virtual currencies and means of payment.",
          item2:
            "2. As part of the currency exchange service, the user can choose a transaction involving the purchase or sale of virtual currencies, involving an exchange between virtual currencies or an exchange between means of payment and virtual currencies.",
          item3:
            "3. In order to use a specific type of transaction, the User is obliged to select a specific type of transaction and the deposit and withdrawal method.",
          item4:
            "4. The range of available deposit and withdrawal methods may change depending on current availability. The website generally provides various forms of deposits and withdrawals, including:",
          item5: "• cash money;",
          item6: "• transfer of virtual currency;",
          item7: "• payment by Visa or MasterCard.",
          item8:
            "5. In order to complete the transaction, the User is obliged to:",
          item9: "• approval of the form and amount of payment;",
          item10: "• approval of the form of payment;",
          item11:
            "• indication of the address of the virtual currency wallet to which the funds will be transferred;",
          item12: "• completing personal data necessary for authorization;",
          item13:
            "• completing a captcha test or other similar security measure;",
          item14: "• confirming the provided data using the Exchange button;",
          item15:
            "6. If you choose to purchase virtual currencies using a Visa or MasterCard card, the User is additionally obliged to provide the card details necessary to process the transaction, including the card number, expiry date and CVV or CVC code.",
          item16:
            "7. After completing the data correctly, the User is obliged to enter the authorization code sent to the given data in accordance with the selected authorization method.",
          item17:
            "8. In order to maintain the exchange rate, the User is obliged to finalize the transaction within:",
          item18:
            "• 5 minutes for deposits and withdrawals using cash, counted from the moment of data confirmation;",
          item19:
            "• 10 minutes for deposits and withdrawals using Visa or MasterCard.",
          item20:
            "9. Exceeding the time means the transaction expires and the exchange rate changes, for which the Service Provider is not responsible.",
          item21:
            "10. The Service Provider reserves the right to change the forms of deposits and withdrawals. Some forms, including cash withdrawals, may depend on the User meeting additional requirements referred to in section 18 et seq.",
          item22:
            "11. Using the services available on the Website may involve fees. The service provider charges fees for the currency exchange services provided in the form of:",
          item23:
            "• 1-10% commission, which is included in the currently displayed virtual currency exchange rate;",
          item24:
            "• transaction costs, included in the displayed amount of virtual currencies that the User buys or sells.",
          item25:
            "12. The Service Provider reserves that the transaction costs of buying and selling virtual currencies are not fixed. Their amount depends on the current state of the network responsible for the operation of a given virtual currency.",
          item26:
            "13. As part of each transaction, the User is informed about the total amount of the transaction, i.e. including the fees referred to in section 11.",
          item27:
            "14. The User is entitled to register an account on the Website. In order to do so, it is necessary to perform authentication activities consisting of setting a login, password and providing an e-mail address.",
          item28:
            "15. A User with a registered account on the Website is entitled to receive an affiliate link and additional benefits, including reducing the amount of fees referred to in section 11 and receiving additional commission from transactions made by Users using the affiliate link.",
          item29:
            "16. Each transaction on the Website requires authorization, which consists in confirming the transaction by entering the authorization code or clicking on the appropriate link received via SMS or to the indicated e-mail address.",
          item30:
            '17. The Service Provider determines the current rates of virtual currencies used to conduct transactions on a continuous basis based on current rates from the XE.COM INC. ("XE"), WhiteBIT Tech Sp. Z.o.o, Binance Poland Spółka z Ograniczoną Odpowiedzialnością',
          item31:
            "18. The Service Provider, in accordance with internal procedures for counteracting money laundering and terrorist financing, may request additional information from the User. The KYC procedure is performed each time for transactions exceeding EUR 1,000. As part of KYC, the User is obliged to provide data including:",
          item32:
            "• a scan or photo of the User's valid identity document (e.g. passport, ID card);",
          item33: "• a photo covering the User's face in the format;",
          item34:
            "• a photo of the User's face along with the document indicated in point 1;",
          item35: "• name and surname of the User",
          item36: "• date of birth.",
          item37:
            "19. Cash payments are made at the Service Provider's office, according to current office hours.",
          item38:
            "20. Before using the Website's Services, the User is obliged to read these Regulations and accept them each time they place a transaction order.",
          item39:
            "21. The User is responsible for providing incorrect data during a transaction, authorization or KYC. Providing incorrect data may result in, in particular, preventing the transaction from being completed.",
          item40:
            "22. Withdrawals from the sale or purchase of virtual currencies are made by the Service Provider.",
          item41: "23. Payments are made by external payment operators.",
          item42:
            "24. The Service Provider is not responsible for the risk related to exchange rate fluctuations of virtual currencies, which determines the market value of the sold or purchased virtual currency.",
          item43:
            "25. The use of a Device with a modified operating system, in particular malicious software resulting in incorrect operation of the Website, is prohibited. If the User uses them, the Service Provider is entitled to apply the measures indicated in section III. 15 of the Regulations until the User stops using such Device.",
        },
        item5: {
          title: "COMPLAINTS AND WITHDRAWAL FROM THE CONTRACT",
          item1:
            "1. All complaints related to the operation of the Website and the provision of Services through it, as well as questions regarding the use of the Website should be directed to the Service Provider at the e-mail address: PayStore.Pro@proton.me",
          item2:
            "2. The complaint should include: User data, including contact details, reason for reporting, identification of the transaction being complained about, detailed description and reason for reporting.",
          item3:
            "3. Within 30 days from the date of its receipt, the Service Provider considers the complaint and informs the User about the result of its consideration. This deadline may be extended if consideration of the complaint requires obtaining special information or the Service Provider encounters other difficulties beyond its control or if it is necessary to obtain additional information from the User. The time the User provides additional information each time extends the complaint processing time.",
          item4:
            "4. A response to the complaint will be provided by the Service Provider to the e-mail address from which it was received.",
          item5:
            "5. Sending a complaint by the User in electronic form is tantamount to consenting to receiving a response from the Service Provider also in electronic form.",
          item6:
            "6. The User's use of extrajudicial means of dealing with complaints and pursuing claims is voluntary.",
          item7:
            "7. A user who is a consumer has the right to resolve disputes electronically via the EU ODR online platform available at: http://ec.europa.eu/consumers/odr/.",
          item8:
            "8. The Service Provider reserves the right not to respond to an obviously unfounded complaint, in particular to the extent that the complaint has already been considered in relation to a given User.",
        },
        item6: {
          title: "SERVICE PROVIDER'S LIABILITY",
          item1:
            "1. The Service Provider conducts ongoing supervision over the technical functioning of the Website, ensuring its correct operation. However, the Service Provider does not guarantee the constant availability of all functions of the Website or their error-free operation.",
          item2:
            "2. The User uses the Website voluntarily and at his own risk. The Service Provider's liability for any damages arising in connection with the use of the Website, in particular its lack of operation or incorrect functioning, is excluded to the fullest extent permitted by law.",
          item3:
            "3. The Service Provider is not responsible for limitations or technical problems in the IT systems used by Users' Devices, which prevent or limit Users from using the Website and the Services offered through it. The Service Provider is not responsible for the quality, efficiency or accuracy of the Website that is unsatisfactory to the User.",
          item4:
            "4. There may be interruptions in the operation of the Website due to technical reasons. The User then has no claim in connection with the interruption or cessation of the provision of Services by the Service Provider.",
          item5:
            "5. The provision of Services may be interrupted in the event of inadequate connection quality, damage or defects in telecommunications devices, power systems, computer equipment, failure of the telecommunications network, power outages or any action of third parties.",
          item6:
            "6. The Service Provider has the right to block access to the Website or individual functions in the event of any irregularities in the use of the Website, in particular the occurrence of circumstances that could harm the User or the Service Provider. The Service Provider is then not liable for temporarily suspending access to the Website for the period necessary to remove any threats or irregularities.",
          item7:
            "7. The costs of possible data transmission required to launch and use the Website are covered by the User on their own, based on contracts concluded with telecommunications operators or other Internet providers. The Service Provider is not responsible for the amount of fees charged for the use of data transmission necessary to use the Website.",
        },
        item7: {
          title: "PROCESSING OF PERSONAL DATA",
          item1:
            "1. The administrator of personal data is the Service Provider PAYSTORE Sp. z o.o. based in Warsaw (01-001), Aleja Jana Pawla II 27, registered under the number: 0001060590, NIP: 5273076950, share capital 5,000.00 PLN, within the meaning of Art. 8 of the Act of 18 July 2002 on the provision of electronic services.",
          item2:
            '2. Personal data are processed in accordance with the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/ EC (General Data Protection Regulation - hereinafter referred to as "GDPR"), the Act of May 10, 2018 on the protection of personal data and the Act of July 18, 2002 on the provision of electronic services.',
          item3:
            "3. The Administrator ensures the use of appropriate technical and organizational measures to ensure the security of the personal data being processed, in particular preventing access to them by unauthorized third parties, or their processing in violation of the provisions of generally applicable law, preventing the loss, damage or destruction of personal data.",
          item4:
            "4. Detailed rules for the processing of personal data are regulated in the Privacy Policy, which constitutes an integral part of these Regulations.",
        },
        item8: {
          title: "COPYRIGHT AND INTELLECTUAL PROPERTY",
          item1:
            "1. All rights to the Website, including copyrights to the Website, as well as to its individual parts, in particular to text, graphic, multimedia elements and programming elements generating and operating the Website, including industrial property rights and all other derivative rights , excluding content provided by Suppliers or Payment Operators, are entitled to the Service Provider.",
          item2:
            "2. Upon use of the Website and acceptance of the Regulations by the User, the Service Provider grants the User a non-exclusive license to use the Website, to the extent that the User uses its Services. The license is non-transferable and is granted for the duration of the User's use of the Website in accordance with its intended purpose and in accordance with these Regulations.",
          item3:
            "3. The non-exclusive license granted to the User does not authorize the User to grant further licenses. Moreover, the User is not authorized to act beyond the scope of the License.",
          item4:
            "4. Transferring the content contained on the Website to third parties is permitted only using the tools contained on the Website and intended for this purpose.",
          item5:
            "5. The User has no right to reproduce, sell or otherwise market or distribute the source code of the Website, in whole or in part, in particular to send or make it available in computer systems and networks, mobile application distribution systems or any other IT systems.",
          item6:
            "6. If the User violates the rules of using the Website or the terms of the granted License, the Service Provider will be entitled to block the User's access to the Website and withdraw the granted License to use the Website. The above does not prejudice the Service Provider's right to take other appropriate actions provided for by law in connection with the breach.",
        },
        item9: {
          title: "AML PROCEDURES",
          item1:
            "1. The Service Provider reserves that, as an obligated institution within the meaning of the Act of March 1, 2018 on counteracting money laundering and terrorism financing or the implementation of internal AML procedures, it may request additional verification from the Website Users.",
          item2:
            "2. The verification procedure may in particular involve a request to provide additional data, in particular:",
          item3:
            "• personal data confirmed by an identity document;",
          item4: "• documenting the source of funds;",
          item5: "• other data required by the Service Provider.",
          item6:
            "3. Failure to provide the requested data may result in suspension of the transaction and, consequently, refusal to complete it.",
          item7:
            "4. Transactions are verified in accordance with the current requirements of the Act of March 1, 2018 on counteracting money laundering and terrorism financing, as well as the Service Provider's internal AML procedure. These are in particular transactions exceeding EUR 1,000.",
          item8:
            "5. Some methods of payment withdrawal may also be verified, in particular cash.",
        },
        item10: {
          title: "FINAL PROVISIONS",
          item1:
            "1. The Service Provider has the right to change these Regulations without giving a reason. The Service Provider will then inform about the changes in a visible place on the Website. If the User does not consent to the amendment of the Regulations, he has the right to terminate the contract for the provision of electronic services.",
          item2:
            "2. The Service Provider provides access to the Regulations via the Website in such a way that it is possible to download, save and print them.",
          item3:
            "3. The provisions of these Regulations and any disputes between the Service Provider and the User are subject to the provisions of Polish law.",
          item4:
            "4. The lack of legal basis or incompleteness of any of the clauses contained in the Regulations does not mean that the entire Regulations lose their legal force. These provisions are changed to those that best reflect the meaning and purpose of the existing provisions.",
        },
      },
    },
    risk: {
      title: "Risk Warning.",
      list: {
        item1: {
          title: "GENERAL PROVISIONS AND DEFINITIONS",
          item1:
            "1. the Administrator of the personal data collected through the website https://www.paystore.pro/ , is PAYSTORE Sp. z o.o. located in Warsaw (00-867), Aleja Jana Pawla II 27, registered under the number: 0001060590, NIP: 5273076950, share capital PLN 5,000.00 within the meaning of Article 8 of the Act of July 18, 2002 on the provision of electronic services.",
          item2:
            "2. Contact the Administrator by e-mail: PayStore.Pro@proton.m",
          item3: "3. Definitions:",
          item4:
            "• Cookies - means IT data, in particular small text files, saved and stored on the devices through which the User uses the Site;",
          item5:
            "• Personal Data means information about an identified or identifiable natural person. An identifiable natural person is a person who can be identified directly or indirectly, in particular by reference to an identifier such as a name, identification number, location data, online identifier, or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of the individual;",
          item6:
            "• Profiling - means any form of automated processing of personal data that consists in using personal data to assess certain personal factors of a natural person, in particular to analyze or predict aspects relating to the consequences of that natural person's work, his or her economic activity. situation, health, personal preferences, interests, authority, behavior, location or movement;",
          item7:
            "• Processing - means an operation or set of operations performed on personal data or sets of personal data, whether or not by automated means, such as collecting, recording, organizing, structuring, storing, adapting or modifying, downloading, viewing, using, disclosing by sending, disseminating or otherwise making available, aligning or combining, restricting, deleting or destroying;",
          item8: "• The Web site is https://www.paystore.pro/",
          item9:
            "• Device - an electronic device that allows data to be processed, received and sent, e.g. laptop, computer, smartphone, tablet, cell phone;",
          item10:
            "• User - means a person to whom services may be provided electronically in accordance with the provisions of the law or with whom an Electronic Services Agreement may be concluded;",
          item11:
            "• Regulations means the document that defines the rules and terms of use of the Site;",
          item12:
            "• Payment Operator - an organization that processes Users' payments to the Service Provider for the purpose of their exchange into virtual currencies;",
          item13:
            "• Suppliers are external organizations that provide content available in the",
          item14:
            "Website, particularly suggestions, surveys and advertisements.",
        },
        item2: {
          title: "LEGAL BASIS AND PURPOSES OF PROCESSING USER DATA",
          item1:
            '1. The personal data collected by the Administrator is processed in accordance with the provisions of Regulation (EU) 2016/679 of the European Parliament and of the Council of April 27, 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data and on the repeal of Directive 95/46/EC (hereinafter "GDPR"), the Act of May 10, 2018 on the protection of personal data (Journal of Laws 2019, item. 1781) and the Act of July 18, 2002 on the provision of electronic services (Journal of Laws 2020, item. 344).',
          item2:
            "2. the Administrator processes personal data provided or made available to the User in connection with the use of the Site for the purposes of:",
          item3:
            "• conclusion and execution of a contract for the provision of electronic services and the functionality of the Website (scope of data: IP addresses, wallet address, payment card details, e-mail address, telephone number and other necessary data about the Device used by the User - in accordance with Article 6(1)(b) GDPR, i.e. because the processing is necessary for the performance of a contract to which the data subject is a party; - informing Users about issues related to the functionality of the Website (scope of data: e-mail address, e-mail address, payment card details, telephone number and other necessary data about the Device used by the User - in accordance with Article 6(1)(b) GDPR, i.e. because the processing is necessary for the performance of a contract to which the data subject is a party; - informing Users about issues related to the functionality of the Website (scope of data: e-mail address, electronic",
          item4:
            "• assertion of claims and defense of rights (scope of data: all data obtained from the User necessary to prove the existence of a claim or defense of rights) - pursuant to Art. 6 Section 1 letter f GDPR, i.e. because the processing is necessary for the purposes of legitimate interests pursued by the Administrator or a third party;",
          item5:
            "• fulfillment of the legal obligations imposed on the Administrator in connection with the conduct of business, in particular as an obliged institution within the meaning of the Anti-Money Laundering and Terrorist Financing Act (scope of data: all data collected from the User) - in accordance with Art. 6 section 1 letter c GDPR, i.e. because the processing is necessary to fulfill the legal obligations of the Administrator;",
          item6:
            "• conducting its own marketing and advertising activities (scope of data: all data received from the User) - in accordance with Article 6 Section 1 Letter e of the GDPR;",
          item7:
            "• conducting marketing and promotional activities on the basis of a separate consent (Article 6(1)(a) GDPR);",
          item8:
            "• sending commercial information electronically in accordance with Article 10 Section 2 of the Act on the provision of services by electronic means of July 18, 2002 (Journal of Laws 2017, Art. 1219, as amended), including the sending of notifications (scope of data: all data received from the User) - based on the separate consent granted (Article 6(1)(a) GDPR);",
        },
        item3: {
          title: "DATA COLLECTED BY THE SITE ADMINISTRATOR",
          item1:
            "1. the Site Administrator collects or may collect the following personal data through the Site or by direct contact with the User:",
          item2:
            "• Identification and contact details (e-mail address, telephone number);",
          item3: "• virtual currency wallet address;",
          item4:
            "• data about the Device used by the User (including IP address, device type, device brand, device model, device name, device language);",
          item5:
            "• data on the User's use of the VPN software or the Device with a modified operating system;",
          item6:
            "• other data voluntarily provided by the User when communicating with the Administrator, including data about their Device, correspondence data and other data not listed above.",
          item7:
            "2. Viewing the content of the Site does not require the provision of personal data, except for automatically obtained information about the connection parameters (IP address).",
          item8:
            "3. As part of the services provided on the Website, the Administrator collects the following personal data:",
          item9: "• e-mail address;",
          item10: "• cell phone number;",
          item11: "• virtual currency wallet address;",
          item12: "• payment card details;",
          item13: "• IP address;",
          item14: "• other necessary data about the Device used by the User.",
          item15:
            "4. The Administrator reserves the right within the framework of using the services of the Site, including the payment system, may also process personal data of the User on the basis of its own privacy policy.",
        },
        item4: {
          title: "COOKIE",
          item1:
            "1. the Administrator collects cookies related to the User's activity on the Site.",
          item2:
            "2. the Administrator may use profiling of the processed data on the basis of Article 6 section 1 letter c GDPR in connection with the obligations arising from the Act of March 1, 2018 on combating money laundering and terrorist financing.",
          item3:
            "3. When using the Site, small files, in particular text files, are stored on the User's device, which are used to remember the User's decisions, maintain the User's session, remember the entered data, collect information about the User's device and its visit to ensure security, as well as analyze visits and adjust content.",
          item4:
            "4. Cookies do not contain data identifying the User, which means that it is impossible to determine the User's identity based on them. The files used by the Site do not in any way harm the User or the device and do not affect its software or settings.",
          item5:
            "5. A cookie is assigned to the User after entering the main page of the Site or any of its subpages.",
          item6:
            "6. The cookie system does not interfere with the User's computer and can be disabled.",
          item7:
            "7. The user can set the browser to block certain types of cookies and other technologies by specifying the amount of information collection allowed.",
          item8:
            "8. Using the site without changing your browser settings, i.e. accepting cookies and similar technologies by default, constitutes consent to their use for the purposes set out in this Privacy Policy.",
          item9:
            "9. The Administrator informs that if the files are necessary for the Site operation, restriction of their use may prevent the use of the Site services.",
          item10:
            '10. The browser settings of the User\'s device must allow the storage of cookies and authorize consent by clicking "OK" in the window that appears after entering the Website with the information: This website uses cookies to ensure the correct provision of the service. By continuing to use the Website, you consent to their use - these files will be stored on your device.',
          item11:
            "11. Providers, including payment processors, may collect cookies based on the User's separately provided consent.",
        },
        item5: {
          title: "TIME OF PERSONAL DATA PROCESSING",
          item1: "1. Personal data will be processed during the period:",
          item2:
            "• is necessary for implementation of the contract for provision of electronic services specified in the Regulations, concluded through the Website, including after its execution due to the possibility of the parties to exercise their rights under the contract, as well as due to the possible collection of receivables - before the expiration of the limitation period for claims;",
          item3:
            "• until the moment of withdrawal of consent or filing an objection to data processing - in cases of processing the User's personal data on the basis of a separate consent;",
          item4:
            "2 The Administrator stores personal data of Users also when it is necessary to fulfill its legal obligations, resolve disputes, enforce User's obligations, ensure security and prevent fraud and abuse.",
        },
        item6: {
          title: "USER AUTHORIZATIONS",
          item1:
            "1. the Administrator ensures that Users exercise the rights specified in section 2 below. To exercise the rights, send the corresponding request (inquiry) by e-mail to the address: PayStore.Pro@proton.me or by traditional mail to the address of the Administrator's location.",
          item2: "2. The user has the right to:",
          item3:
            "• access to data content - in accordance with Article 15 GDPR;",
          item4:
            "• data correction/updating - in accordance with Article 16 GDPR;",
          item5: "",
          item6: "• data deletion - in accordance with Article 17 GDPR;",
          item7:
            "• restrictions on data processing - in accordance with Article 18 GDPR;",
          item8: "• data transfer - in accordance with Article 20 GDPR;",
          item9:
            "• object to data processing - in accordance with Article 21 GDPR;",
          item10:
            "• withdrawal of consent at any time, but withdrawal of consent does not affect the lawfulness of processing carried out on the basis of consent prior to its withdrawal - in accordance with Art. 7 Section 3",
          item11: "GDPR;",
          item12:
            "• lodge a complaint with the supervisory authority, i.e. the Chairman of the Personal Data Protection Authority - in accordance with Article 77 GDPR;",
          item13:
            "3. The Administrator will consider submitted requests without undue delay, but no later than within one month from the date of receipt. However, if - due to the complex nature of the request or the number of requests - the Administrator will not be able to consider the User's request within the specified period of time, he will inform the User of the expected extension of the deadline and specify the deadline. for consideration of the application, but not more than 2 months.",
          item14:
            "4. The Administrator shall inform each recipient to whom personal data have been disclosed of the rectification or deletion of personal data or restriction of processing carried out by it in accordance with the User's request, unless this proves impossible or requires disproportionate effort.",
        },
        item7: {
          title: "NEED FOR DATA PROVISION",
          item1:
            "1. To fulfill the contract, the Administrator may make the data collected from Users available to legal entities, including: employees, collaborators, organizations providing legal and IT services to the Administrator and suppliers, including payment providers. In addition, the Administrator makes the collected personal data available to the person with whom it has concluded a contract on entrusting the processing of personal data.",
          item2:
            "2. In such cases, the amount of data transmitted is limited to the necessary minimum. In addition, the information provided by Users may be made available to competent public authorities, limited to situations where this is required by applicable law.",
          item3:
            "3. To recipients other than those specified above, processed personal data are not provided externally in a form that allows identification of Users, unless the User has consented to the specific disclosure of the data.",
        },
        item8: {
          title: "TECHNICAL MEASURES",
          item1:
            "1. the Administrator shall make every effort to ensure the security of Users' data and protect them from the actions of third parties, and shall control the security of data throughout their possession in such a way as to ensure protection against unauthorized access, damage, distortion, destruction. or loss of data.",
          item2:
            "2. the Administrator applies the necessary security measures to servers, connections and the Site. However, the Administrator's actions may be insufficient if Users fail to comply with the security rules.",
        },
        item9: {
          title: "TRANSFER OF PERSONAL DATA OUTSIDE THE EUROPEAN ECONOMIC AREA",
          item1:
            "1. Personal data of users are not transferred to countries outside the EEA. The Administrator uses servers for data storage located in EEA countries.",
          item2:
            "2 The Administrator reserves the right of suppliers, including payment operators, to process and transfer personal data outside the European Economic Area.",
        },
        item10: {
          title: "SUBJECTS PROCESSING DATA ON BEHALF OF THE ADMINISTRATOR",
          item1:
            "1. Users' personal data may be transferred for processing on behalf of the Administrator to portals supporting the Administrator's marketing campaign. Each processing entity is obliged to ensure the security of processing and comply with the principles of processing your personal data to the same extent as the Administrator.",
        },
        item11: {
          title: "CHANGE IN PRIVACY POLICY AND COOKIES",
          item1:
            "1. the Administrator has the right to change this document, about which the User will be notified in a way that allows the User to familiarize with the changes before they take effect, for example, by posting the relevant information on the Site.",
          item2:
            "2. If the User has objections to the changes made, he/she may request the deletion of personal data from the Site. Continued use of the Site after publication or sending of the notice of changes hereof shall be deemed consent to collection, use and disclosure of the User's personal data in accordance with the updated content of the document.",
          item3:
            "3 This document does not limit any of the User's rights under generally applicable law",
        },
      },
    },
    policy: {
      title: "Privacy policy",
      list: {
        item1: {
          title: "GENERAL PROVISIONS AND DEFINITIONS",
          item1:
            "1. the Administrator of the personal data collected through the website https://www.paystore.pro/ , is PAYSTORE Sp. z o.o. located in Warsaw (00-867), Aleja Jana Pawla II 27, registered under the number: 0001060590, NIP: 5273076950, share capital PLN 5,000.00 within the meaning of Article 8 of the Act of July 18, 2002 on the provision of electronic services.",
          item2:
            "2. Contact the Administrator by e-mail: PayStore.Pro@proton.m",
          item3: "3. Definitions:",
          item4:
            "• Cookies - means IT data, in particular small text files, saved and stored on the devices through which the User uses the Site;",
          item5:
            "• Personal Data means information about an identified or identifiable natural person. An identifiable natural person is a person who can be identified directly or indirectly, in particular by reference to an identifier such as a name, identification number, location data, online identifier, or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of the individual;",
          item6:
            "• Profiling - means any form of automated processing of personal data that consists in using personal data to assess certain personal factors of a natural person, in particular to analyze or predict aspects relating to the consequences of that natural person's work, his or her economic activity. situation, health, personal preferences, interests, authority, behavior, location or movement;",
          item7:
            "• Processing - means an operation or set of operations performed on personal data or sets of personal data, whether or not by automated means, such as collecting, recording, organizing, structuring, storing, adapting or modifying, downloading, viewing, using, disclosing by sending, disseminating or otherwise making available, aligning or combining, restricting, deleting or destroying;",
          item8: "• The Web site is https://www.paystore.pro/",
          item9:
            "• Device - an electronic device that allows data to be processed, received and sent, e.g. laptop, computer, smartphone, tablet, cell phone;",
          item10:
            "• User - means a person to whom services may be provided electronically in accordance with the provisions of the law or with whom an Electronic Services Agreement may be concluded;",
          item11:
            "• Regulations means the document that defines the rules and terms of use of the Site;",
          item12:
            "• Payment Operator - an organization that processes Users' payments to the Service Provider for the purpose of their exchange into virtual currencies;",
          item13:
            "• Suppliers are external organizations that provide content available in the",
          item14:
            "Website, particularly suggestions, surveys and advertisements.",
        },
        item2: {
          title: "LEGAL BASIS AND PURPOSES OF PROCESSING USER DATA",
          item1:
            '1. The personal data collected by the Administrator is processed in accordance with the provisions of Regulation (EU) 2016/679 of the European Parliament and of the Council of April 27, 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data and on the repeal of Directive 95/46/EC (hereinafter "GDPR"), the Act of May 10, 2018 on the protection of personal data (Journal of Laws 2019, item. 1781) and the Act of July 18, 2002 on the provision of electronic services (Journal of Laws 2020, item. 344).',
          item2:
            "2. the Administrator processes personal data provided or made available to the User in connection with the use of the Site for the purposes of:",
          item3:
            "• conclusion and execution of a contract for the provision of electronic services and the functionality of the Website (scope of data: IP addresses, wallet address, payment card details, e-mail address, telephone number and other necessary data about the Device used by the User - in accordance with Article 6(1)(b) GDPR, i.e. because the processing is necessary for the performance of a contract to which the data subject is a party; - informing Users about issues related to the functionality of the Website (scope of data: e-mail address, e-mail address, payment card details, telephone number and other necessary data about the Device used by the User - in accordance with Article 6(1)(b) GDPR, i.e. because the processing is necessary for the performance of a contract to which the data subject is a party; - informing Users about issues related to the functionality of the Website (scope of data: e-mail address, electronic",
          item4:
            "• assertion of claims and defense of rights (scope of data: all data obtained from the User necessary to prove the existence of a claim or defense of rights) - pursuant to Art. 6 Section 1 letter f GDPR, i.e. because the processing is necessary for the purposes of legitimate interests pursued by the Administrator or a third party;",
          item5:
            "• fulfillment of the legal obligations imposed on the Administrator in connection with the conduct of business, in particular as an obliged institution within the meaning of the Anti-Money Laundering and Terrorist Financing Act (scope of data: all data collected from the User) - in accordance with Art. 6 section 1 letter c GDPR, i.e. because the processing is necessary to fulfill the legal obligations of the Administrator;",
          item6:
            "• conducting its own marketing and advertising activities (scope of data: all data received from the User) - in accordance with Article 6 Section 1 Letter e of the GDPR;",
          item7:
            "• conducting marketing and promotional activities on the basis of a separate consent (Article 6(1)(a) GDPR);",
          item8:
            "• sending commercial information electronically in accordance with Article 10 Section 2 of the Act on the provision of services by electronic means of July 18, 2002 (Journal of Laws 2017, Art. 1219, as amended), including the sending of notifications (scope of data: all data received from the User) - based on the separate consent granted (Article 6(1)(a) GDPR);",
        },
        item3: {
          title: "DATA COLLECTED BY THE SITE ADMINISTRATOR",
          item1:
            "1. the Site Administrator collects or may collect the following personal data through the Site or by direct contact with the User:",
          item2:
            "• Identification and contact details (e-mail address, telephone number);",
          item3: "• virtual currency wallet address;",
          item4:
            "• data about the Device used by the User (including IP address, device type, device brand, device model, device name, device language);",
          item5:
            "• data on the User's use of the VPN software or the Device with a modified operating system;",
          item6:
            "• other data voluntarily provided by the User when communicating with the Administrator, including data about their Device, correspondence data and other data not listed above.",
          item7:
            "2. Viewing the content of the Site does not require the provision of personal data, except for automatically obtained information about the connection parameters (IP address).",
          item8:
            "3. As part of the services provided on the Website, the Administrator collects the following personal data:",
          item9: "• e-mail address;",
          item10: "• cell phone number;",
          item11: "• virtual currency wallet address;",
          item12: "• payment card details;",
          item13: "• IP address;",
          item14: "• other necessary data about the Device used by the User.",
          item15:
            "4. The Administrator reserves the right within the framework of using the services of the Site, including the payment system, may also process personal data of the User on the basis of its own privacy policy.",
        },
        item4: {
          title: "COOKIE",
          item1:
            "1. the Administrator collects cookies related to the User's activity on the Site.",
          item2:
            "2. the Administrator may use profiling of the processed data on the basis of Article 6 section 1 letter c GDPR in connection with the obligations arising from the Act of March 1, 2018 on combating money laundering and terrorist financing.",
          item3:
            "3. When using the Site, small files, in particular text files, are stored on the User's device, which are used to remember the User's decisions, maintain the User's session, remember the entered data, collect information about the User's device and its visit to ensure security, as well as analyze visits and adjust content.",
          item4:
            "4. Cookies do not contain data identifying the User, which means that it is impossible to determine the User's identity based on them. The files used by the Site do not in any way harm the User or the device and do not affect its software or settings.",
          item5:
            "5. A cookie is assigned to the User after entering the main page of the Site or any of its subpages.",
          item6:
            "6. The cookie system does not interfere with the User's computer and can be disabled.",
          item7:
            "7. The user can set the browser to block certain types of cookies and other technologies by specifying the amount of information collection allowed.",
          item8:
            "8. Using the site without changing your browser settings, i.e. accepting cookies and similar technologies by default, constitutes consent to their use for the purposes set out in this Privacy Policy.",
          item9:
            "9. The Administrator informs that if the files are necessary for the Site operation, restriction of their use may prevent the use of the Site services.",
          item10:
            '10. The browser settings of the User\'s device must allow the storage of cookies and authorize consent by clicking "OK" in the window that appears after entering the Website with the information: This website uses cookies to ensure the correct provision of the service. By continuing to use the Website, you consent to their use - these files will be stored on your device.',
          item11:
            "11. Providers, including payment processors, may collect cookies based on the User's separately provided consent.",
        },
        item5: {
          title: "TIME OF PERSONAL DATA PROCESSING",
          item1: "1. Personal data will be processed during the period:",
          item2:
            "• is necessary for implementation of the contract for provision of electronic services specified in the Regulations, concluded through the Website, including after its execution due to the possibility of the parties to exercise their rights under the contract, as well as due to the possible collection of receivables - before the expiration of the limitation period for claims;",
          item3:
            "• until the moment of withdrawal of consent or filing an objection to data processing - in cases of processing the User's personal data on the basis of a separate consent;",
          item4:
            "2 The Administrator stores personal data of Users also when it is necessary to fulfill its legal obligations, resolve disputes, enforce User's obligations, ensure security and prevent fraud and abuse.",
        },
        item6: {
          title: "USER AUTHORIZATIONS",
          item1:
            "1. the Administrator ensures that Users exercise the rights specified in section 2 below. To exercise the rights, send the corresponding request (inquiry) by e-mail to the address: PayStore.Pro@proton.me or by traditional mail to the address of the Administrator's location.",
          item2: "2. The user has the right to:",
          item3:
            "• access to data content - in accordance with Article 15 GDPR;",
          item4:
            "• data correction/updating - in accordance with Article 16 GDPR;",
          item5: "",
          item6: "• data deletion - in accordance with Article 17 GDPR;",
          item7:
            "• restrictions on data processing - in accordance with Article 18 GDPR;",
          item8: "• data transfer - in accordance with Article 20 GDPR;",
          item9:
            "• object to data processing - in accordance with Article 21 GDPR;",
          item10:
            "• withdrawal of consent at any time, but withdrawal of consent does not affect the lawfulness of processing carried out on the basis of consent prior to its withdrawal - in accordance with Art. 7 Section 3",
          item11: "GDPR;",
          item12:
            "• lodge a complaint with the supervisory authority, i.e. the Chairman of the Personal Data Protection Authority - in accordance with Article 77 GDPR;",
          item13:
            "3. The Administrator will consider submitted requests without undue delay, but no later than within one month from the date of receipt. However, if - due to the complex nature of the request or the number of requests - the Administrator will not be able to consider the User's request within the specified period of time, he will inform the User of the expected extension of the deadline and specify the deadline. for consideration of the application, but not more than 2 months.",
          item14:
            "4. The Administrator shall inform each recipient to whom personal data have been disclosed of the rectification or deletion of personal data or restriction of processing carried out by it in accordance with the User's request, unless this proves impossible or requires disproportionate effort.",
        },
        item7: {
          title: "NEED FOR DATA PROVISION",
          item1:
            "1. To fulfill the contract, the Administrator may make the data collected from Users available to legal entities, including: employees, collaborators, organizations providing legal and IT services to the Administrator and suppliers, including payment providers. In addition, the Administrator makes the collected personal data available to the person with whom it has concluded a contract on entrusting the processing of personal data.",
          item2:
            "2. In such cases, the amount of data transmitted is limited to the necessary minimum. In addition, the information provided by Users may be made available to competent public authorities, limited to situations where this is required by applicable law.",
          item3:
            "3. To recipients other than those specified above, processed personal data are not provided externally in a form that allows identification of Users, unless the User has consented to the specific disclosure of the data.",
        },
        item8: {
          title: "TECHNICAL MEASURES",
          item1:
            "1. the Administrator shall make every effort to ensure the security of Users' data and protect them from the actions of third parties, and shall control the security of data throughout their possession in such a way as to ensure protection against unauthorized access, damage, distortion, destruction. or loss of data.",
          item2:
            "2. the Administrator applies the necessary security measures to servers, connections and the Site. However, the Administrator's actions may be insufficient if Users fail to comply with the security rules.",
        },
        item9: {
          title: "TRANSFER OF PERSONAL DATA OUTSIDE THE EUROPEAN ECONOMIC AREA",
          item1:
            "1. Personal data of users are not transferred to countries outside the EEA. The Administrator uses servers for data storage located in EEA countries.",
          item2:
            "2 The Administrator reserves the right of suppliers, including payment operators, to process and transfer personal data outside the European Economic Area.",
        },
        item10: {
          title: "SUBJECTS PROCESSING DATA ON BEHALF OF THE ADMINISTRATOR",
          item1:
            "1. Users' personal data may be transferred for processing on behalf of the Administrator to portals supporting the Administrator's marketing campaign. Each processing entity is obliged to ensure the security of processing and comply with the principles of processing your personal data to the same extent as the Administrator.",
        },
        item11: {
          title: "CHANGE IN PRIVACY POLICY AND COOKIES",
          item1:
            "1. the Administrator has the right to change this document, about which the User will be notified in a way that allows the User to familiarize with the changes before they take effect, for example, by posting the relevant information on the Site.",
          item2:
            "2. If the User has objections to the changes made, he/she may request the deletion of personal data from the Site. Continued use of the Site after publication or sending of the notice of changes hereof shall be deemed consent to collection, use and disclosure of the User's personal data in accordance with the updated content of the document.",
          item3:
            "3 This document does not limit any of the User's rights under generally applicable law",
        },
      },
    },
  },
};
