import React from "react";
import { useTranslation } from "react-i18next";

const Risk = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "rules.risk.";
  return (
    <div className="returns">
      <h5>{t(`${basePath}title`)}</h5>

      <p>{t(`${basePath}list.item1.title`)}</p>
      <p>{t(`${basePath}list.item1.item1`)}</p>
      <p>{t(`${basePath}list.item1.item2`)}</p>
      <p>{t(`${basePath}list.item1.item3`)}</p>
      <p>{t(`${basePath}list.item1.item4`)}</p>
      <p>{t(`${basePath}list.item1.item5`)}</p>
      <p>{t(`${basePath}list.item1.item6`)}</p>
      <p>{t(`${basePath}list.item1.item7`)}</p>
      <p>{t(`${basePath}list.item1.item8`)}</p>
      <p>{t(`${basePath}list.item1.item9`)}</p>
      <p>{t(`${basePath}list.item1.item10`)}</p>
      <p>{t(`${basePath}list.item1.item11`)}</p>
      <p>{t(`${basePath}list.item1.item12`)}</p>
      <p>{t(`${basePath}list.item1.item13`)}</p>
      <p>{t(`${basePath}list.item1.item14`)}</p>

      <p className="mt-30">{t(`${basePath}list.item2.title`)}</p>
      <p>{t(`${basePath}list.item2.item1`)}</p>
      <p>{t(`${basePath}list.item2.item2`)}</p>
      <p>{t(`${basePath}list.item2.item3`)}</p>
      <p>{t(`${basePath}list.item2.item4`)}</p>
      <p>{t(`${basePath}list.item2.item5`)}</p>
      <p>{t(`${basePath}list.item2.item6`)}</p>
      <p>{t(`${basePath}list.item2.item7`)}</p>
      <p>{t(`${basePath}list.item2.item8`)}</p>

      <p className="mt-30">{t(`${basePath}list.item3.title`)}</p>
      <p>{t(`${basePath}list.item3.item1`)}</p>
      <p>{t(`${basePath}list.item3.item2`)}</p>
      <p>{t(`${basePath}list.item3.item3`)}</p>
      <p>{t(`${basePath}list.item3.item4`)}</p>
      <p>{t(`${basePath}list.item3.item5`)}</p>
      <p>{t(`${basePath}list.item3.item6`)}</p>
      <p>{t(`${basePath}list.item3.item7`)}</p>
      <p>{t(`${basePath}list.item3.item8`)}</p>
      <p>{t(`${basePath}list.item3.item9`)}</p>
      <p>{t(`${basePath}list.item3.item10`)}</p>
      <p>{t(`${basePath}list.item3.item11`)}</p>
      <p>{t(`${basePath}list.item3.item12`)}</p>
      <p>{t(`${basePath}list.item3.item13`)}</p>
      <p>{t(`${basePath}list.item3.item14`)}</p>
      <p>{t(`${basePath}list.item3.item15`)}</p>

      <p className="mt-30">{t(`${basePath}list.item4.title`)}</p>
      <p>{t(`${basePath}list.item4.item1`)}</p>
      <p>{t(`${basePath}list.item4.item2`)}</p>
      <p>{t(`${basePath}list.item4.item3`)}</p>
      <p>{t(`${basePath}list.item4.item4`)}</p>
      <p>{t(`${basePath}list.item4.item5`)}</p>
      <p>{t(`${basePath}list.item4.item6`)}</p>
      <p>{t(`${basePath}list.item4.item7`)}</p>
      <p>{t(`${basePath}list.item4.item8`)}</p>
      <p>{t(`${basePath}list.item4.item9`)}</p>
      <p>{t(`${basePath}list.item4.item10`)}</p>
      <p>{t(`${basePath}list.item4.item11`)}</p>

      <p className="mt-30">{t(`${basePath}list.item5.title`)}</p>
      <p>{t(`${basePath}list.item5.item1`)}</p>
      <p>{t(`${basePath}list.item5.item2`)}</p>
      <p>{t(`${basePath}list.item5.item3`)}</p>
      <p>{t(`${basePath}list.item5.item4`)}</p>

      <p className="mt-30">{t(`${basePath}list.item6.title`)}</p>
      <p>{t(`${basePath}list.item6.item1`)}</p>
      <p>{t(`${basePath}list.item6.item2`)}</p>
      <p>{t(`${basePath}list.item6.item3`)}</p>
      <p>{t(`${basePath}list.item6.item4`)}</p>
      <p>{t(`${basePath}list.item6.item5`)}</p>
      <p>{t(`${basePath}list.item6.item6`)}</p>
      <p>{t(`${basePath}list.item6.item7`)}</p>
      <p>{t(`${basePath}list.item6.item8`)}</p>
      <p>{t(`${basePath}list.item6.item9`)}</p>
      <p>{t(`${basePath}list.item6.item10`)}</p>
      <p>{t(`${basePath}list.item6.item11`)}</p>
      <p>{t(`${basePath}list.item6.item12`)}</p>
      <p>{t(`${basePath}list.item6.item13`)}</p>

      <p className="mt-30">{t(`${basePath}list.item7.title`)}</p>
      <p>{t(`${basePath}list.item7.item1`)}</p>
      <p>{t(`${basePath}list.item7.item2`)}</p>
      <p>{t(`${basePath}list.item7.item3`)}</p>

      <p className="mt-30">{t(`${basePath}list.item8.title`)}</p>
      <p>{t(`${basePath}list.item8.item1`)}</p>
      <p>{t(`${basePath}list.item8.item2`)}</p>

      <p className="mt-30">{t(`${basePath}list.item9.title`)}</p>
      <p>{t(`${basePath}list.item9.item1`)}</p>
      <p>{t(`${basePath}list.item9.item2`)}</p>

      <p className="mt-30">{t(`${basePath}list.item10.title`)}</p>
      <p>{t(`${basePath}list.item10.item1`)}</p>

      <p className="mt-30">{t(`${basePath}list.item11.title`)}</p>
      <p>{t(`${basePath}list.item11.item1`)}</p>
      <p>{t(`${basePath}list.item11.item2`)}</p>
      <p>{t(`${basePath}list.item11.item3`)}</p>
    </div>
  );
};

export default Risk;
