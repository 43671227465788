export const CRYPTOCURRENCY_FORM = [
  {
    id: "tethertrc",
    type: "crypto",
    symbol: "USDT",
    name: "TRC20 Tether USDT",
    memo: false,
    reserve: 2922043,
    min: 1000.0,
    max: 1000000.0,
    wallet: "TJYQVPLyEs1krhf5J61Svra2BFCSxBBAug",
    icon: require("../assets/img/cryptocurrency/usdtt.svg").default,
  },
  {
    id: "tethererc",
    type: "crypto",
    symbol: "USDC",
    name: "ERC20 Tether USDT",
    memo: false,
    reserve: 2922043,
    min: 1000.0,
    max: 1000000.0,
    wallet: "0x67DE640253B4e0219EC6331Cd01e904C12BfA6eA",
    icon: require("../assets/img/cryptocurrency/usdte.svg").default,
  },
  {
    id: "solana",
    type: "crypto",
    symbol: "SOL",
    name: "SOL Solana",
    memo: false,
    reserve: 101,
    min: 5,
    max: 1000000,
    wallet: "Eo6ANRrzsEVxVG51V4HCNGsjeGmtXJFQhvWBdU9vE4sB",
    icon: require("../assets/img/cryptocurrency/sol.svg").default,
  },
  {
    id: "bitcoin",
    type: "crypto",
    symbol: "BTC",
    name: "BTC Bitcoin",
    memo: false,
    reserve: 2922043,
    min: 0.01,
    max: 100,
    wallet: "bc1qvqwsdv80dnzfuxpjj7kaa3w3ugacjg8na2zjdn",
    icon: require("../assets/img/cryptocurrency/btc.svg").default,
  },
  {
    id: "ethereum",
    type: "crypto",
    symbol: "ETH",
    name: "ETH Ethereum",
    memo: false,
    reserve: 704596,
    min: 0.3,
    max: 100000,
    wallet: "0x67DE640253B4e0219EC6331Cd01e904C12BfA6eA",
    icon: require("../assets/img/cryptocurrency/eth.svg").default,
  },
  {
    id: "tron",
    type: "crypto",
    symbol: "TRX",
    name: "TRX Tron",
    memo: false,
    reserve: 57.87,
    min: 4000,
    max: 1000000,
    wallet: "TJYQVPLyEs1krhf5J61Svra2BFCSxBBAug",
    icon: require("../assets/img/cryptocurrency/trx.svg").default,
  },
  {
    id: "litecoin",
    type: "crypto",
    symbol: "LTC",
    name: "LTC Litecoin",
    memo: false,
    reserve: 57.87,
    min: 8,
    max: 1000000,
    wallet: "ltc1qgqwn6yc7mh0ewcn7zwqt57rz26tnafyutymxk2",
    icon: require("../assets/img/cryptocurrency/ltc.svg").default,
  },
  {
    id: "monero",
    type: "crypto",
    symbol: "XMR",
    name: "XMR Monero",
    memo: false,
    reserve: 1045715,
    min: 5,
    max: 1000000,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/xmr.svg").default,
  },
  {
    id: "ripple",
    type: "crypto",
    symbol: "XRP",
    name: "XRP Ripple",
    memo: false,
    reserve: 1045315,
    min: 1000,
    max: 10000000,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/xrp.svg").default,
  },
  {
    id: "link",
    type: "crypto",
    symbol: "LINK",
    name: "LINK Chainlink",
    memo: false,
    reserve: 57.87,
    min: 25,
    max: 1000000,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/link.svg").default,
  },
  {
    id: "avalanche",
    type: "crypto",
    symbol: "AVAX",
    name: "AVAX Avalanche",
    memo: false,
    reserve: 4248,
    min: 25,
    max: 1000000,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/avax.png"),
  },
  {
    id: "dogecoin",
    type: "crypto",
    symbol: "DOGE",
    name: "DOGE Dogecoin",
    memo: false,
    reserve: 57.87,
    min: 5000,
    max: 1000000,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/doge.svg").default,
  },
  {
    id: "cardano",
    type: "crypto",
    symbol: "ADA",
    name: "ADA Cardano",
    memo: false,
    reserve: 57.87,
    min: 1000,
    max: 1000000,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/ada.svg").default,
  },
  {
    id: "polkadot",
    type: "crypto",
    symbol: "DOT",
    name: "DOT Polkadot",
    memo: false,
    reserve: 1045315,
    min: 50,
    max: 1000000,
    wallet: "13y1rTzw4g97DVVdKXNmmfzUaxpTsdGr2Yk6GzQ3hMe3nLu5",
    icon: require("../assets/img/cryptocurrency/dot.png"),
  },
  {
    id: "bitcoincash",
    type: "crypto",
    symbol: "BCH",
    name: "BCH Bitcoin Cash",
    memo: false,
    reserve: 57.57,
    min: 2,
    max: 10000,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/bch.svg").default,
  },
  {
    id: "stellar",
    type: "crypto",
    symbol: "XML",
    name: "XML Stellar",
    memo: false,
    reserve: 343352,
    min: 5000,
    max: 100000000,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/xml.svg").default,
  },
  {
    id: "dash",
    type: "crypto",
    symbol: "DASH",
    name: "DASH Dash",
    memo: false,
    reserve: 57.87,
    min: 50.0,
    max: 1000000.0,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/dash.svg").default,
  },
  {
    id: "shiba",
    type: "crypto",
    symbol: "SHIB",
    name: "SHIB Shiba Inu",
    memo: false,
    reserve: 1045315,
    min: 21653713.29879101,
    max: 43307426597.58204,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/shib.svg").default,
  },
  {
    id: "atom",
    type: "crypto",
    symbol: "ATOM",
    name: "ATOM Cosmos",
    memo: false,
    reserve: 57.87,
    min: 40,
    max: 1000000,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/atom.svg").default,
  },
  {
    id: "eos",
    type: "crypto",
    symbol: "EOS",
    name: "EOS",
    memo: false,
    reserve: 57.87,
    min: 750,
    max: 1000000,
    wallet: "0x67DE640253B4e0219EC6331Cd01e904C12BfA6eA",
    icon: require("../assets/img/cryptocurrency/eos.svg").default,
  },
  {
    id: "near",
    type: "crypto",
    symbol: "NEAR",
    name: "NEAR Protocol",
    memo: false,
    reserve: 57.87,
    min: 175,
    max: 1000000,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/near.svg").default,
  },
  {
    id: "ethereumclassic",
    type: "crypto",
    symbol: "ETC",
    name: "ETC Ether Classic",
    memo: false,
    reserve: 57.87,
    min: 20,
    max: 100000,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/etc.png"),
  },
  {
    id: "aedcard",
    type: "fiat",
    symbol: "AEDCARD",
    name: "AED Visa/MasterCard",
    memo: false,
    reserve: 1045315,
    min: 400.0,
    max: 1000000.0,
    wallet: "",
    icon: require("../assets/img/cryptocurrency/card.svg").default,
  },
];

export const CRYPTOCURRENCY_TO = [
  {
    id: "tethertrc",
    type: "crypto",
    symbol: "USDT",
    name: "TRC20 Tether USDT",
    memo: false,
    reserve: 1048240.71,
    label: "Tether TRC20",
    icon: require("../assets/img/cryptocurrency/usdtt.svg").default,
  },
  {
    id: "tethererc",
    type: "crypto",
    symbol: "USDC",
    name: "ERC20 Tether USDT",
    memo: false,
    reserve: 225311.96,
    label: "Tether ERC20",
    icon: require("../assets/img/cryptocurrency/usdte.svg").default,
  },
  {
    id: "thbcash",
    type: "fiat",
    symbol: "THB",
    name: "THB/Cash",
    memo: false,
    reserve: 970479,
    icon: require("../assets/img/cryptocurrency/thb.png"),
  },
  {
    id: "pnlblik",
    type: "fiat",
    symbol: "PLN",
    name: "PLN Blik",
    memo: false,
    reserve: 822926.192467,
    icon: require("../assets/img/cryptocurrency/blik.png"),
  },
  {
    id: "privat",
    type: "fiat",
    symbol: "UAHPrivat",
    name: "UAH Privat24",
    memo: false,
    reserve: 2922043.33,
    icon: require("../assets/img/cryptocurrency/privat.svg").default,
  },
  {
    id: "monobank",
    type: "fiat",
    symbol: "UAHMono",
    name: "UAH Monobank",
    memo: false,
    reserve: 3088628.5573,
    icon: require("../assets/img/cryptocurrency/mono.png"),
  },
  {
    id: "kaspibank",
    type: "fiat",
    symbol: "KZTKaspi",
    name: "KZT Kaspi Bank",
    memo: false,
    reserve: 781206946.1556826,
    icon: require("../assets/img/cryptocurrency/kaspi.svg").default,
  },
  {
    id: "centercredir",
    type: "fiat",
    symbol: "KZTCenter",
    name: "KZT CenterCredit Bank",
    memo: false,
    reserve: 787993403.3933587,
    icon: require("../assets/img/cryptocurrency/centercredit.png"),
  },
  {
    id: "plncard",
    type: "fiat",
    symbol: "PLNCard",
    name: "PLN Visa/MasterCard",
    memo: false,
    reserve: 826166.776543,
    icon: require("../assets/img/cryptocurrency/card.svg").default,
  },
  {
    id: "aedcard",
    type: "fiat",
    symbol: "AEDCard",
    name: "AED Visa/MasterCard",
    memo: false,
    reserve: 2368623.77,
    icon: require("../assets/img/cryptocurrency/card.svg").default,
  },
  {
    id: "trycard",
    type: "fiat",
    symbol: "TRYCard",
    name: "TRY Visa/MasterCard",
    memo: false,
    reserve: 2397948.85,
    icon: require("../assets/img/cryptocurrency/card.svg").default,
  },
  {
    id: "uahcard",
    type: "fiat",
    symbol: "UAHCard",
    name: "UAH Visa/MasterCard",
    memo: false,
    reserve: 3224327.92,
    icon: require("../assets/img/cryptocurrency/card.svg").default,
  },
  {
    id: "plnbank",
    type: "fiat",
    symbol: "PLNBank",
    name: "PLN Bank Transfer",
    memo: false,
    reserve: 836394.873327,
    icon: require("../assets/img/cryptocurrency/pln.png"),
  },
  {
    id: "czkbank",
    type: "fiat",
    symbol: "CZKBank",
    name: "CZK Bank Transfer",
    memo: false,
    reserve: 832819.51,
    icon: require("../assets/img/cryptocurrency/czk.png"),
  },
  {
    id: "aedbank",
    type: "fiat",
    symbol: "AEDBank",
    name: "AED Bank Transfer",
    memo: false,
    reserve: 2159053.27,
    icon: require("../assets/img/cryptocurrency/aed.png"),
  },
  {
    id: "thbbank",
    type: "crypto",
    symbol: "THBBank",
    name: "THB Bank Transfer",
    memo: false,
    reserve: 2428236.0,
    icon: require("../assets/img/cryptocurrency/thbtransfer.png"),
  },
  {
    id: "aznbank",
    type: "fiat",
    symbol: "AZNBank",
    name: "AZN Bank Transfer",
    memo: false,
    reserve: 2428236.0,
    icon: require("../assets/img/cryptocurrency/azn.png"),
  },
  {
    id: "uahbank",
    type: "fiat",
    symbol: "UAHBank",
    name: "UAH Bank Transfer",
    memo: false,
    reserve: 3294832.0,
    icon: require("../assets/img/cryptocurrency/uah.png"),
  },
  {
    id: "eurrevolut",
    type: "fiat",
    symbol: "EURRev",
    name: "EUR Revolut",
    memo: false,
    reserve: 246851.669558,
    icon: require("../assets/img/cryptocurrency/revolut.png"),
  },
  {
    id: "bitcoin",
    type: "crypto",
    symbol: "BTC",
    name: "BTC Bitcoin",
    memo: false,
    reserve: 57.8874883519,
    label: "Bitcoin",
    icon: require("../assets/img/cryptocurrency/btc.svg").default,
  },
  {
    id: "ethereum",
    type: "crypto",
    symbol: "ETH",
    name: "ETH Ethereum",
    memo: false,
    reserve: 101.016595,
    label: "Ethereum",
    icon: require("../assets/img/cryptocurrency/eth.svg").default,
  },
  {
    id: "monero",
    type: "crypto",
    symbol: "XMR",
    name: "XMR Monero",
    memo: false,
    reserve: 284.1982649,
    label: "Monero",
    icon: require("../assets/img/cryptocurrency/xmr.svg").default,
  },
  {
    id: "ripple",
    type: "crypto",
    symbol: "XRP",
    name: "XRP Ripple",
    memo: false,
    reserve: 2515468.334628,
    label: "Ripple",
    icon: require("../assets/img/cryptocurrency/xrp.svg").default,
  },
  {
    id: "litecoin",
    type: "crypto",
    symbol: "LTC",
    name: "LTC Litecoin",
    memo: false,
    reserve: 2291.55674959,
    label: "Litecoin",
    icon: require("../assets/img/cryptocurrency/ltc.svg").default,
  },
  {
    id: "tron",
    type: "crypto",
    symbol: "TRX",
    name: "TRX Tron",
    memo: false,
    reserve: 27003.60272018,
    label: "Tron",
    icon: require("../assets/img/cryptocurrency/trx.svg").default,
  },
  {
    id: "cardano",
    type: "crypto",
    symbol: "ADA",
    name: "ADA Cardano",
    memo: false,
    reserve: 343352.8067286,
    label: "Cardano",
    icon: require("../assets/img/cryptocurrency/ada.svg").default,
  },
  {
    id: "dogecoin",
    type: "crypto",
    symbol: "DOGE",
    name: "DOGE Dogecoin",
    memo: false,
    reserve: 771556.95420202,
    label: "DOGE",
    icon: require("../assets/img/cryptocurrency/doge.svg").default,
  },
  {
    id: "near",
    type: "crypto",
    symbol: "NEAR",
    name: "NEAR Protocol",
    memo: false,
    reserve: 9353.86255412,
    label: "Near",
    icon: require("../assets/img/cryptocurrency/near.svg").default,
  },
  {
    id: "polkadot",
    type: "crypto",
    symbol: "DOT",
    name: "DOT Polkadot",
    memo: false,
    reserve: 8871.94748964,
    label: "Polkadot",
    icon: require("../assets/img/cryptocurrency/dot.png"),
  },
  {
    id: "ethereumclassic",
    type: "crypto",
    symbol: "ETC",
    name: "ETC Ether Classic",
    memo: false,
    reserve: 4790,
    label: "Ethereum Classic",
    icon: require("../assets/img/cryptocurrency/etc.png"),
  },
  {
    id: "bitcoincash",
    type: "crypto",
    symbol: "BCH",
    name: "BCH Bitcoin Cash",
    memo: false,
    reserve: 767.60297304,
    label: "BitcoinCash",
    icon: require("../assets/img/cryptocurrency/bch.svg").default,
  },
  {
    id: "matic",
    type: "crypto",
    symbol: "MATIC",
    name: "MATIC Polygon",
    memo: false,
    reserve: 2843.616407,
    label: "Polygon",
    icon: require("../assets/img/cryptocurrency/matic.svg").default,
  },
  {
    id: "stellar",
    type: "crypto",
    symbol: "XML",
    name: "XML Stellar",
    memo: false,
    reserve: 704596.20453916,
    label: "Stellar",
    icon: require("../assets/img/cryptocurrency/xml.svg").default,
  },
  {
    id: "dash",
    type: "crypto",
    symbol: "DASH",
    name: "DASH Dash",
    memo: false,
    reserve: 321402.54,
    label: "Dash",
    icon: require("../assets/img/cryptocurrency/dash.svg").default,
  },
];
