import React from "react";
import { useTranslation } from "react-i18next";

const Regulations = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "rules.regulations.";

  return (
    <div>
      <h5>{t(`${basePath}title`)}</h5>
      <h5 className="mb-20">{t(`${basePath}item1.title`)}</h5>
      <ul>
        <li>{t(`${basePath}item1.list.item1`)}</li>
        <li>{t(`${basePath}item1.list.item2`)}</li>
        <li>{t(`${basePath}item1.list.item3`)}</li>
        <li>{t(`${basePath}item1.list.item4`)}</li>
      </ul>
      <h5 className="mb-20">{t(`${basePath}item2.title`)}</h5>
      <ul>
        <li>{t(`${basePath}item2.list.item1`)}</li>
        <li>{t(`${basePath}item2.list.item2`)}</li>
      </ul>
      <h5 className="mb-20">{t(`${basePath}item3.title`)}</h5>
      <ul>
        <li>{t(`${basePath}item3.list.item1`)}</li>
        <li>{t(`${basePath}item3.list.item2`)}</li>
      </ul>
      <h5 className="mb-20">{t(`${basePath}item4.title`)}</h5>
      <ul>
        <li>{t(`${basePath}item4.list.item1`)}</li>
        <li>{t(`${basePath}item4.list.item2`)}</li>
        <li>{t(`${basePath}item4.list.item3`)}</li>
        <li>{t(`${basePath}item4.list.item4`)}</li>
      </ul>
      <h5 className="mb-20">{t(`${basePath}item5.title`)}</h5>
      <ul>
        <li>{t(`${basePath}item5.list.item1`)}</li>
        <li>{t(`${basePath}item5.list.item2`)}</li>
        <li>{t(`${basePath}item5.list.item3`)}</li>
        <li>{t(`${basePath}item5.list.item4`)}</li>
      </ul>
      <h5 className="mb-20">{t(`${basePath}item6.title`)}</h5>
      <ul>
        <li>{t(`${basePath}item6.list.item1`)}</li>
      </ul>
      <h5 className="mb-20">{t(`${basePath}item7.title`)}</h5>
      <ul>
        <li>{t(`${basePath}item7.list.item1`)}</li>
      </ul>
      <h5 className="mb-20">{t(`${basePath}item7.title`)}</h5>
      <ul>
        <li>{t(`${basePath}item7.list.item1`)}</li>
      </ul>
      <h5 className="mb-20">{t(`${basePath}item8.title`)}</h5>
      <ul>
        <li>{t(`${basePath}item8.list.item1`)}</li>
      </ul>
      <h5 className="mb-20">{t(`${basePath}item9.title`)}</h5>
      <ul>
        <li>{t(`${basePath}item9.list.item1`)}</li>
      </ul>
      <h5 className="mb-20">{t(`${basePath}item10.title`)}</h5>
      <ul>
        <li>{t(`${basePath}item10.list.item1`)}</li>
      </ul>
      <h5 className="mb-20">{t(`${basePath}item11.title`)}</h5>
      <ul>
        <li>{t(`${basePath}item11.list.item1`)}</li>
      </ul>
    </div>
  );
};

export default Regulations;
